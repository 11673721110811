import L from 'leaflet'

import React from 'react'
import { Marker } from "react-leaflet"
import { makeStyles } from '@material-ui/core'
import { renderToString } from 'react-dom/server'

import * as COM from '../../../utilities/common'
import FlagSvg from './FlagSvg'

const useStyles = makeStyles({
    root: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    '@keyframes opacityChanger': {
        '0%': {
            opacity: 1,
        },
        '50%': {
            opacity: .75,
        },
        '100%': {
            opacity: 1,
        },
    },
    wrapper: {
        display: 'flex',
    },
    placer: {
        position: "relative",
        top: "-32px",
        left: "-6px",
        display: 'flex',
    },
    marker: {
        width: "36px",
        height: "36px",
    },
    icon: {
        width: "12px",
        height: "12px",
    },
    markerContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "36px",
        height: "36px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        
        //  PLUTO-1221
        //
        //  This inner helper (child of markerContainer) div is necessary to solve the ticket PLUTO-1221.
        //  The SVG icons did not show up in mobile Safari.
        //  Somehow the inner SVG content did not push out the containing div so we have to use an outer
        //  wrapper div with width and height specified.
        //  To keep the SVG precisely positioned to the start and end points coordinates we have to use
        //  the exactly same height and width for the wrapper div as we use for the SVG icons.
        //
        //  TODO: further investigation of this problem would be great!
        "& div": {
            width: "36px",
            height: "36px",
        }
    },
    iconContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "36px",
        height: "24px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
    },
    doAnimation: {
        animation: "$opacityChanger 1.5s infinite"
    },
});

const FlagMarker = (props) => {

    const { position, type } = props
    const classes = useStyles() 
    const bodyColor = type === "start" ? "green" : "black"
    const rectColor = type === "start" ? "green" : "white"

    //https://stackoverflow.com/questions/263965/how-can-i-convert-a-string-to-boolean-in-javascript
    const doAnimation = (process.env.REACT_APP_ANIMATE_ROUTE_ARROW_ICONS === 'true')
    console.debug(`Are start stop icons animated: ${doAnimation}`)
    const wrapperClasses = COM.clsx({
        [classes.wrapper]: true,
        [classes.doAnimation]: doAnimation,
    })

    const htmlString = renderToString(
        <div className={wrapperClasses}>
            <div className={classes.placer}>
                <div className={classes.markerContainer}>
                    {/*
                    !!! DO NOT REMOVE THIS OUTER DIV !!! 
                    This is here to solve the ticket PLUTO-1221!
                    */}
                    <div>
                        <FlagSvg
                            bodyColor={bodyColor}
                            rectColor={rectColor}
                            className={classes.marker}/>
                    </div>
                </div>
                <div className={classes.iconContainer}>

                </div>
            </div>
        </div>
    )

    const htmlIcon = new L.divIcon({
        html: htmlString, //NOTE: Very important to set, cannot be null, otherwise the leaflet library will crash! I did spend hours to find this bug ;)
        className: classes.root,
    })
    
    return <Marker icon={htmlIcon} position={position} />
}

export default FlagMarker