//Only one theme is in use at the moment
//See the commented lines how to add more!

import { createTheme } from '@material-ui/core/styles'
import lightTheme from './themes/light.json'

//import darkTheme from './themes/dark.json'
//import highContrastTheme from './themes/highContrast.json'

export const themes = {
    light: createTheme(lightTheme),
    
    //dark: createTheme(darkTheme),
    //highContrast: createTheme(highContrastTheme)
}

export const getTheme = (name) => {
    return themes[name] ? themes[name] : themes.light // Set up default theme here
}