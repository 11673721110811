
// React imports
import React from 'react'

// Redux imports
import { connect } from 'react-redux'
import * as trackingActions from '../../../store/actions/tracking'

import * as COM from '../../../utilities/common'

// Material UI
import {
    makeStyles,
    Fab,
} from '@material-ui/core/'

import AspectRatioIcon from '@material-ui/icons/AspectRatio'

const useStyles = makeStyles((theme) => ({
    fab: {
      backgroundColor: "white",
    },
    fabAutoFitToBounds: {
      backgroundColor: theme.palette.secondary.light,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      }
    },
    fabIconWrapper: {
      display: "flex",
      alignContent: "ceter",
      justifyContent: "center",
    },
    '@keyframes rotator': {
      from: {transform: 'scale(0.75)'},
      to: {transform: 'scale(1.1)'},
    },
    animated: {
      animation: '$rotator 2s infinite linear',
    }
  
}));

const TrackingFitButton = (props) => {

    const classes = useStyles()
    
    const {
      state,
      fitToBoundsUserRequest,
    } = props
    
    const { tracking } = state
    const bounds = tracking.bounds
    const { autoFitToBounds } = tracking

    if (!bounds) { return null }

    const handleFitToBoundsUserRequest = (event) => {
      event.stopPropagation()
      fitToBoundsUserRequest()
    }

    const fitToBoundButtonClasses = COM.clsx({
      [classes.fab]: !autoFitToBounds,
      [classes.fabAutoFitToBounds]: autoFitToBounds,
    })

    const fabIconWrapperClasses = COM.clsx({
      [classes.animated]: autoFitToBounds,
      [classes.fabIconWrapper]: true,
    })

    return (
        <Fab
            size="small"
            onClick={handleFitToBoundsUserRequest}
            className={fitToBoundButtonClasses}>
            <div className={fabIconWrapperClasses}>
              <AspectRatioIcon />
            </div>
        </Fab>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    fitToBoundsUserRequest: () => dispatch(trackingActions.fitToBoundsUserRequest()),
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingFitButton)