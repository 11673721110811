import React, { Fragment } from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

// i18n imports
import i18next from "i18next";
import { useTranslation } from 'react-i18next';

const Language = () => {

    const { t } = useTranslation();    
    
    return (
        <Fragment>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <Typography variant="h6" component="h6">
                        {t("Language")}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("Selected & Fallback")}</TableCell>
                <TableCell>{i18next.languages.map((e, i) => i === i18next.languages.length - 1 ? `${e}` : `${e}, `)}</TableCell>
            </TableRow>
        </Fragment>
    )
}

export default Language