import { 
    makeStyles,
} from "@material-ui/core"

import ModalProgressIndicator from "./ModalProgressIndicator"

const useStyles = makeStyles( theme => ({
    root: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: "flex",
        justifyContent: "center",
        aligItems: 'center',
        zIndex: 1000,
    },
}))

const FullScreenModalProgressIndicator = (props) => {

    const classes = useStyles()  
    const { message, flag, showPaper } = props

    if (!flag) return null
    
    return (
        <div className={classes.root}>
            <ModalProgressIndicator flag={true} message={message} showPaper={showPaper} />
        </div>
    )
}

export default FullScreenModalProgressIndicator