// React imports
import React, { useEffect } from 'react'
import * as routesActions from '../../store/actions/routes'
import * as navigationActions from '../../store/actions/navigation'
import * as COM from '../../utilities/common'

// Redux imports
import { connect } from 'react-redux'

// i18n
import { useTranslation } from 'react-i18next'

import {
  PlaylistAddCheck,
  DateRange,
  LocalShipping,
} from "@material-ui/icons"

import {
  Tabs,
  Tab,
} from "@material-ui/core"

import SingleSelectedTarget from '../Filter/SingleSelectedTarget'
import DateRangeFilter from "../Filter/DateRangeFilter"
import RoutesFilter from "./RoutesFilter"
import MapView from '../Map/MapView'
import TargetsFilter from "../Filter/TargetsFilter"
import TopDrawer from "../Drawer/TopDrawer"
import Items from './Items'

const Routes = (props) => {

    const { state } = props
    const { routes, targets } = state
    const { items } = routes
    const { t } = useTranslation()

    //NOTE: To be human-friendy we add plus one to the real day difference, so in case of same days the difference is 1 intead of 0
    const dateDifferenceString = `${COM.dateDifference(routes.dateRangeFrom, routes.dateRangeTo).days + 1 }${t('d')}`
    const itemsCount = routes.items.length
    const selectedCount = routes.target ? 1 : 0
    const { dateRangeFrom, dateRangeTo, selectedTab } = routes

    //We want to run only once!
    useEffect(() => {
      props.pageIsReady();
      // props could change be we want to run only once, so we are going to turn eslint off
      // eslint-disable-next-line
    },[])

    const handleTargetClick = (item) => {
      props.addTarget(item)
    }
    
    const handleClearSelectedClick = () => {
      props.deleteTarget()
    }
    
    const handleClickDelete = () => {
      props.deleteTarget()
    }

    const handleDateRangeChange = (from, to) => {
      props.setDateRange(from,to)
    }

    const handleClearClick = () => {
      props.clearDateRange()
    } 

    const handleTabChange = (event, value) => {
      props.setSelectedTab(value)
    }

    //We have to create a common target format
    const selectedTargets =[{
        id: state.routes?.target?.id,
        ...state.routes?.target
    }]

    const tabContent = [
      
      <TargetsFilter
        selectedCount={selectedCount}
        selectedTargets={selectedTargets}
        items={state.routes.target}
        onClearSelectedClick={handleClearSelectedClick}
        onTargetClick={handleTargetClick}>
          <SingleSelectedTarget item={state.routes.target} onDelete={handleClickDelete} />
      </TargetsFilter>,

      <DateRangeFilter
        onClearClick={handleClearClick}
        dateRangeFrom={dateRangeFrom}
        dateRangeTo={dateRangeTo}
        handleDateRangeChange={handleDateRangeChange} />,
        
      <RoutesFilter />,
    ]

    const checkedItems = items.filter( itm => itm.checked ) //TODO: we could filter this array in the SAGA layer. Does this change can lead to better performace?

    return (

      <>

        <TopDrawer inProgress={routes.inProgress || targets.inProgress}>

          <div>
            <Tabs value={selectedTab} onChange={handleTabChange} >
              <Tab
                icon={<LocalShipping />}
                label={`${selectedCount}`} />
              <Tab
                icon={<DateRange />}
                label={dateDifferenceString} />
              <Tab
                icon={<PlaylistAddCheck />}
                label={`${itemsCount}`} />
            </Tabs>
          </div>
          
          {tabContent[selectedTab]}
          
        </TopDrawer>
             
        <MapView>
          <Items items={checkedItems} />
        </MapView>

      </>
    )

}

const mapDispatchToProps = dispatch => {
  return {
    addTarget: (newItem) => dispatch(routesActions.addTarget(newItem)),
    deleteTarget: () => dispatch(routesActions.deleteTarget()),
    setDateRange: (from, to) => dispatch(routesActions.setDateRange(from, to)),
    clearDateRange: () => dispatch(routesActions.clearDateRange()),
    setSelectedTab: (value) => dispatch(routesActions.setSelectedTab(value)),
    pageIsReady: () => dispatch(navigationActions.routesPageIsReady()),
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)