// i18n
import { useTranslation } from 'react-i18next'

const NoResult = (props) => {

    const { t } = useTranslation()

    let { message } = props

    if (!message) message = t("No result")

    return <span>{message}</span>
}

export default NoResult