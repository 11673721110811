import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

// i18n imports
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
    keyValueHeaderRow: {
       backgroundColor: `${theme.palette.grey[200]}`,
       textDecoration: "underline",
    },
    keyCell: {
        fontStyle: 'italic',
    },
    valueCell: {
        fontStyle: 'italic',
    }
}))


const DeviceKeyValueTable = ({obj}) => {

    const { t } = useTranslation()
    const classes = useStyles();
    const objectKeys = Object.keys(obj)
    if (!objectKeys && objectKeys.length === 0) return null
    
    return (
        <>
            <TableRow className={classes.keyValueHeaderRow}>
                <TableCell>{t("Key")}:</TableCell>
                <TableCell>{t("Value")}:</TableCell>
            </TableRow>
            {objectKeys.map( (aKey, index) => (
                <TableRow key={index}>
                    <TableCell className={classes.keyCell}>{aKey}</TableCell>
                    <TableCell className={classes.valueCell}>{obj[aKey]}</TableCell>
                </TableRow>
            ))}
        </>
    )
}

const DeviceInfo = (props) => {

    const { t } = useTranslation()
    const { deviceState } = props
    const { info, presenter } = deviceState

    return (
        <Fragment>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <Typography variant="h6" component="h6">
                        {t("Device Info")}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                    <TableCell>{t("Presenter")}</TableCell>
                    <TableCell>{presenter}</TableCell>
            </TableRow>
            <DeviceKeyValueTable obj={info} />
        </Fragment>
    )
}

function mapStateToProps(state) {
    return {
        deviceState: state.device
    };
}

export default connect(mapStateToProps)(DeviceInfo)