import { takeEvery, select, putResolve, call } from 'redux-saga/effects'

import * as mapActions from '../../actions/map'
import * as settingsActions from '../../actions/settings'
import * as actionTypes from '../../actions/actionTypes'

function* handlePanTo(action) {

    const { payload } = action
    const { map } = yield select()
    const { ref } = map

    ref.panTo(payload)
    
    console.debug("Map panned to: ", payload)
}

function* handleMapSet(action) {

    try {

        const { center, zoom } = action
        const { map } = yield select()
        const { ref } = map
        
        // The ref could be null because the component layer (UI) sends 
        if (!ref) {
            console.info("Map reference is null. Skipping set!")
            return
        }
        
        ref.setView(center, zoom)
    
        console.debug("Map view set to center: ", center, ", zoom: ", zoom)

    } catch (error) {
        console.error(`Oops! Something went wrong during setting the map view!`, error)
    }
}

function* fitToBounds(action) {

    const bounds = action.payload
    const { map } = yield select()
    const ref = map.ref

    console.debug("Map will fit to bounds: ", bounds)

    try {

        //XXX: Do not use named options like 'paddingTopLeft' or 'paddingBottomRight'
        //here because these cause a very strange NaN exception coming deeply from leaflet library!
        //Use the 'padding' key with an array as value!
        //Highly recommend to investigate this issue more, it seems for me as a bug in leaflet!
        const options = {
            padding:
            [   
                parseInt(`${process.env.REACT_APP_FIT_TO_BOUNDS_TOP_LEFT_PADDING || 0}`),
                parseInt(`${process.env.REACT_APP_FIT_TO_BOUNDS_BOTTOM_RIGHT_PADDING || 0}`)
            ],
        }

        console.debug("YYYY Bounds to fit: ", bounds, "Options: ", options)

        if (bounds && bounds.isValid()) {
            
            // XXX: We 'wrap around' the call with 'rfb' here to avoid call params passing problem. 
            // Somehow I couldn't pass the arguments to the call function properly.
            // Investigating further this issue would be useful!
            // https://stackoverflow.com/questions/52219839/how-to-pass-urls-params-headers-to-call-in-redux-saga
            // https://redux-saga.js.org/docs/api/*/
            const rfb = () => ref.fitBounds(bounds, options) 
            
            // 2. move the map (generate an event)
            yield call(rfb)

        } else {
            console.debug(`Invalid bounds or bounds not presented at 'fitToBounds' call!`, bounds)
        }

    } catch (error) {
        console.error(`Oops! Something went wrong during fitting the map to bounds!`, error)
    }
}


function* handleFitTo(action) {
    const { payload } = action //payload is latlng here
    const zoom = process.env.REACT_APP_FIT_TO_TARGET_ZOOM || 10
    
    //1. move and zoom the map
    yield putResolve(mapActions.set(payload, zoom))

    //2. close the filter pane (drawer) if necessary
    yield process.env.REACT_APP_FIT_TO_TARGET_AUTO_HIDE_FILTER_PANE &&
    putResolve(settingsActions.setFilterPaneVisible(false))

    console.debug("Map fitted to: ", payload, " with zoom level ", zoom)
}

function* handleZoomUserRequest(action) {
    const { map } = yield select()
    const { ref } = map
    const value = action.payload

    const currentZoom = ref.getZoom()
    const newZoom = currentZoom + value

    ref.setZoom(newZoom)
}

export function* saga() {

    yield takeEvery(actionTypes.MAP_SET, handleMapSet)
    yield takeEvery(actionTypes.MAP_PAN_TO, handlePanTo)   
    yield takeEvery(actionTypes.MAP_FIT_TO, handleFitTo)
   
    yield takeEvery(actionTypes.MAP_ZOOM_USER_REQUEST, handleZoomUserRequest)
   
    yield takeEvery(actionTypes.MAP_FIT_TO_BOUNDS, fitToBounds)
}