import * as actionTypes from './actionTypes'

// Fetch (API calls)
export const routesFetchStart = (targetId, fromTime, toTime) => {
    return {
        type: actionTypes.ROUTES_FETCH_START,
        targetId: targetId,
        fromTime: fromTime,
        toTime: toTime,
    }
}

export const routesFetchSucces = (items) => {
    return {
        type: actionTypes.ROUTES_FETCH_SUCCESS,
        payload: items 
    }
}

export const routesFetchFail = (error) => {
    return {
        type: actionTypes.ROUTES_FETCH_FAIL,
        error: error
    }
}

export const itemCheckedByUser = (startPositionTimeMicros) => {
    return {
        type: actionTypes.ROUTES_ITEM_CHECKED_BY_USER,
        payload: startPositionTimeMicros
    }
}

export const toggleChecked = (startPositionTimeMicros) => {
    return {
        type: actionTypes.ROUTES_TOGGLE_CHECKED,
        payload: startPositionTimeMicros
    }
}

export const setMultiselect = (value) => {
    return {
        type: actionTypes.ROUTES_SET_MULTISELECT,
        payload: value
    }
}

export const positionsFetchStart = (targetId, fromTime, toTime) => {
    return {
        type: actionTypes.ROUTES_POSITIONS_FETCH_START,
        targetId: targetId,
        fromTime: fromTime,
        toTime: toTime
    }
}


export const positionsFetchSucces = (data, fromTime) => {
    return {
        type: actionTypes.ROUTES_POSITIONS_FETCH_SUCCESS,
        data: data, 
        fromTime: fromTime,
    }
}

export const positionsFetchFail = (error, targetId, fromTime) => {
    return {
        type: actionTypes.ROUTES_POSITIONS_FETCH_FAIL,
        error: error,
        targetId: targetId,
        fromTime: fromTime,
    }
}

//SELECTED
export const addTarget = (target) => {
    return {
        type: actionTypes.ROUTES_ADD_TARGET,
        payload: target 
    }
}

export const deleteTarget = () => {
    return {
        type: actionTypes.ROUTES_DELETE_TARGET,
    }
}

export const setItems = (items) => {
    return {
        type: actionTypes.ROUTES_SET_ITEMS,
        payload: items
    }
}

//NOTE: 
//This feature isn't in use at the moment, due to the very expensive server-side requirements to serve!
//The whole logic does work, all the codes are in place, but the related UI elements have been removed!
//
//selectAllSections
export const selectAllItems = () => {
    return {
        type: actionTypes.ROUTES_SELECT_ALL_ITEMS,
    }
}

export const clearSelectedSections = () => {
    return {
        type: actionTypes.ROUTES_CLEAR_SELECTED_SECTIONS
    }
}


// DATE RANGE
export const setDateRangeFrom = (value) => {
    return {
        type: actionTypes.ROUTES_SET_DATE_RANGE_FROM,
        dateRangeFrom: value  
    }
}

export const setDateRangeTo = (value) => {
    return {
        type: actionTypes.ROUTES_SET_DATE_RANGE_TO,
        dateRangeTo: value  
    }
}

export const setDateRange = (from, to) => {
    return {
        type: actionTypes.ROUTES_SET_DATE_RANGE,
        from: from,
        to: to,
    }
}

export const clearDateRange = () => {
    return {
        type: actionTypes.ROUTES_CLEAR_DATE_RANGE, 
    }
}


// SELECTED TAB
export const setSelectedTab = (value) => {
    return {
        type: actionTypes.ROUTES_SET_SELECTED_TAB,
        payload: value  
    }
}


// BOUNDS
export const setBounds = (bounds) => {
    return {
        type: actionTypes.ROUTES_SET_BOUNDS,
        payload: bounds,
    }
}

export const createBounds = () => {
    return {
        type: actionTypes.ROUTES_CREATE_BOUNDS,
    }
}

export const fitToBounds = () => {
    return {
        type: actionTypes.ROUTES_FIT_TO_BOUNDS,
    }
}

export const fitToBoundsUserRequest = () => {
    return {
        type: actionTypes.ROUTES_FIT_TO_BOUNDS_USER_REQUEST,
    }
}