// React imports
import React from 'react'

// Redux
import { connect } from 'react-redux'

// i18n imports
import { useTranslation } from 'react-i18next'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core'

import * as settingsActions from './../../store/actions/settings'

import availableLanguages from './availableLanguages.json'
availableLanguages.sort((a, b) => a.order - b.order)

const useStyle = makeStyles(theme => ({
  root: {
    display: "inline",
    padding: 0,
    fontSize: ".7rem",
    "&:not(:first-child):before": {
      content: '""',
      paddingLeft: theme.spacing(1)
    },
    "&:not(:last-child):after": {
      paddingLeft: theme.spacing(1),
      content: '"|"',
      color: theme.palette.grey[600]
    },
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  normal: {
    color: theme.palette.grey[600]
  },
  [theme.breakpoints.up('sm')]: {
    root: {
      fontSize: ".85rem",
    }
  }
}))

const LanguageItem = ({language, onClick}) => {
    
    const { t, i18n } = useTranslation()
    const classes = useStyle()

    return (
      <ListItem className={classes.root} onClick={ ev => onClick(language.code)}>
        <span className={language.code === i18n.language ? classes.selected : classes.normal}>
          {t(language.name)}
        </span>
      </ListItem>
    )
}

const LanguageSelector = ({onLanguageChange, languageChange}) => {

    const handleClick = code => {

        //if we have an extra callback, call it
        onLanguageChange && onLanguageChange(code)

        //We are going to store the langugage into settings
        languageChange(code)
    
        console.debug("Language has been changed to: ", code)
    }

    return(
        <List>
          {availableLanguages.map( l => <LanguageItem language={l} onClick={handleClick} key={l.code} /> )}
        </List>
     )
}

const mapDispatchToProps = dispatch => {
  return {
    languageChange: (key) => dispatch(settingsActions.languageChange(key)),
  }
}

function mapStateToProps(state) {
  return {
    settingsState: state.settings,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)