import * as actionTypes from './actionTypes'

// ITEMS
export const fetchItemsStart = (offset, filter) => {
    return {
        type: actionTypes.TARGETS_FETCH_ITEMS_START,
        offset: offset,
        filter: filter,
    }
}

export const fetchItemsSuccess = (items, offset, filter) => {
    return {
        type: actionTypes.TARGETS_FETCH_ITEMS_SUCCESS,
        items: items,
        offset: offset,
        filter: filter,
    }
}

export const fetchItemsFail = (err) => {
    return {
        type: actionTypes.TARGETS_FETCH_ITEMS_FAIL,
        error: err
    }
}

// COUNT
export const fetchCountStart = (filter) => {
    return {
        type: actionTypes.TARGETS_FETCH_COUNT_START,
        filter: filter,
    }
}

export const fetchCountSuccess = (count) => {
    return {
        type: actionTypes.TARGETS_FETCH_COUNT_SUCCESS,
        count: count,
    }
}

export const fetchCountFail = (err) => {
    return {
        type: actionTypes.TARGETS_FETCH_COUNT_FAIL,
        error: err
    }
}


// SETTERS
export const setInProgress = (value) => {
    return {
        type: actionTypes.TARGETS_SET_IN_PROGRESS,
        payload: value
    }
}

export const setAutocompleteInProgress = (value) => {
    return {
        type: actionTypes.TARGETS_SET_AUTOCOMPLETE_IN_PROGRESS,
        payload: value
    }
}

export const setCount = (value) => {
    return {
        type: actionTypes.TARGETS_SET_COUNT,
        payload: value  
    }
}

export const setInputFilter = (value) => {
    return {
        type: actionTypes.TARGETS_SET_INPUT_FILTER,
        payload: value
    }
}

export const setItems = (items) => {
    return {
        type: actionTypes.TARGETS_SET_ITEMS,
        payload: items,
    }
}

export const set = (items, offset, filter) => {
    return {
        type: actionTypes.TARGETS_SET,
        items: items,
        offset: offset,
        filter: filter,
    }
}


// OTHER METHODS
export const next = () => {
    return {
        type: actionTypes.TARGETS_NEXT,
    }
}

export const clearInputFilter = () => {
    return {
        type: actionTypes.TARGETS_CLEAR_INPUT_FILTER,
    }
}