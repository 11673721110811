import storage from 'redux-persist/lib/storage'
import { createTransform } from 'redux-persist'
import { initialState } from '../store/reducers/settings'
import * as COM from '../utilities/common'

const prefix = process.env.REACT_APP_LOCAL_STORAGE_KEY_PREFIX
const s = process.env.REACT_APP_API_URL
const settingsReducerName = "settings"
const buildIdString = COM.buildIdString()

const inboundStateHandler = (inboundState, key) => {
    
    console.debug(`🗃️ WRITE to local storage. Persist transform '${settingsReducerName}' state is being saved: `, inboundState, key)
    
    //We have to clone the state, because the mofification of the state causes
    //the object being persistated again!
    const newState = JSON.parse(JSON.stringify(inboundState));

    const p = newState?.autoLoginData?.password
    if (p) {
        console.debug(`🗃️ Encrypting auto login password: `, p)
        newState.autoLoginData.password = COM.encryptString(p, s)
        console.debug(`🗃️ Encrypted auto login password: `, newState.autoLoginData.password)
    }

    console.debug(`🗃️ Persist transform '${settingsReducerName}' state has been saved: `, newState, key)

    return newState
}

const outboundStateHandler = (outboundState, key) => {
    console.debug(`🗃️ READ from local storage. Persist transform '${settingsReducerName}' state has been persisted: `, outboundState, key)
    const isBuildIdStringMatch = buildIdString === outboundState.buildIdString
    
    //We have to clone the state, because the mofification of the state causes
    //the object being persistated again!
    const newState = JSON.parse(JSON.stringify(outboundState));

    const p = newState?.autoLoginData?.password
    if (p) {
        console.debug(`🗃️ Decrypting auto login password...`)
        newState.autoLoginData.password = COM.descryptString(p, s)
        console.debug(`🗃️ Decrypted auto login password: `, newState.autoLoginData.password)
    }

    // Handle build Id
    if (!isBuildIdStringMatch) {
        console.debug(`🗃️ Peprsisted buildId doesn't match to id in build info! ${newState.buildIdString} != ${buildIdString}`)
        console.debug("🗃️ Using the initial state instead of persisted!", initialState)
        return initialState
    }

    return newState
}

const params = { whitelist: [settingsReducerName] }
const buildIdTransform = createTransform( inboundStateHandler, outboundStateHandler, params)

const persistConfig = {
    key: `${prefix}`,
    storage,
    whitelist: [settingsReducerName],
    transforms: [buildIdTransform]
}

export default persistConfig