// React imports
import React from 'react'
import { connect } from 'react-redux'
import * as targetsActions from '../../store/actions/targets'

// I18n
import { useTranslation } from 'react-i18next'

// Material-UI
import {
  makeStyles,
  Input,
  InputAdornment,
  Tooltip,
} from '@material-ui/core'

// Icons
import ClearIcon from '@material-ui/icons/Clear'

// Own components
import ClearSelectedItems from './ClearSelectedItems'
import TargetList from './TargetList'
import { PlaylistAdd } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  
  root: {
    boxSizing: "border-box",
    width: "100%",
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    //for debug:
    //backgroundColor: "magenta"
  },

  /* total height is 2rem */
  filterAndClearContainer: {
    height: "3rem",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: 'center',
    gap: "1rem",

    //for debug:
    //backgroundColor: "rgba(11,233,233,.5)"
  },

    /* total height is 4.5rem */
    selectedTargetsContainer: {
      boxSizing: "border-box",
      marginTop: ".5rem",
      marginBottom: ".5rem",
  
      overflowX: "scroll", 
      overflowY: "hidden", 
  
      display: "flex",
      alignItems: "center",
      justifyContent: 'flex-start',
      gap: "1rem",
  
      height: "3.5rem",
    
      //for debug:
      //backgroundColor: "rgba(11,23,233,.5)"
    },

}));

const AddAll = (props) => {

  const targetItems = props.targetItems
  const onClick = props.onClick

  //TODO: 200 from settings
  if (!onClick || !targetItems || targetItems.length === 0 || targetItems.length > 200) return null;

  return (
    <>
      <PlaylistAdd onClick={() => onClick(targetItems)}/>
    </>
  )
}

const TargetsFilter = (props) => {

  const classes = useStyles()
  const { t } = useTranslation()

  const {

    selectedTargets,

    children,
    state,
    
    onTargetClick,
    onClearSelectedClick,
    
    setInputFilter,
    clearInputFilter,
  
  } = props

  //To avoid erros in case of not presented we use this approach!
  const onAddAllClick = props.onAddAllClick

  const { targets } = state
  const { filter, inputFilter } = targets
  const selectedCount = selectedTargets ? selectedTargets.length : null
  const remoteItemCount = targets.count
  const localItemCount = targets.items.length
  const hasMore = remoteItemCount !== localItemCount
 
  const handleInputFilterChanged = (e) => {
    setInputFilter(e.target.value)
  }

  const handleClearClick = () => {
    clearInputFilter()
  }

  const getIcon = () => {
    return filter !== "" ? <ClearIcon onClick={handleClearClick} /> : ""
  }
  
  const next = () => props.next()

  
  return (
      <div className={classes.root}>
        
        <div className={classes.filterAndClearContainer}>
          <Input
              fullWidth
              type="text"
              value={inputFilter}
              onChange={handleInputFilterChanged}
              endAdornment={<InputAdornment position="end">{getIcon()}</InputAdornment>} />
          <Tooltip title={t("downloaded/all targets count")}>
            <span>{localItemCount}/{remoteItemCount}</span>
          </Tooltip>
        </div>

        <div className={classes.selectedTargetsContainer}>
          <AddAll onClick={onAddAllClick} targetItems={targets.items} />
          <ClearSelectedItems onClick={onClearSelectedClick} selectedCount={selectedCount} />
          {children}
        </div>

        <TargetList
          selectedItems={selectedTargets}
          items={state.targets.items}
          autocompleteInProgress={state.targets.autocompleteInProgress}
          next={next}
          hasMore={hasMore}
          onTargetClick={onTargetClick} />
        
      </div>
  )
}

function mapStateToProps(state) {
  return {
    state: state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    next: () => dispatch(targetsActions.next()),
    setInputFilter: (filter) => dispatch(targetsActions.setInputFilter(filter)),
    clearInputFilter: () => dispatch(targetsActions.clearInputFilter()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetsFilter)