import Route from "./Route"

const Items = (props) => {

    const { items } = props
    
    const routes = items.map( (itm, idx) => <Route key={idx} sections={itm.sections} positions={itm.positions} latLngPositions={itm.latLngPositions} startPosition={itm.startPosition} stopPosition={itm.stopPosition} /> )

    return routes
}

export default Items