import React from "react";
import { connect } from 'react-redux'

import {
    Grid,
    Button,
    makeStyles,
    Paper
} from '@material-ui/core/'

import LanguageSelector from '../LanguageSelector/LanguageSelector'

//i18n
import { useTranslation } from 'react-i18next'

import * as settingsActions from '../../store/actions/settings'
import ThemeSelect from "./ThemeSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        width: "100%",
        maxWidth: theme.breakpoints.values.sm,
        top: 0,
        left: 0,
        zIndex: '1000',
        backgroundColor: "rgb(255, 255, 255, 0.93)",
    },
    container: {
        margin: "1rem",
    },
    label: {
        fontSize: "0.85rem",
    },
    buttonContainer: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end"
    },
    username: {
        color: theme.palette.grey[900],
        fontSize: "1.05rem"
    }
}));

const ThemeSelector = (props) => {

    //NOTE: We use a string comparation here, so any other value than 'true' will be processed as false
    if (process.env.REACT_APP_THEME_SELECTOR_ACTIVE !== 'true') return null
    
    return (
        <Grid item xs={12}>
            <ThemeSelect />    
        </Grid>
    )
}

const ProfilePane = (props) => {
  
    const { t } = useTranslation()
    const classes = useStyles()
    const { state, setProfilePaneVisible } = props
    const { settings, auth } = state
    const { profilePaneVisible } = settings

    if (!profilePaneVisible) return null

    const handleClose = () => {
        setProfilePaneVisible(false)
    }

    return (
        <Paper className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <h3>{t("User")}</h3>
                    <span className={classes.username}>{auth.credentials.username}</span>
                </Grid>
                <Grid item xs={12}>
                    <h3>{t("Language")}</h3>
                    <LanguageSelector />
                </Grid>
                <ThemeSelector />
            </Grid>
            <div className={classes.buttonContainer}>
                <Button onClick={handleClose} color="primary">{t("Close")}</Button>
            </div>
        </Paper>
    )
}

const mapDispatchToProps = dispatch => {
  return {
      setProfilePaneVisible: (value) => dispatch(settingsActions.setProfilePaneVisible(value)),
  }
}

function mapStateToProps(state) {
  return {
      state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePane)