import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

export const getTargetsPosition = (credentials, payloadAsJSON, popupTargetId, acceptLanguage) => {
    const suffix = popupTargetId ? `?popupTargetId=${popupTargetId}` : '';
    const query = `${BASE_URL}/targets/positions/tracking${suffix}`;

    console.debug("getTargetsPosition query:", query);

    return axios.post(
        query,
        payloadAsJSON,
        {
            headers: {
                'Accept-Language': acceptLanguage,
                'Content-Type': 'application/json'
            },
            auth: credentials
        }
    )
}