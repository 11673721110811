// Basic imports
import React from 'react'
import { connect } from 'react-redux'

// Material UI
import { 
  makeStyles,
  Paper,
  Grid,
} from '@material-ui/core'

// Own components
import RefuelsListHeader from './RefuelsListHeader'
import RefuelsNoResultHeader from './RefuelsNoResultHeader'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  grid: {
    padding: "0.5rem",
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
  },
}))

const RefuelsHeader = (props) => {

  const classes = useStyles()

  //XXX: !!! MISLEADING NAMES !!!
  //XXX: Nameing is misleading, be carefull!
  //XXX: The state contains a 'refuels' object.
  //XXX: The refuels object contains a 'refuels' list.
  //XXX: We use the refuels list INSIDE the refuels object to iterate
  const refuelsState = props.state.refuels
  const { refuels } = refuelsState

  const isEmpty = !refuels || refuels.length < 1

  return (
    <Paper className={classes.root}>
        <Grid container className={classes.grid}>
            {isEmpty 
            ? <RefuelsNoResultHeader />
            : <RefuelsListHeader />
            }
        </Grid>
    </Paper>
  );

}
const mapDispatchToProps = dispatch => {
    return {}
}
  
const mapStateToProps = state => {
    return {
        state: state,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefuelsHeader)