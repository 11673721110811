// React imports
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import * as settingsActions from '../../store/actions/settings'

// Navigation routes
import * as navigationRoutes from '../../other/NavigationRoutes'

// Material components
import { makeStyles } from '@material-ui/core'

// Own components
import MapView from '../Map/MapView';
import SignIn from './SignIn';

const useStyles = makeStyles( (theme) => (
  {
    wrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1100,
    },
    fade: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 24, 63, .75)",
      zIndex: 1000,
    }
  }
))

const SignInLayout = (props) => {
  
  const classes = useStyles()

  return (
    <>
      <MapView/>

      <div className={classes.fade}></div>
      <div className={classes.wrapper}>

        <Switch>
            <Route path={navigationRoutes.NAVIGATION_ROUTE_HOME} component={SignIn}/>
        </Switch>
      
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    languageChange: (languageCode) => dispatch(settingsActions.languageChange(languageCode)),
  }
}

function mapStateToProps(state) {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout)