// React imports
import React from 'react'

// Material-UI
import {
    Badge,
} from '@material-ui/core/'

import DeleteIcon from '@material-ui/icons/HighlightOff'

const ClearSelectedItems = (props) => {

    const { onClick, selectedCount } = props

    if (selectedCount < 1) return null

    return (
        <Badge badgeContent={selectedCount} onClick={onClick}>
            <DeleteIcon />
        </Badge>          
    )
}

export default ClearSelectedItems