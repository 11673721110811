import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

/**
 * Get all the refuels for the target
 * @param {object} credentials - The username and password
 * @param {number} targetId - The target ID 
 * @param {object} params - The parameters to send to the API
 * @param {number} params.offset - The offset to start from
 * @param {number} params.limit - The limit of the results
 * @param {number} params.from - The from timestamp
 * @param {number} params.to - The to timestamp
 * @param {string} acceptLanguage  - Mandatory parameter for the API, use the current language
 * 
 */
export const getRefuels = (credentials, targetId, params, acceptLanguage) => {
    return axios.get(
        `${BASE_URL}/targets/${targetId}/fuelfillings`, {
            auth: credentials,
            headers: {
                'Accept-Language': acceptLanguage
            },
            params: params
        }
    )
}
