import React from 'react'

import 'date-fns'
import { compareAsc, isValid } from 'date-fns'

import { DatePicker } from "@material-ui/pickers"

import {
  makeStyles,
} from '@material-ui/core'

// i18n
import { useTranslation } from 'react-i18next'

const DATE_RANGE_FORMAT = process.env.REACT_APP_DATE_FORMAT || "yyyy-MM-dd"

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: "1rem",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    claer: {
      flex: 1,
    }
}))

const DateRangeFilter = (props) => {

  const { t } = useTranslation()
  const classes = useStyles()
  const { dateRangeFrom, dateRangeTo, handleDateRangeChange } = props
  
  const processDates = (from, to) => {
    
    console.debug("[processDates] Received from: ", from, ", to:", to)

    if (!isValid(from)) { from = new Date() }
    if (!isValid(to)) { to = new Date() }

    // The start date is before the end date, we have to swap them.
    if (compareAsc(from, to) === 1) {
      const tmp = from
      from = to
      to = tmp
    }

    console.debug("[processDates] Processed from: ", from, ", to:", to)
    
    handleDateRangeChange(from, to)
  }

  const handleFromDateChange = (from) => {
    processDates(from, dateRangeTo)
  }

  const handleToDateChange = (to) => {
    processDates(dateRangeFrom, to)
  }

  return (
      <div className={classes.root}>
        <DatePicker
          autoOk
          showTodayButton
          variant="dialog"
          inputVariant="outlined"
          label={t("Start date")}
          todayLabel={t("Today")}
          cancelLabel={t("Cancel")}
          okLabel={t("Ok")}
          format={DATE_RANGE_FORMAT}
          value={dateRangeFrom}
          onChange={date => handleFromDateChange(date)} />
        <DatePicker
          autoOk
          showTodayButton
          variant="dialog"
          inputVariant="outlined"
          label={t("End date")}
          todayLabel={t("Today")}
          cancelLabel={t("Cancel")}
          okLabel={t("Ok")}
          format={DATE_RANGE_FORMAT}
          value={dateRangeTo}
          onChange={date => handleToDateChange(date)} />
      </div>
    )
}

export default DateRangeFilter