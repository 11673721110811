import { take, takeEvery, putResolve } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'

import * as actionTypes from '../actions/actionTypes'
import * as actions from '../actions/device'
import * as COM from '../../utilities/common' 

const EXOSKELETON_WELCOME_MESSAGE = process.env.REACT_APP_EXOSKELETON_WELCOME_MESSAGE
const WAIT_FOR_IOS_DEVICE_INFO_TIMEOUT = 250
const ANDROID_BRIDGE = window.Android
const IOS_BRIDGE = window.webkit

const sendToastMessage = (message) => {
        
    ANDROID_BRIDGE && ANDROID_BRIDGE.showToast(message)
    IOS_BRIDGE && sendIosCommand("toastMessage", message)
    
    ANDROID_BRIDGE && IOS_BRIDGE && console.debug(`Toast message sent: ${message}`)
}

// These two other different type of messages are available but not in use at the moment
/*const sendAlertMessage = (message) => {
    
    ANDROID_BRIDGE && ANDROID_BRIDGE.alertMessage(message)
    IOS_BRIDGE && sendIosCommand("alertMessage", message)
    
    console.debug(`Alert message sent: ${message}`)
}

const sendConsoleMessage = (message) => {

    ANDROID_BRIDGE && ANDROID_BRIDGE.writeToConsole(message)
    IOS_BRIDGE && sendIosCommand("consoleMessage", message)

    console.debug(`Console message sent: ${message}`)
}*/

const sendIosCommand = (command, ...payload) => {
    
    const body = {
        command: command,
        payload: payload
    }

    IOS_BRIDGE.messageHandlers.deviceInfoCoordinator.postMessage(JSON.stringify(body))
}


function* getAndroidInfo() {
    const deviceInfo = {
        info: JSON.parse(ANDROID_BRIDGE.systemInfo()),
        presenter: COM.ANDROID_EXOSKELETON,
    }
    yield putResolve(actions.set(deviceInfo))
}

// Complex logic to get the device information from the iOS device via the webkit javascript bridge
function* getIOSInfo() {
    
    //We have this information right now, se we are going to set it quick
    yield putResolve(actions.set({info: null, presenter: COM.IOS_EXOSKELETON}))

    // We are going to create an event channel here, because iOS could provide system information in an asyncrhronous manner.
    // 1. We have to create a receiver function
    // 2. We have to send a command to the iOS device
    // 3. We have to process the passed info in the receiver function
    // 4. We have to stop the event channel if the receiver function has been called or the timeout exceeded
    // 5. We have to create the deviceInfo object structure and send it to the reducer to store.
    // 6. We have to close the channel

    const channel = eventChannel( emitter => {

        //1. Create a receiver function
        window.iTrackPluto = {
            setDeviceInfo: (deviceInfo) => {
                console.debug("IOS deviceInfo: ", deviceInfo)
                // 3. Reveiver function called. We have a deviceInfo at this moment
                emitter(deviceInfo)
                emitter(END)
            }
        }

        // 4. Sending the END signal if the timeout exceeeded.
        const timeoutInterval = setTimeout(() => {
            emitter(END)
        }, WAIT_FOR_IOS_DEVICE_INFO_TIMEOUT)

        return () => clearInterval(timeoutInterval)
    })

    try {    
        
        while (true) {

             // 2. Send the iOS command to the native app
            sendIosCommand("getDeviceInfo")

            // Wainting until we get an emittion
            const payload = yield take(channel)
            console.debug("Device info received form the channel", payload)
 
            // 5. Create the object and store into the store            
            const deviceInfo = {
                info: payload,
                presenter: COM.IOS_EXOSKELETON,
            }
            yield putResolve(actions.set(deviceInfo))
        }

    } finally {
        // END jumps here
        // 6. Close the channel
        channel.close()
        console.log('IOS deviceInfo event channel has been closed.')
    }
}

function* getBrowserInfo() {
    const deviceInfo = {
        info: {
            //XXX: deprecated keys! Check and update!
            appName: navigator.appName,
            appVersion: navigator.appVersion,
            appCodeName: navigator.appCodeName,
            userAgent: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
        },
        presenter: COM.BROWSER,
    }
    yield putResolve(actions.set(deviceInfo))
}

function* storeCreated(action) {

    if (ANDROID_BRIDGE) {
        console.debug("Android brige detected.")
        yield getAndroidInfo()
    } else if (IOS_BRIDGE) {
        console.debug("iOS brige detected.")
        yield getIOSInfo()
    } else {
        console.debug("Browser detected.")
        yield getBrowserInfo()
    }

    //This action signs the device information has been collected, the auth saga could take this event
    //and do the autologin process, if necessary.
    yield putResolve(actions.infoReady())
}

function* welcomeMessage(action) {
    console.debug("welcomeMessage", action)
    yield sendToastMessage(EXOSKELETON_WELCOME_MESSAGE)
}

export function* saga() {
    yield takeEvery(actionTypes.STORE_CREATED, storeCreated)
    yield takeEvery(actionTypes.DEVICE_INFO_READY, welcomeMessage)
}