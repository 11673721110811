import { takeEvery, put, call, select } from 'redux-saga/effects'

import * as actions from './../actions/shareTarget'
import * as actionTypes from '../actions/actionTypes'
import * as shareTargetRepo from '../repository/shareTarget'
import * as COM from '../../utilities/common'

function* generateSharableLink(action) {

    const { fromDate, toDate, targetId, email, variant } = action

    try {
        const state = yield select()

        //We are going to create the proper 'from' and 'to' time micros for the server
        const processedFromTimeMicros = COM.createStartOfDayServerTimeMicrosFromDate(fromDate)
        const processedToTimeMicros = COM.createEndOfDayServerTimeMicrosFromDate(toDate)

        const params = {
            targetId: targetId,
            fromTimeMicros: processedFromTimeMicros,
            toTimeMicros: processedToTimeMicros,
            emailAddress: email,
            //NODE: type field is necessary otherwise the backend throws exception! 
            type: variant || "tracking" //NOTE: we have to use different variable name here to avoid collision!
        }

        const acceptLanguage = state.settings.language;
        const { data } = yield call(shareTargetRepo.generateSharableLink.bind(this, state.auth.credentials, JSON.stringify(params), acceptLanguage)) ///XXX: WTF? What is going on, why we need to bind here?

        //NOTE: data is an object, we have to use loginToken FROM the object
        yield put(actions.generateSuccess(data.loginToken))
    
    } catch (exp) {
        console.debug("Error while generation sharable link!", exp)
        yield put({type: actionTypes.SHARE_TARGET_GENERATE_FAIL, error: exp})
    }
}

export function* saga() {
    yield takeEvery(actionTypes.SHARE_TARGET_GENERATE_START, generateSharableLink)
}