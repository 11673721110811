import { take, takeEvery, putResolve, } from 'redux-saga/effects'

import * as actionTypes from '../../actions/actionTypes'
import * as trackingActions from '../../actions/tracking'

function* waitUntilNoPendingTargetsAndCreateAndFitToBounds() {
    yield take(actionTypes.TRACKING_NO_PENDING_TARGET)
    yield 
    yield putResolve(trackingActions.createBounds())
    yield take(actionTypes.TRACKING_SET_BOUNDS)
    yield putResolve(trackingActions.fitToBounds())
}

function* replaceAllTargetsUserRequest(action) {
    const targets = action.payload
    console.debug("Unconditional 'autofitter': replaceAllTargetsUserRequest")
    yield putResolve(trackingActions.replaceAllTargets(targets))
    yield waitUntilNoPendingTargetsAndCreateAndFitToBounds()
}

function* removeTargetByIdUserRequest(action) {
    console.debug("Unconditional 'autofitter': removeTargetByIdUserRequest")
    const targetId = action.payload
    yield putResolve(trackingActions.removeTargetById(targetId))
    yield waitUntilNoPendingTargetsAndCreateAndFitToBounds()
}

function* addTargetsUserRequest(action) {
    console.debug("Unconditional 'autofitter': addTargetsUserRequest")
    const targets = action.payload
    yield putResolve(trackingActions.addTargets(targets))
    yield waitUntilNoPendingTargetsAndCreateAndFitToBounds()
}

function* handlePageIsReady(action) {
    console.debug("handlePageIsReady")
    console.debug("Waiting for map's reference")
    yield take(actionTypes.MAP_SET_REF)
    console.debug("Map reference has set")
    console.debug("Create bbounds...")
    yield putResolve(trackingActions.createBounds())
    console.debug("Waiting for 'tracking bounds are valid'")
    yield take(actionTypes.TRACKING_BOUNDS_ARE_VALID)
    console.debug("Tracking bounds are valid has taken")
    yield putResolve(trackingActions.fitToBounds())
    console.debug("Fit to bounds done")
}

export function* saga() {
    yield takeEvery(actionTypes.TRACKING_ADD_TARGETS_USER_REQUEST, addTargetsUserRequest)
    yield takeEvery(actionTypes.TRACKING_REMOVE_TARGET_BY_ID_USER_REQUEST, removeTargetByIdUserRequest)
    yield takeEvery(actionTypes.TRACKING_REPLACE_ALL_TARGETS_USER_REQUEST, replaceAllTargetsUserRequest)
    yield takeEvery(actionTypes.NAVIGATION_TRACKING_PAGE_IS_READY, handlePageIsReady)
}