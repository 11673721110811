import React, { Fragment } from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

// i18n imports
import { useTranslation } from 'react-i18next'

import BuildId from '../BuildID/BuildID'

const Application = () => {

    const { t } = useTranslation();    
    
    return (
        <Fragment>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <Typography variant="h6" component="h6">
                        {t("Application")}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("Package name")}</TableCell>
                <TableCell>{process.env.REACT_APP_NPM_PACKAGE_NAME}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("Package version")}</TableCell>
                <TableCell>{process.env.REACT_APP_NPM_PACKAGE_VERSION}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("Build ID")}</TableCell>
                <TableCell><strong><BuildId /></strong> (<BuildId long />)</TableCell>
            </TableRow>
        </Fragment>
    )
}

export default Application