import {
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
  } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PopupMenu = (props) => {
    const { items, anchorEl, onClose, onClick } = props
    const { t } = useTranslation()

    if (!items) return null
  
    return (
      <Menu
        anchorEl={anchorEl}
        id="bottom-menu-popup"
        elevation={6}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={onClose}>
        {items.map(
          (item, index) => 
            React.isValidElement(item) 
            ?
            <section key={`${item}`}>{item}</section>
            :
            <MenuItem key={`mi-${index}`} onClick={(event) => onClick(event, item)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{t(item.label)}</ListItemText>
            </MenuItem>
          )
        }
      </Menu>
    )
  }

  export default PopupMenu