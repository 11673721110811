// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { update } from '../../utilities/common.js'

// Initial state
const initialState = {
    info: null,
    presenter: null,
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {
        case actionTypes.DEVICE_SET: return update(state, action.payload)
        default: return state
    }
}

export default reducer
