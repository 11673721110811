// React imports
import React, { useState } from 'react'

import {
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel, 
    TextField,
    Link,
    Grid,
    Box,
} from '@material-ui/core'

// import i18next
import { useTranslation } from 'react-i18next'

const Form = (props) => {
        
    const { t } = useTranslation()
    
    //NOTE: necessary to define an initial value to avoid controlled/non controller problem!
    const [state, setState] = useState({credentials: {
        username: "",
        password: ""
    }})
    
    const handleInputChange = (event) => {
        const newState = { ...state.credentials }
        newState[event.target.name] = event.target.value
        setState({ credentials: newState })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        props.handleSubmit(state.credentials)
    }


    return ( 
    <>
        <form className={props.classes.form}>
            <TextField
                    type="email"
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="username"
                name="username"
                label={t("Username")}
                id="username"
                autoFocus={true}
                margin="normal"
                required
                fullWidth
                value={state.credentials.username}
                onChange={handleInputChange}
                variant="filled"
                className={props.classes.textField}/>
            <TextField
                className={props.classes.textField}
                margin="normal"
                required
                fullWidth
                value={state.credentials.password}
                name="password"
                label={t("Password")}
                type="password"
                id="password"
                autoComplete="password"
                onChange={handleInputChange}
                variant="filled"/>

            <Box mt={4} mb={3}>
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                    <Grid item xs={7}>
                        <Link
                        href="#0"
                        underline="none"
                        onClick={props.handleForgotPassword} >{t("Forgot Password")}</Link>
                    </Grid>
                    <Grid item xs={5}>
                        <Link href="#" variant="body2">
                            <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            >
                            {t("Sign In")}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </form>
        <Box className={props.classes.storePasswordBox}>
        <FormGroup>
            <FormControlLabel control={
            <Checkbox
                checked={props.forcedAutoLogin} 
                onChange={(e) => props.setForcedAutoLogin(!props.forcedAutoLogin)}
                />
            } label={t("Save password")} />
        </FormGroup>
        </Box>
    </>
    )
}

export default Form