import { v4 as uuidv4 } from 'uuid';

export default class DisplayableError extends Error {
    
    constructor(exception, message, title) {
        
        super(message)
    
        this.title = title
        this.exception = exception
        this.date = new Date()
        this.acknowledged = false
        this.uuid = uuidv4()

        console.debug(`ERROR has been created with date: Date: ${this.date} Message: ${this.message} Exception: ${this.exception}`)
    }

}