// React imports
import React from 'react'

// Redux imports
import { connect } from 'react-redux'

// Material UI
import {
    makeStyles,
} from '@material-ui/core/'

import ZoomControlButtons from './ZoomControlButtons'
import FitControlButton from './FitButton'

const useStyles = makeStyles((theme) => ({
    container: {
      //flex
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: ".5rem",
      bottom: "1rem",
      position: "absolute",
      right: ".5rem",
      zIndex: 700,
      "& *": {
        zIndex: 700,
      },
    },
}));

const MapControlButtons = (props) => {

    const classes = useStyles()
   
    return (
        <div className={classes.container}>
            <FitControlButton />
            <ZoomControlButtons />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapControlButtons)