// Basic imports
import React from 'react'
import { connect } from 'react-redux'
import * as COM from '../../utilities/common'

// Material UI
import { 
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'

// Icons
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

// i18n imports
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    icon: {
      color: theme.palette.grey[400],
      marginRight: "0.5rem",
    },
  }))

const RefuelsListHeader = (props) => {

    const classes = useStyles()
    const { t } = useTranslation()
  
    //XXX: !!! MISLEADING NAMES !!!
    //XXX: Nameing is misleading, be carefull!
    //XXX: The state contains a 'refuels' object.
    //XXX: The refuels object contains a 'refuels' list.
    const refuelsState = props.state.refuels

    const fromDateStr = COM.formattedDate(refuelsState.dateRangeFrom)
    const toDateStr = COM.formattedDate(refuelsState.dateRangeTo)
    const targetName = refuelsState.target ? refuelsState.target.name : null
  
    const title = `${t("Refuels")} ${targetName}`
    const subTitle = `${fromDateStr} - ${toDateStr}`

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={4} md={2}>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={10}>
                    <Typography variant="h6">{subTitle}</Typography>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={4} md={2} container alignItems="center">
                <   AccessTimeIcon fontSize="small" className={classes.icon} />
                    {t("Date of refuel")}
                </Grid>
                <Grid item xs={12} sm={8} md={6} container alignItems="center">
                    <LocationOnIcon fontSize="small"  className={classes.icon} />
                    {t("Place of refuel")}
                </Grid>
                <Grid item xs={12} sm={12} md={4} container alignItems="center">
                    <LocalGasStationIcon fontSize="small" className={classes.icon} />
                    {t("Before refuel")}(L) + {t("After refuel")}(L)
                </Grid>
            </Grid>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {}
}
  
const mapStateToProps = state => {
    return {
        state: state,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RefuelsListHeader)