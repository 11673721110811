// React imports
import React, {useEffect, useState} from 'react'

// Redux imports
import { connect } from 'react-redux'

import {
  makeStyles,
  Input,
  IconButton,
  FormControl,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'

import CancelIcon from '@material-ui/icons/CancelRounded'

import * as chatActions from '../../store/actions/chat'
import * as COM from '../../utilities/common'
import { useTranslation } from 'react-i18next'

 // i18n imports
//import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0.5rem",
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  filterContainer: {
    flex: 0,
  },
  listConteiner: {
    flexGrow: 1,
    position: "relative",
  },
  listWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
  },
  progressContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameFilter: {
    marginTop: "1rem", 
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    height: "2.5rem",
  },
  filterInput: {
    boxSizing: "border-box",
    width: "100%",
    fontSize: "135%",
  },
  filterNotInUse: {
    backgroundColor: "white"
  },
  filterUnprocessed: {
    backgroundColor: "orange"
  },
  filterProcessed: {
    backgroundColor: "yellow"
  },
  button: {
    //
  },
    
  selected: { 
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white",
    "& div": {
      color: "white",
    }
  },
  normal: {
    "& div": {
      color: theme.palette.grey[900],
    },
    "& > div:first-child": {
      fontWeight: "bold"
    },
    "& > div:nth-child(2)": {
      fontSize: "90%"
    }
  },
  odd: {
    backgroundColor: theme.palette.grey[200]
  },
  even: {
    backgroundColor: "white"
  }
}));

const ChatFilter = (props) => {

    const classes = useStyles()
    const { t } = useTranslation()
    const { state } = props
    const { chat }  = state
    const { threads, filteringInProgress } = chat

    //const filter = chat.filter
    const selectedThreadId = chat.selectedThreadId

    const [filterBuffer, _setFilterBuffer] = useState({
      text: chat.filter,
      processed: true,
    })

    const setFilterBuffer = (text, processed) => {
      _setFilterBuffer({
        text: text || "",
        processed: processed
      })
    } 

    const getLastMessage = (messages) => {
      if (!messages || messages.length === 0) return null
      return messages[0].content.body
    }

    const threadList = threads.map(t => ({
      name: `${t.threadUser.name}`,
      lastMessage: getLastMessage(t.messages),
      id: t.id
    }))

    const handleFilterChange = (evt) => {
      setFilterBuffer(evt.target.value, false)
      //props.changeFilter(evt.target.value)
    }

    const handleClearFilter = (evt) => {
      setFilterBuffer("", false)
      //props.changeFilter("")
    }

    const handleTabClick = (threadId) => {
        //threadIdSelectedByUser: (payload) => dispatch(chatActions.threadIdSelectedByUser(payload)),
        props.threadIdSelectedByUser(threadId)
    }

    const checkBuffer = () => {

      if (filterBuffer.processed) return // In this case, we do not need to do anything

      props.changeFilter(filterBuffer.text)
      setFilterBuffer(filterBuffer.text, true)
    }

    useEffect(() => {
      const bufferInterval = setInterval(checkBuffer, 250)
      return () => clearInterval(bufferInterval)
    // eslint-disable-next-line
    },[threadList])

    let filterClass = classes.filterUnprocessed
    if (filterBuffer.processed) {
      if (filterBuffer.text && filterBuffer.text.length > 3) {
        filterClass = classes.filterProcessed
      } else {
        filterClass = classes.filterNotInUse
      }
    }

    return(
        <div className={classes.root}>
          <div className={classes.filterContainer}>
            <FormControl className={classes.nameFilter}>
                <Input
                  id="name-filter"
                  type="text"
                  value={filterBuffer.text}
                  className={COM.cls(classes.filterInput, filterClass)}
                  onChange={handleFilterChange}
                  placeholder={t("Please type a car plate")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Clear the fillter"
                        onClick={handleClearFilter}>
                        <CancelIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
          </div>
          <div className={classes.listConteiner}>
              {filteringInProgress 
              ? <div className={classes.progressContainer}><CircularProgress /></div>
              : <div className={classes.listWrapper}>
                {threadList.map( (t, i) => (
                  <div className={`${i % 2 === 0 ? classes.odd : classes.even} ${t.id === selectedThreadId ? classes.selected : classes.normal}`} onClick={(ev) => handleTabClick(t.id)} key={t.id}>
                    <div className={classes.topic}>{t.name}</div>
                    <div className={classes.subtopic}>{t.lastMessage}</div>                
                  </div>
                ))}
              </div>}
          </div>
        </div>
    )
}

function mapStateToProps(state) {
  return {
    state: state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    threadIdSelectedByUser: (payload) => dispatch(chatActions.threadIdSelectedByUser(payload)),
    changeFilter: (payload) => dispatch(chatActions.changeFilter(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatFilter)