import { SvgIcon } from "@material-ui/core"

const ArrowSvgIcon = (props) => {

    const strokeWidth = props.strokeWidth || 0
    const strokeColor = props.strokeColor || "black"
    const fill = props.fill || "white"
    
    return (
    <SvgIcon viewBox='0 0 24 24'>
        <g fill={fill} stroke={strokeColor} storke-width={strokeWidth}>
            <path d="M 22 12 L 12 22 L 12 15 L 2 15 L 2 9 L 12 9 L 12 2 L 22 12" />
        </g>
    </SvgIcon>
    )
}

export default ArrowSvgIcon