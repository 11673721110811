import Target from "./Target";

const Targets = ({ items, selectedItems, onClick }) => {

    const isInSelectedById = (arr, id) => {

        for (var i = 0; i < arr.length; i++) {
            const e = arr[i]
            //We have to check the id and the target.id too to work generally for all of the modules.
            //The structure of 'target' and 'targets' are not the same for refuels, routes and tracking.
            if (e.id === id) return true;
        }
        return false
    }

    return items.map((item, index) => {

        const selected = selectedItems && selectedItems.length > 0 ? isInSelectedById(selectedItems, item.id) : false
        const handleClick = onClick.bind(null, item)

        return (
            <div key={index} onClick={handleClick} >
                <Target item={item} index={index} selected={selected} />
            </div>
        )

    })
}

export default Targets