// React imports
import React, {useEffect, useState, createRef } from 'react'
import { connect } from 'react-redux'
import * as chatActions from '../../store/actions/chat'
import {getItemFromAnArrayById as getById} from '../../utilities/common'

// i18n imports
import { useTranslation } from 'react-i18next'

// Material UI
import {
  makeStyles,
  IconButton,
  Typography,
}from '@material-ui/core'

// Icons
import SendIcon from '@material-ui/icons/Send'

// Own components
import TopDrawer from '../Drawer/TopDrawer'
import NoResult from '../Filter/NoResult'
import MessageList from './MessageList'
import ChatFilter from './ChatFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    height: "3rem", //we have to set the height here. We will use this value to calculate the scroll area's height
    backgroundColor: theme.palette.grey[200],
    display: "flex",
    alignItems: "center",
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
  },
  body: {
    flexGrow: 1,
    overflowY: "scroll",
  },
  footer: {
    height: "3rem", //we have to set the height here. We will use this value to calculate the scroll area's height
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
    display: "flex",
    backgroundColor: theme.palette.grey[200],
    alignItems: "center",
  },
  spacer: {
    minWidth: "140px",
    maxWidth: "140px",
  },
  input: {
    flexGrow: 1,
  },
  button: {
    //
  },
  textinput: {
    float: "right",
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "480px",
    border: "none",
    fontSize: "135%",
    backgroundColor: "transparent",
    borderBottom: `solid 1px ${theme.palette.grey[900]}`,
    '&:focus': {
      outline: "none",
    }
  }
}));

const ChatInput = ({show, sendMessage, scrollToTheBottom}) => {

  const classes = useStyles()
  const [text, setText] = useState("")

  if (!show) return null

  const handleOnChange = (ev) => {
    scrollToTheBottom()
    setText(ev.target.value)
  }

  const handleInputClick = (ev) => {
    scrollToTheBottom()
  }

  const handleSendClick = (evt) => {
    scrollToTheBottom()
    sendMessage(text)
    setText("")
  }

  return (
    <>

      <div className={classes.input}>
        <input className={classes.textinput} type="text" onClick={handleInputClick} onChange={handleOnChange} value={text} />
      </div>

      <div className={classes.button}>
        <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleSendClick}
            disabled={!(text.length > 0)}
            variant="contained">
            <SendIcon />
        </IconButton>
      </div>

    </>
  )
}

const Chat = (props) => {  

  const { t } = useTranslation()
  const classes = useStyles()

  const chat = props.state.chat
  const selectedThreadId = chat.selectedThreadId
  const selectedThread = getById(chat.threads, selectedThreadId)
  const topic = selectedThread ? selectedThread.threadUser.name : <NoResult message={t("No thread selected")} />
  const messages = selectedThread ? selectedThread.messages : null
  const firstMessageSentTime = selectedThread ? selectedThread.firstMessageSentTime : null
  const messageListContainerRef = createRef()

  const scrollToTheBottom = () => {
    const elem = messageListContainerRef.current
    elem.scrollTop = elem.scrollHeight
  }

  const handlePreviousClick = () => {
    console.debug("Load previus messages before: ", firstMessageSentTime)
    props.getMessages({
      threadId: selectedThreadId,
      lastTimeMicros: firstMessageSentTime
    })
  }

  //NOTE: we have to scroll to the bottom, if we got a new message!
  const lastMessageSentTime = selectedThread && selectedThread.lastMessageSentTime ? selectedThread.lastMessageSentTime : null
  useEffect(() => {
    if (lastMessageSentTime && messageListContainerRef.current) {
      //console.debug(`The 'lastMessageSentTime' for thread ${selectedThread.id} is ${lmst}. Scrolling down!`)
      scrollToTheBottom()
    }
  // eslint-disable-next-line
  },[lastMessageSentTime])
 
  return (
    <>

      <TopDrawer inProgress={chat.inProgress}>
          <ChatFilter />
      </TopDrawer>

      <div className={classes.root}>

        <div className={classes.header}>
          <Typography variant="h6">{topic}</Typography>
        </div>
        
        <div className={classes.body}>
          <div ref={messageListContainerRef}>
            <MessageList
              messages={messages}
              firstMessageSentTime={firstMessageSentTime}
              handlePreviousClick={handlePreviousClick} />
          </div>
        </div>
        
        <div className={classes.footer}>
          <div className={classes.spacer}>&nbsp;</div>

          <ChatInput show={selectedThread} scrollToTheBottom={scrollToTheBottom} />
          
        </div>

      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getMessages: (payload) => dispatch(chatActions.getMessages(payload)),
  }
}

connect(mapStateToProps, mapDispatchToProps)(ChatInput)
export default connect(mapStateToProps, mapDispatchToProps)(Chat)