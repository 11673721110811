// React imports
import React from 'react'

// Redux imports
import { connect } from 'react-redux'

import TrackingFitButton from './TrackingFitButton'
import RoutesFitButton from './RoutesFitButton'

const FitButton = (props) => {

    const { state } = props
    const { settings } = state
    const sbmi = settings.selectedBottomMenuItem

    switch (sbmi) {
      case 1: return <TrackingFitButton />
      case 2: return <RoutesFitButton />
      default: return null
    }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FitButton)