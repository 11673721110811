import * as actionTypes from './actionTypes'

export const generateStart = (targetId, fromDate, toDate, email, variant) => {
    return {
        type: actionTypes.SHARE_TARGET_GENERATE_START,
        targetId: targetId,
        fromDate: fromDate,
        toDate: toDate,
        email: email,
        variant: variant,
    }
}

export const generateSuccess = (loginToken) => {
    return {
        type: actionTypes.SHARE_TARGET_GENERATE_SUCCESS,
        loginToken: loginToken
    }
}

export const senerateFail = (error) => {
    return {
        type: actionTypes.SHARE_TARGET_GENERATE_FAIL,
        error: error
    }
}