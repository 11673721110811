// Action Types import
import * as actionTypes from '../actions/actionTypes'

// Other imports
import * as COM from '../../utilities/common.js'

// Initial state
const initialState = {
    target: null,
    inProgress: false,
    items: [],
    multiselect: false,
    dateRangeFrom: COM.createNewDateWithTimeSetToStartOfDay(COM.TODAY),
    dateRangeTo: COM.createNewDateWithTimeSetToEndOfDay(COM.TODAY),
    selectedTab: 0,
    bounds: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.ROUTES_ADD_TARGET: return COM.update(state, {target: action.payload})
        case actionTypes.ROUTES_DELETE_TARGET: return COM.update(state, {target: null})

        case actionTypes.ROUTES_FETCH_START: return COM.update(state, {inProgress: true})
        case actionTypes.ROUTES_FETCH_FAIL: return COM.update(state, {inProgress: false})
        case actionTypes.ROUTES_FETCH_SUCCESS: return COM.update(state, {inProgress: false, items: action.payload})

        case actionTypes.ROUTES_SET_MULTISELECT: return COM.update(state, {multiselect: action.payload})
        
        case actionTypes.ROUTES_SET_ITEMS: return COM.update(state, {items: action.payload})
        case actionTypes.ROUTES_SELECT_ALL_ITEMS: return COM.update(state, {multiselect: true})

        case actionTypes.ROUTES_SET_DATE_RANGE: return COM.update(state, {dateRangeTo: action.to, dateRangeFrom: action.from})
        case actionTypes.ROUTES_SET_DATE_RANGE_TO: return COM.update(state, {dateRangeTo: action.dateRangeTo})
        case actionTypes.ROUTES_SET_DATE_RANGE_FROM: return COM.update(state, {dateRangeFrom: action.dateRangeFrom})

        case actionTypes.ROUTES_SET_SELECTED_TAB: return COM.update(state, {selectedTab: action.payload})

        case actionTypes.ROUTES_SET_BOUNDS: return COM.update(state, {bounds: action.payload})

        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return COM.update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer