// React imports
import React from 'react'

// i18n imports
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Application from './Application'
import Environment from './Environment'
import DeviceInfo from './DeviceInfo'
import Language from './Language'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      overflowY: "scroll",
    },
}))

const SystemInfo = () => {

    const { t } = useTranslation()
    const classes = useStyles()

    return(
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Typography variant="h4" component="h4">
                    {t("System Info")}
                </Typography>
                <Table aria-label="simple table">
                    <TableBody>
                        <Application />
                        <Environment />
                        <DeviceInfo />
                        <Language />
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default SystemInfo