// React imports
import React from 'react'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles( theme => (
    {
        root: {
            fontSize: theme.typography.fontSize * 0.85,
            [theme.breakpoints.up('md')]: {
                fontSize: theme.typography.fontSize
            }
        }
    }
))

const Copyright = ( props => {
    
    const classes = useStyles()

    return (
        <Typography variant="body2" color="textSecondary" align="center" className={classes.root}>
            &copy;&nbsp;<Link color="inherit" href="https://itrack.hu/">iData Kft.</Link>&nbsp;{new Date().getFullYear()}
        </Typography>
    )
})

export default Copyright