import React, { useRef } from 'react'
import { connect } from 'react-redux'
import * as routesActions from '../../store/actions/routes'

// i18n imports
import { useTranslation } from 'react-i18next'

// Other
import InfiniteScroll from 'react-infinite-scroller'
import InfiniteScrollMessage from '../InfiniteScroll/InfiniteScrollMessage'

import {
  Grid,
  makeStyles,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import ItemRow from './ItemRow'
import ClearSelectedItems from '../Filter/ClearSelectedItems'
import NoResult from '../Filter/NoResult'


const useStyles = makeStyles((theme) => ({
  
   wrapper: {
    flex: 1,
    alignItems: "stretch",
    width: `100%`,

    //just for debugging
    //backgroundColor: "green",
  },

  /* This wrapper is necessary to fit & scroll perfectly */
  scrollerWrapper: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  scroller: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "scroll",

    //for debug:
    //backgroundColor: "yellow",
  },

}));

const RouteItems = (props) => {

  const { items, selectedItems } = props
  
  const isMultipleSelected = (selectedItems && selectedItems.length > 1)
  const summaryItems = isMultipleSelected ? selectedItems : items;

  /*console.debug(`Summary items: ${summaryItems.length}`, summaryItems)
  console.debug(`Selected items: ${selectedItems.length}`, selectedItems)
  console.debug(`Items: ${items.length}`, items)*/

  const summary = {
    startPosition: summaryItems[0].startPosition,
    stopPosition: summaryItems[summaryItems.length - 1].stopPosition,
    durationTimeMicros: 0,
    distanceKm: 0,
    standTimeMicros: 0,
    itemCount: summaryItems.length,
    isMultipleSelected: isMultipleSelected
  }

  summaryItems.forEach( s => {
    summary.standTimeMicros += s.standTimeMicros
    summary.durationTimeMicros += s.durationTimeMicros
    summary.distanceKm += s.distanceKm
  })

  const itemRows = items.map( (s, i) => {
    return <ItemRow section={s} key={`section-${i}`} index={i} />
  })

  return [
    ...itemRows,
    <ItemRow section={summary} key={`section-summary`} isSummary />
  ]
}


const ItemList = ({items, selectedItems, next, hasMore, onTargetClick}) => {
  
  const classes = useStyles()
  const scrollParentRef = useRef(null)
  const { t } = useTranslation()

  if (items.length === 0) return <NoResult />

  return (

    <div className={classes.wrapper}>
      
       {/*
          Maybe it seems overkiller but it's not!
          To scroll and fit preciselly this dual div solution looks the easiest way!  
        */}
        <div className={classes.scrollerWrapper}>
          <div className={classes.scroller} ref={scrollParentRef} >
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={next}
              hasMore={hasMore}
              useWindow={false}
              getScrollParent={() => scrollParentRef.current}
              loader={<InfiniteScrollMessage message={t("Loading targets...")} />}>
                
              <RouteItems items={items} selectedItems={selectedItems} />

            </InfiniteScroll>
        </div>
      </div>
    </div>
  )
} 

const RoutesFilter = (props) => {

  const { t } = useTranslation()
  const { state } = props
  const { routes } = state
  const { items } = routes
  const itemsCount = items.length

  const selectedItems = items.filter( itm => itm.checked === true)
  const checkedCount = selectedItems.length

  const handleSwitchChange = (ev, value) => {
    props.setMultiselect(value)
  }

  const handleClearSelectedItems = (ev) => {
    props.clearSelectedSections()
  }

  if (itemsCount < 1) return <NoResult />

  return (
        <>
          
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={3}>
                <ClearSelectedItems
                  label="routes"
                  selectedCount={checkedCount}
                  onClick={handleClearSelectedItems}/>
              </Grid>
              <Grid item xs={9} container justifyContent="flex-end" alignItems="center">
                <FormControlLabel
                  control={<Switch checked={routes.multiselect} onChange={handleSwitchChange} name="multiselectChecker" />}
                  label={t("Mutiselect")}/>
              </Grid>
            </Grid>

            <ItemList
              items={items}
              selectedItems={selectedItems}
              hasMore={false}
            />
        
        </>
    )
}

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMultiselect: (value) => dispatch(routesActions.setMultiselect(value)),
    clearSelectedSections: () => dispatch(routesActions.clearSelectedSections()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesFilter)