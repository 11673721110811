import L from 'leaflet'

const LeafIcon = L.Icon.extend({
    options: {
        iconAnchor: null,
        popupAnchor: [0,0], //NOTE: Very important to set, cannot be null, otherwise the leaflet library will crash! I did spend hours to find this bug ;)
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(24, 24),
    }
})

const divIconMaker = (iconHTML, className) => {
    return new L.DivIcon({
      className: className,
      html: iconHTML,
      iconAnchor: null,
      popupAnchor: [0,0], //NOTE: Very important to set, cannot be null, otherwise the leaflet library will crash! I did spend hours to find this bug ;)
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(24, 24),
    });
}


export { divIconMaker, LeafIcon }