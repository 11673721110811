// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { update } from '../../utilities/common.js'

// Initial state
const initialState = {
    inProgress: false,              // Progress indicator
    autocompleteInProgress: false,  // Dedicated flag for autocompletition progress indication
    items: [],                      // List of items from the offset position
    count: 0,                       // Total count of the targets stored in the remote DB
    offset: 0,                      // Query offset position
    
    // The filter used for the last query
    filter: "",             

    // The value of this guy is the current value of the input field on the target selector module. 
    inputFilter: "",        
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.TARGETS_SET: return update(state,
        {
            items: action.items,
            offset: action.offset,
            filter: action.filter,
        })

        case actionTypes.TARGETS_SET_ITEMS: return update(state, { items: action.payload })
        
        case actionTypes.TARGETS_SET_INPUT_FILTER: return update(state, {inputFilter: action.payload})

        case actionTypes.TARGETS_SET_COUNT: return update(state, {count: action.payload})

        case actionTypes.TARGETS_SET_IN_PROGRESS: return update(state, {inProgress: action.payload})

        case actionTypes.TARGETS_SET_AUTOCOMPLETE_IN_PROGRESS: return update(state, {autocompleteInProgress: action.payload})

        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer