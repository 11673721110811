// React imports
import React, { useState } from 'react'

import * as COM from '../../utilities/common'

import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
}
from '@material-ui/core'
import striptags from 'striptags'



const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: '50vh', //TODO: proper size calculation!
        overflow: 'scroll',
    },
    table: {
        "& td": {
            borderBottom: `solid 1px ${theme.palette.primary.light}`,
        },
        "& *": {
            fontSize: "0.75rem",
            color: "white",
        }
    },
    timestampRow: {
        "& td": {
            fontSize: "90%",
            padding: 0,
            margin: 0,
            opacity: "0.9",
        }
    },
    noBottomBorder: {
        "& td": {
            borderBottom: "none"
        }
    }
}))

const Timestamp = (props) => {
    if (!props.show) return null
    const timestampMicros  = props.timestampMicros
    if (!timestampMicros) {
        console.debug("No timestampMicros provided for <Timestamp> component!", props)
        return
    }
    const time = COM.createFrontendTimeMilis(timestampMicros)
    const formattedTime = COM.formattedDateTime(time)
    return <TableCell colSpan={2}>{formattedTime}</TableCell>
}

const Row = (props) => {

    const classes = useStyles()

    const { row } = props
    const [timestampVisible, setTimestampVisible] = useState(false)
    
    const toggleVisible = () => setTimestampVisible(!timestampVisible)

    return (
        <>
            <TableRow className={timestampVisible ? classes.noBottomBorder : null} onClick={toggleVisible}>
                <TableCell padding="none" align="left">{row.name}</TableCell>
                <TableCell padding="none" align="right">{striptags(row.value)}</TableCell> 
            </TableRow>
            <TableRow  className={classes.timestampRow}>
                <Timestamp show={timestampVisible} timestampMicros={row.timestampMicros} />    
            </TableRow>
        </>
    )
}

const KeyValueTable = (props) => {

    const classes = useStyles()
    
    return(
        <div className={classes.container}>
            <TableContainer>
                <Table className={classes.table} aria-label="keyvalue-table">
                    <TableBody>
                        {props.array.map( row => <Row row={row} key={row.name} /> )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default KeyValueTable