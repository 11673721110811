// React imports
import React from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'

// i18n imports
import { useTranslation } from 'react-i18next';

import TextRow from './TextRow';
import { Button } from '@material-ui/core';
import NoResult from '../Filter/NoResult';


const useStyles = makeStyles((theme) => ({
  root: {
      marginTop: "0.5rem",
      maxWidth: "100%",
      textAlign: "center",
  },
}));

const PreviousMessageRow = (props) => {
  
  const classes = useStyles()
  const { t } = useTranslation()
 
  return (
    <div className={classes.root}>
        <Button onClick={props.handlePreviousClick}>
            {t("Load previous messages")}
        </Button>
    </div>
  )
}

const MessageList = (props) => {

    const { t } = useTranslation()
    const { messages, firstMessageSentTime } = props

    if (!messages) return <NoResult message={t('No message')} />
    
    const rows = []
    messages.forEach( (m, idx) => {
      rows.unshift(<TextRow message={m} key={`${m.id}-${idx}`}/>)
    })
    
    if (firstMessageSentTime) { rows.unshift(<PreviousMessageRow {...props} key={`chat-previous-message-row-${firstMessageSentTime}`} />) }

    return rows.map(r => r)
}

export default MessageList