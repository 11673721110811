// import for i18next
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// imports of language files
import english from './translations/en.json';
import hungarian from './translations/hu.json';
import croatian from './translations/hr.json';
import serbian from './translations/sr.json';
import czech from './translations/cz.json';
import slovakian from './translations/sk.json';
import romanian from './translations/ro.json';

// To set the fallback language, use the following line
// fallbackLng: "hu" between the lng and interpolation

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            enUS: {
                translation: english
            },
            hu: {
                translation: hungarian
            },
            ro: {
                translation: romanian
            },
            sr: {
                translation: serbian
            },
            sk: {
                translation: slovakian
            },
            cz: {
                translation: czech
            },
            hr: {
                translation: croatian
            }
        },
        lng: "en",
        interpolation: {
            escapeValue: false
        }
    });