import { eventChannel } from 'redux-saga'
import { takeEvery, select, race, take, fork, putResolve, put} from 'redux-saga/effects'

import * as actionTypes from '../../actions/actionTypes'
import * as mapEventsActions from '../../actions/mapEvents'

const EVENT_MOUSE_DOWN = "mousedown"
const EVENT_MOUSE_UP = "mouseup"
const EVENT_MAP_MOVE = "move"
const EVENT_MAP_ZOOM = "zoom"

function* eventHandler(channel) {

    let mouseDidDown = false
    let mouseDidUp = false
    let mapDidMove = false
    let mapDidZoom = false

    const clearFlags = () => {
        mouseDidDown = false
        mouseDidUp = false
        mapDidMove = false
        mapDidZoom = false
    }
   
    while (true) {

        const raceResult = yield race({
            mapEvent: take(channel),
            cancelEvent: take(actionTypes.MAP_EVENTS_STOP_CHANNEL),
        })
       
        // Due to this fact, this channel emits events on very high frequency
        // do not use the console just for debugging! 
        // console.debug("Emission from map event channel: ", raceResult)

        const { mapEvent, cancelEvent } = raceResult

        if (mapEvent) {

            //console.debug("mapEvent.type", mapEvent.type)

            // Handling the zoom event, 'broadcast' the zoom
            if (mapEvent.type === EVENT_MAP_ZOOM) {
                yield put(mapEventsActions.mapDidZoom())
            }

            // Managing the event flags.
            switch (mapEvent.type) {
                case EVENT_MOUSE_DOWN: mouseDidDown = true; break;
                case EVENT_MOUSE_UP: mouseDidUp = true; break;
                case EVENT_MAP_MOVE: mapDidMove = true; break;
                case EVENT_MAP_ZOOM: mapDidZoom = true; break;
                default: console.debug("Unknown map event:", mapEvent.type, mapEvent)
            }

            // we start our event handling process
            if (mouseDidDown) clearFlags()

            // we finish our event handling process
            if (mouseDidUp && mapDidMove && !mapDidZoom) {
                clearFlags()
                console.debug("Map has been moved by the user", mapDidMove, mapDidZoom)
                yield putResolve(mapEventsActions.mapMovedByUser())
            }
        }

        if (cancelEvent) {
            channel.close()
            console.debug("Map event channel has been closed.")
            return
        }
    }
}


function* startMapEventChannel() {

    const mouseEventNames = [
        EVENT_MOUSE_DOWN,
        EVENT_MOUSE_UP,
        EVENT_MAP_MOVE,
        EVENT_MAP_ZOOM,
    ]

    const { map } = yield select()
    const { ref } = map

    const mapEventChannel = eventChannel(emitter => {
        mouseEventNames.forEach( e => ref.on(e, emitter))

        return () =>  mouseEventNames.forEach( e => ref.off(e, emitter))
    })

    yield fork(eventHandler, mapEventChannel)
}

function* restartEventChannel() {
    yield putResolve(mapEventsActions.stopMapEventsChannel())
    yield putResolve(mapEventsActions.startMapEventsChannel())
}

function* handleSetRef(action) {
    const { ref } = action
    if (ref) yield restartEventChannel()
}

export function* saga() {
    yield takeEvery(actionTypes.MAP_EVENTS_START_CHANNEL, startMapEventChannel)
    yield takeEvery([
        actionTypes.MAP_FIT_TO_BOUNDS_USER_REQUEST,
        actionTypes.MAP_ZOOM_USER_REQUEST,
    ], restartEventChannel)
    yield takeEvery(actionTypes.MAP_SET_REF, handleSetRef)
}