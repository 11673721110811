import * as COM from '../../utilities/common'

import { takeEvery, putResolve, select } from 'redux-saga/effects'

import * as actions from './../actions/settings'
import * as actionTypes from '../actions/actionTypes'

function* handleTrackingSetTargets(action) {

    const targets = action.payload
    const storableArray = targets.map( i => { return {
        target: i.target,
        frontendTimeMicros: i.frontendTimeMicros,
        status: COM.TRACKING_STATUS_PENDING
    }})
    
    yield putResolve(actions.setTrackingTargets(storableArray))

}   

function* handleFilterPaneVisibilityChange(action) {
    const filterPaneVisible = action.payload
    yield filterPaneVisible && putResolve(actions.setFloatingMenuOpen(false)) //Close the floating menu
    yield filterPaneVisible && putResolve(actions.setProfilePaneVisible(false)) //Hide the profile pane
}

function* handleFloatingMenuOpenChange(action) {
    const floatingMenuOpen = action.payload
    yield floatingMenuOpen && putResolve(actions.setFilterPaneVisible(false))
}

function* handleProfilePaneVisibilityChange(action) {
    const profilePaneVisible = action.payload
    yield profilePaneVisible && putResolve(actions.setFilterPaneVisible(false)) //Hide the filter pane
}

function* handleAuthSucces(action) {
    const { auth } = yield select()
    const { credentials } = auth 
    const userId = credentials.id
    yield putResolve(actions.setUserId(userId))
    yield putResolve(actions.userIdStored())
}

function* handleSignOutSuccess(action) {
    // Erease the stored user Id
    yield putResolve(actions.setUserId(null))

    // close all possible open pane and windows
    yield putResolve(actions.setFilterPaneVisible(false))
    yield putResolve(actions.setFloatingMenuOpen(false))
    yield putResolve(actions.setProfilePaneVisible(false))
}

export function* saga() {
    yield takeEvery(actionTypes.SETTINGS_SET_FILTER_PANE_VISIBLE, handleFilterPaneVisibilityChange)
    yield takeEvery(actionTypes.SETTINGS_SET_FLOATING_MENU_OPEN, handleFloatingMenuOpenChange)
    yield takeEvery(actionTypes.SETTINGS_SET_PROFILE_PANE_VISIBLE, handleProfilePaneVisibilityChange)

    yield takeEvery(actionTypes.TRACKING_SET_TARGETS, handleTrackingSetTargets)

    yield takeEvery(actionTypes.AUTH_SUCCESS, handleAuthSucces)
    yield takeEvery(actionTypes.AUTH_SIGN_OUT_SUCCESS, handleSignOutSuccess)
}