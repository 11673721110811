import React from "react"
import { connect } from 'react-redux'

import * as settingsActions from '../../store/actions/settings'

import { 
  makeStyles,
  Drawer,
} from '@material-ui/core/'
import ModalProgressIndicator from "../App/ModalProgressIndicator"

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      maxWidth: theme.breakpoints.values.sm,
      boxSizing: "content-box",

      //We only set the height here to avoid any problem!
      height: `calc(100% - ${process.env.REACT_APP_BOTTOM_MENU_HEIGHT})`,

      overflow: "hidden",
      
      display: "flex",
      alignItems: "stretch",

    },
    childrenContainer: {
      padding: ".5rem",
      boxSizing: "content-box",
      
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    
    },
  }))

const TopDrawer = (props) => {

    const classes = useStyles()

    const { children, state, inProgress, progressMessage, setFilterPaneVisible } = props
    const { settings } = state
    const { filterPaneVisible } = settings

    const handleOnClose = (value) => {
      console.debug("Click happend outside of the drawer.")
      if (process.env.REACT_APP_CLOSE_FILTER_PANE_ON_CLICK_OUTSIDE) {
        console.debug(" setFilterPaneVisible(false) called.")
        setFilterPaneVisible(false)
      }
    }

    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper
        }}

        //Customer's request, PLUTO-1238 
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        //^^^ is it a good idea to set the background color to transparent?
        //^^^ Doest it decrease the user experience?
        
        anchor="top"
        onClose={handleOnClose}
        open={filterPaneVisible} >
        <div className={classes.childrenContainer}>
          {children}
        </div>
        <ModalProgressIndicator flag={inProgress} message={progressMessage} />
      </Drawer>
    )
}

const mapDispatchToProps = dispatch => {
    return {
      setFilterPaneVisible: (value) => dispatch( settingsActions.setFilterPaneVisible(value) )
    }
  }
  
const mapStateToProps = (state) => {
    return {
        state: state,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TopDrawer)