import { buffers } from 'redux-saga'
import { actionChannel, take, takeEvery, put, putResolve, call, select, fork } from 'redux-saga/effects'
import * as actionTypes from '../actions/actionTypes'
import * as actions from './../actions/targets'
import * as targetRepo from '../repository/targets'

const TARGETS_LIMIT = process.env.REACT_APP_TARGETS_LIMIT || 30


function* fetchCount(action) {

    try {

        const { auth, settings } = yield select()
        const { filter } = action
        const { credentials } = auth

        //Getting the count of the items for the specified filter
        const { data } = yield call(targetRepo.getTargetCount,
        {
            credentials: credentials,
            filter: filter,
            acceptLanguage: settings.languageCode,
        })
        console.debug(`Get target count finished: ${data}`)

        return data

    } catch (ex) {

        console.error("Error while fetching targets count!", ex)
        yield put(actions.fetchCountFail(ex))
    
    }
}

function* fetchItems(action) {

    try {
        
        const { auth, settings } = yield select()
        const { offset, filter } = action
        const { credentials } = auth
        const limit = TARGETS_LIMIT
       
        //Getting the items by using the specified filter
        const result = yield call(targetRepo.getTargets,
        {
            credentials: credentials,
            offset: offset,
            filter: filter,
            limit: limit,
            acceptLanguage: settings.languageCode,
        })
        const items = result.data

        console.debug(`Get targets finished: ${items.length}`, items)
        
        return {items: items, offset: offset, filter: filter}

    } catch (ex) {

        console.error("Error while fetching targets!", ex)
        yield put(actions.fetchItemsFail(ex))
    
    }
}


function* authSuccess() {
    
    const { targets } = yield select()
    const initialOffset = targets.offset
    const initialFilter = targets.filter
 
    yield putResolve(actions.setInProgress(true))

    const count = yield call(fetchCount, {filter: initialFilter})
    const { items, offset, filter } = yield call(fetchItems, {offset: initialOffset, filter: initialFilter})

    yield putResolve(actions.setCount(count))
    yield putResolve(actions.set(items, offset, filter))

    yield putResolve(actions.setInProgress(false))
}

function* next() {

    const { targets } = yield select()

    const currentOffset = targets.offset
    const currentFilter = targets.filter
    const currentItems = targets.items

    const newOffset = currentOffset + TARGETS_LIMIT

    const { items, offset, filter } = yield call(fetchItems, {offset: newOffset, filter: currentFilter})
    const newItems = [...currentItems, ...items]     
    yield putResolve(actions.set(newItems, offset, filter))
}

function* setInputFilter() {

    const { targets } = yield select()
    const { inputFilter } = targets

    const newOffset = 0

    const count = yield call(fetchCount, {filter: inputFilter})
    const { items, offset, filter } = yield call(fetchItems, {offset: newOffset, filter: inputFilter})
    
    yield putResolve(actions.setCount(count))
    yield putResolve(actions.set(items, offset, filter))

}

function* clearInputFilter() {
    yield putResolve(actions.setInputFilter(""))
}

function* watchSetInputFilter() {

    //We are useing a sliding buffer with capacity 1. This means, in case of new event the older is dropped and
    //will be replaced with the newer. This means we could call the server always by useing the curent filter
    const channel = yield actionChannel(actionTypes.TARGETS_SET_INPUT_FILTER, buffers.sliding(1))
    
    while (true) {
      yield take(channel)
      yield call(setInputFilter)
    }
}

function* autocompleteInProgressOn() {
    yield putResolve(actions.setAutocompleteInProgress(true)) 
}

function* autocompleteInProgressOff() {
    yield putResolve(actions.setAutocompleteInProgress(false)) 
}

export function* saga() {

    yield fork(watchSetInputFilter)

    yield takeEvery(actionTypes.AUTH_SUCCESS, authSuccess)
    yield takeEvery(actionTypes.TARGETS_CLEAR_INPUT_FILTER, clearInputFilter)
    yield takeEvery(actionTypes.TARGETS_NEXT, next)

    yield takeEvery(actionTypes.TARGETS_SET_INPUT_FILTER, autocompleteInProgressOn)
    yield takeEvery(actionTypes.TARGETS_SET, autocompleteInProgressOff)
}
