// React imports
import React from 'react'

// Redux imports
import { connect } from 'react-redux'
import * as routesAction from '../../../store/actions/routes'

// Material UI
import {
    makeStyles,
    Fab,
} from '@material-ui/core/'

import AspectRatioIcon from '@material-ui/icons/AspectRatio'

const useStyles = makeStyles((theme) => ({
    fab: {
      backgroundColor: "white",
    },
    fabIconWrapper: {
      display: "flex",
      alignContent: "ceter",
      justifyContent: "center",
    },
}));

const RoutesFitButton = (props) => {

    const classes = useStyles()
    
    const {
      state,
      fitToBoundsUserRequest,
    } = props
    
    const { routes } = state
    const bounds = routes.bounds

    if (!bounds) { return null }

    const handleFitToBoundsUserRequest = (event) => {
      event.stopPropagation()
      fitToBoundsUserRequest()
    }

    return (
        <Fab
            size="small"
            onClick={handleFitToBoundsUserRequest}
            className={classes.fab}>
            <div className={classes.fabIconWrapper}>
              <AspectRatioIcon />
            </div>
        </Fab>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    fitToBoundsUserRequest: () => dispatch(routesAction.fitToBoundsUserRequest()),
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesFitButton)