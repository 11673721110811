import * as actionTypes from './actionTypes'

//NOTE: !!! IMPORTANT !!!
//In the error saga, we have a watcher for ALL action contains an error property with a DisplayableError type
//due to this design, we have to avoid to use error as variable name to avoid endless loop in the saga$
export const setItems = (items) => {
    return {
        type: actionTypes.ERROR_SET_ITEMS,
        payload: items  
    }
}

export const setAcknowledged = (uuid) => {
    return {
        type: actionTypes.ERROR_SET_ACKNOWLEDGED,
        payload: uuid
    }
}