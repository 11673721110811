// Basic imports
import React, { useEffect } from 'react'
import * as trackingActions from '../../store/actions/tracking'
import * as navigationActions from '../../store/actions/navigation'

// Redux imports
import { connect } from 'react-redux'

import TargetsFilter from "../Filter/TargetsFilter"
import TrackingSelectedTargets from './TrackingSelectedTargets'
import MapView from '../Map/MapView'
import TrackingMarker from './TrackingMarker'
import TopDrawer from '../Drawer/TopDrawer'

const Tracking = (props) => {

    const { state } = props
    const { tracking, targets } = state
 
    //We want to run only once!
    //TODO: consider to move the whole logic into the SAGA layer.
    //We already use 'pageIsReady' in the saga layer, so defining somethink like 'pageDispose'
    //would be logical.
    useEffect(() => {
      //Start the poll
      props.pollStart();
      //event
      props.pageIsReady();
      return () => {
        //Stop the poll
        props.pollStop()
      }
    // eslint-disable-next-line
    },[])

    const handleTargetClick = (item) => {
      console.debug(`Target selected: ${item}`)
      props.addTargetsUserRequest([item])
    }

    const handleAllTargetClick = (items) => {
      console.debug("Targets secelted: ", items)
      props.replaceAllTargetsUserRequest(items)
    }
    
    const handleClearSelectedClick = () => {
      props.replaceAllTargetsUserRequest([])
    }
    
    const handleClickDelete = (item) => {
      props.removeTargetByIdUserRequest(item.id)
    }

    const markers = tracking.targets.map((item, index) => <TrackingMarker key={index} item={item} />)
    
    //We have to create a common target format
    //TODO: TS datamodel?
    const selectedTargets = tracking.targets.map( e => {
      return {
        id: e.target.id,
        ...e
      }
    })

    console.debug("selectedTargets: ", selectedTargets)

    return(
        <>
          
          <TopDrawer inProgress={tracking.inProgress || targets.inProgress} >
            <TargetsFilter
              selectedTargets={selectedTargets}
              onAddAllClick={handleAllTargetClick}
              onTargetClick={handleTargetClick}
              onClearSelectedClick={handleClearSelectedClick}>
              <TrackingSelectedTargets items={selectedTargets} onDelete={handleClickDelete} />
            </TargetsFilter>
          </TopDrawer>

          <MapView>
              {markers}
          </MapView>
      </>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    
    //polling
    pollStart: () => dispatch(trackingActions.pollStart()),
    pollStop: () => dispatch(trackingActions.pollStop()),
    
    //targets add & remove
    addTargetsUserRequest: (newItems) => dispatch(trackingActions.addTargetsUserRequest(newItems)),
    removeTargetByIdUserRequest: (id) => dispatch(trackingActions.removeTargetByIdUserRequest(id)),
    replaceAllTargetsUserRequest: (newItems) => dispatch(trackingActions.replaceAllTargetsUserRequest(newItems)), 
    
    // lifecycle
    pageIsReady: () => dispatch(navigationActions.trackingPageIsReady()),
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tracking)
