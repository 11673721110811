import { eventChannel } from 'redux-saga'
import { takeEvery, race, take, fork, put} from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import * as keyboardActions from '../actions/keyboard'

const EVENT_KEY_DOWN = "keydown"

function* eventHandler(channel) {
  
    while (true) {

        const raceResult = yield race({
            keyboardEvent: take(channel),
            
            //Isn't implemented, not necessary at the moment
            //cancelEvent: take(actionTypes.KEYBOARD_EVENTS_STOP_CHANNEL),
        })

        const { keyboardEvent } = raceResult //{ keyboardEvent, cancelEvent }

        if (keyboardEvent) {
            yield put(keyboardActions.keyDown(keyboardEvent))
        }

        //Isn't implemented, not necessary at the moment
        /*if (cancelEvent) {
            channel.close()
            console.debug("Keyboard event channel has been closed.")
            return
        }*/
    }
}


function* startKeyboardEventChannel() {

    console.debug("Keyboard channel has been started.")

    const keyboardEventChannel = eventChannel(emitter => {

        const handler = (event) => emitter(event)
        document.addEventListener(EVENT_KEY_DOWN, handler, false);

        return () =>  document.removeEventListener(EVENT_KEY_DOWN, handler)
    })

    yield fork(eventHandler, keyboardEventChannel)
}

export function* saga() {
    //We start at the very beginning.
    yield takeEvery(actionTypes.STORE_CREATED, startKeyboardEventChannel)
}