// React & redux imports
import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as shareTargetActions from '../../store/actions/shareTarget'
import * as COM from '../../utilities/common'

// i18n
import { useTranslation } from 'react-i18next'

// Material-UI
import {
  makeStyles,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'

import {
  DatePicker,
} from '@material-ui/pickers'

// Own components
import SubContent from "./SubContent"

const useStyles = makeStyles((theme) => ({
  infoBox: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  resultEmbedded: {
    width: '100%',
    height: '4rem',
    resize: 'none'
  },
  resultLink: {
    width: '100%',
    height: '4rem',
    resize: 'none'
  },
  button: {
    margin: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: ".75%",
  },
  red: {
    bakckgroudColor: 'red',
  }
}));

const ShareTargetDialog = (props) => {

  const dateFormat = process.env.REACT_APP_DATE_FORMAT
  
  const REPLACE_NAME = "{token}"

  const { t } = useTranslation()
  const classes = useStyles()

  const [isEmbedded, setEmbedded] = useState(false)
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())

  // Sharing links
  // Base sharing link: https://m.itrack.hu/#/login/{token}
  // normal suffix: /tracking/hu
  // embedded suffix: /iframe/hu
  // <iframe src="https://m.itrack.hu/#/login/{token}/iframe/hu" width="800" height="600"></iframe>

  const pe = process.env
  const normalLinkSuffix = pe.REACT_APP_SHARE_TARGET_NORMAL_LINK_SUFFIX
  const iframeLinkSuffix = pe.REACT_APP_SHARE_TARGET_IFRAME_LINK_SUFFIX
  const iframeWidth = pe.REACT_APP_SHARE_TARGET_IFRAME_WIDTH || 800
  const iframeHeight = pe.REACT_APP_SHARE_TARGET_IFRAME_HEIGHT || 600
  const baseLinkWithToken =  pe.REACT_APP_SHARE_TARGET_BASE_LINK.replace(REPLACE_NAME, props.shareTargetState.loginToken)
  const link = isEmbedded 
  ? `<iframe src="${baseLinkWithToken}${iframeLinkSuffix}" width="${iframeWidth} || 800" height="${iframeHeight}"></iframe>`
  : `${baseLinkWithToken}${normalLinkSuffix}`

  console.debug("ShareTargetDialog props: ", props)
  
  console.debug(
    "ShareTargetDialog values: isEmbedded: ", isEmbedded,
    ", fromDate: ", fromDate,
    ", toDate: ", toDate,
    ", normalLinkSuffux: ", normalLinkSuffix,
    ", iframeSuffix: ", iframeLinkSuffix,
    ", iframeWidth: ", iframeWidth,
    ", iframeHeight: ", iframeHeight,
    ", baseLinkWithToken: ", baseLinkWithToken,
    ", link: ", link)
  
  console.debug("Sharing link is: ", link)

  const handleEmbeddedChange = (event) => {
    setEmbedded(event.target.checked)
  }

  const handleGenerateClick = (event) => {
    props.generateStart(props.item.target.id, fromDate.getTime(), toDate.getTime(), "", "")
  }

  const handleFromDateChange = (date) => {
      setFromDate(date)
  }

  const handleToDateChange = (date) => {
      setToDate(date)
  }

  const shouldDisableFromDate = (date) => {
    return COM.isBefore(toDate, date)
  }

  const shouldDisableToDate = (date) => {

      //The 'toDate' cannot be before the fromDate or before today
      //We have to 'roll' the time of the dates to the begining of the day and to the end of the day
      const today = COM.createNewDateWithTimeSetToStartOfDay(new Date())
      const endOfTheDayDate = COM.createNewDateWithTimeSetToEndOfDay(date)

      return COM.isBefore(endOfTheDayDate, today) || COM.isBefore(endOfTheDayDate, fromDate)
  }

  const content = props.shareTargetState.loginToken
  ?
  <Grid item xs={12}>
      <textarea readOnly className={isEmbedded ? classes.resultEmbedded : classes.resultLink} value={link} />
  </Grid>
  :
  null

  //TODO: change these text i18n keys (?)
  const embeddedText = "share-embedded-text"
  const normalText = "share-normal-text"
  const title = t(isEmbedded ? "Create embeddable HTML snippet" : "Create sharable link")
  const infoText = t( isEmbedded ? embeddedText : normalText )


  return (
    <Dialog onClose={props.onClose} aria-labelledby="share-dialog-title" open={props.open}>
      <DialogTitle id="share-dialog-title">{title}</DialogTitle>
      <DialogContent>
       
        <Grid container>

          <Grid item xs={6} className={classes.red}>
            <DatePicker
              disableToolbar
              autoOk
              showTodayButton={COM.isBeforeOrSameDay(new Date(), toDate)}
              format={dateFormat}
              margin="normal"
              id="date-range-start-date-picker"
              label={t("Start date")}
              todayLabel={t("Today")}
              cancelLabel={t("Cancel")}
              okLabel={t("Ok")}
              value={fromDate}
              onChange={handleFromDateChange}
              shouldDisableDate={shouldDisableFromDate} />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              disableToolbar
              autoOk={true}
              showTodayButton={COM.isAfterOrSameDay(new Date(), fromDate)}
              format={dateFormat}
              margin="normal"
              id="date-range-to-date-picker"
              label={t("End date")}
              todayLabel={t("Today")}
              cancelLabel={t("Cancel")}
              okLabel={t("Ok")}
              value={toDate}
              onChange={handleToDateChange}
              shouldDisableDate={shouldDisableToDate} />
          </Grid>

          <Grid item xs={12} className={classes.infoBox}>
            {infoText}
          </Grid>

          {content}
          <SubContent
            link={link}
            show={props.shareTargetState.loginToken}
            isEmbedded={isEmbedded}
            switchChanged={handleEmbeddedChange} />

        </Grid>

      </DialogContent>

      <DialogActions>
          <Button onClick={props.onClose}>{t("Close")}</Button>
          { props.shareTargetState.inProgress ? <CircularProgress /> : <Button onClick={handleGenerateClick} color="primary">{t("Generate")}</Button>}
        </DialogActions>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    shareTargetState: state.shareTarget,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    generateStart: (targetId, fromDate, toDate, email, type) => dispatch(shareTargetActions.generateStart(targetId, fromDate, toDate, email, type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareTargetDialog)