import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

/**
 * Get the targets by using the filter
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 * @param {object} params.credentials - The username and password
 * @param {number} params.offset - The offset to start from
 * @param {number} params.limit - The limit of the results
 * @param {string} params.filter - The filter to apply
 */
 export const getTargets = (params) => {
    return axios.get(
        `${BASE_URL}/targets`, {
            headers: {
                'Accept-Language': params.acceptLanguage
            },
            params: {
                offset: params.offset,
                limit: params.limit,
                filter: params.filter
            },
            auth: params.credentials,
        }
    )
}

/**
 * Get the targets count by using the filter
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 * @param {object} params.credentials - The username and password
 * @param {string} params.filter - The filter to apply
 */
export const getTargetCount = (params) => {
    return axios.get(
        `${BASE_URL}/targets/count`, {
            headers: {
                'Accept-Language': params.acceptLanguage
            },
            params: {
                filter: params.filter
            },
            auth: params.credentials,
        }
    )
}