// React imports
import React from 'react'

// Redux imports
import { connect } from 'react-redux'
import * as mapActions from '../../../store/actions/map'

// Material UI
import {
    makeStyles,
} from '@material-ui/core/'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'


const useStyles = makeStyles((theme) => ({
    zoomControl: {
      width: "1.8rem",
      height: "1.8rem",

      borderRadius: "0.9rem",
      border: "solid 1px gray",

      fontWeight: "bold",

      backgroundColor: "white",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
}));

const ZoomControlButtons = (props) => {

    const classes = useStyles()
    
    const {
      zoomUserRequest
    } = props
    
    const handleZoomIn = (event) => {
      event.stopPropagation()
      zoomUserRequest(1)
    }

    const handleZoomOut = (event) => {
      event.stopPropagation()
      zoomUserRequest(-1)
    }

    return (
        <>
            <div className={classes.zoomControl}>
              <AddIcon onClick={handleZoomIn} />
            </div>

            <div className={classes.zoomControl}>
              <RemoveIcon onClick={handleZoomOut} />
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    zoomUserRequest: (step) => dispatch(mapActions.zoomUserRequest(step))
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoomControlButtons)