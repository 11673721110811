// Action Types import
import * as actionTypes from '../actions/actionTypes'

import { update } from '../../utilities/common.js'

// Initial state
const initialState = {
    items: []
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {
        
        case actionTypes.ERROR_SET_ITEMS: return update(state, {items: action.payload})

        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer