// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { update } from '../../utilities/common.js'

// Initial state
const initialState = {   
    events: [],
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {
        case actionTypes.DEBUG_SET_EVENTS: return update(state, {events: action.payload})
        default: return state
    }
}

export default reducer