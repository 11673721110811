import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

/**
 * Authenticate the user
 * @param {object} credentials - The username and password
 * @param {string} acceptLanguage  - Mandatory parameter for the API, use the current language
 * 
 * Read the above link to understand why we are using the below syntax
 * https://github.com/axios/axios/issues/891
 */
export const getCurrentUser = (credentials, acceptLanguage) => {

    return axios.get(
        `${BASE_URL}/users/current`,
        {
            headers: {
                'Accept-Language': acceptLanguage,
            },
            auth: credentials
        }
    )
}