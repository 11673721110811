// Basic imports
import React from 'react'

// Material UI
import { 
  makeStyles,
  Grid,
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'

// Own components
import NoResult from '../Filter/NoResult'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}))

const RefuelsNoResultHeader = (props) => {

    const classes = useStyles()

    return (
        <>
            <Grid item xs={12} container alignItems="center">
                <Alert severity="warning" className={classes.root}>
                    <NoResult />
                </Alert>
            </Grid>
        </>
    )
}

export default RefuelsNoResultHeader