// Redux imports
import { createStore, applyMiddleware } from 'redux'

import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'

// Root reducer import
import rootReducer from './reducers/root'

// Persist configuration
import persistConfig from './persistConfig'

const initStoreAndSaga = () => {
    
    // Create saga and middleware
    const saga = createSagaMiddleware()
    const middlewares = [saga]

    // Add middlewares
    process.env.REACT_APP_USE_SAGA_LOGGER && middlewares.push(logger)
    const persistedReducer = persistReducer(persistConfig, rootReducer)

    // Create the store, add reducers and middlewares
    const store = createStore(persistedReducer, applyMiddleware(...middlewares))   
    const persistor = persistStore(store)

    return { store, saga, persistor }
}

export default initStoreAndSaga