import React, { useState } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { makeStyles } from '@material-ui/core'

import * as errorActions from '../../store/actions/error'

import * as COM from '../../utilities/common'

const useStyles = makeStyles( (theme) => ({
    tableContainer: {
            maxHeight: "30vh"
        }
    }
))
  
const ErrorDialog = (props) => {

    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [showAllErrors, setShowAllErrors] = useState(false)
    const notAcknowledgeds = props.errorState.items.filter( i => !i.acknowledged)
    const currentItem = notAcknowledgeds.length > 0 ? notAcknowledgeds[0] : null

    const toggleShowAllErrors = () => {
        setShowAllErrors(!showAllErrors)
    }

    const handleOk = (uuid) => {
        props.setAcknowledged(uuid)
        setOpen(false)
    };

    // We are going to open if we have errors
    if (!open && currentItem) { setOpen(true) }

    const showOrHideAllErrors = showAllErrors ? "Hide all errors" : "Show all errors"

    const allErrors = showAllErrors
    ?
    <TableContainer className={classes.tableContainer}>
        <Table size="small" aria-label="all error">

            <TableHead>
                <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Ack.</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {props.errorState.items.map((e, i) => (
                    <TableRow key={`${i}-trc`}>
                        <TableCell>{COM.formattedDateTime(e.date)}</TableCell>
                        <TableCell>{e.exception.message}</TableCell>
                        <TableCell>{e.acknowledged ? "✓" : null}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    :
    null

    const ret = currentItem
    ? 
    <Dialog open={open} >
        
        <DialogTitle id="alert-dialog-title">{currentItem.title}</DialogTitle>
        
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {currentItem.message}
            </DialogContentText>
            {allErrors}
        </DialogContent>

        <DialogActions>
            <Button onClick={toggleShowAllErrors}>{showOrHideAllErrors}</Button>
            <Button onClick={() => {handleOk(currentItem.uuid)}} color="primary">Ok</Button>
        </DialogActions>

    </Dialog>
    :
    null

    return ret
}

function mapStateToProps(state) {
    return {
      errorState: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAcknowledged: (uuid) => dispatch(errorActions.setAcknowledged(uuid)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)