// React imports
import React, { useRef } from 'react'

// Other
import InfiniteScroll from 'react-infinite-scroller'

// I18n
import { useTranslation } from 'react-i18next'

// Material-UI
import {
    CircularProgress,
  makeStyles,
} from '@material-ui/core'

//helpers
import * as COM from '../../utilities/common.js'

import InfiniteScrollMessage from '../InfiniteScroll/InfiniteScrollMessage'
import Targets from './Targets'
import NoResult from './NoResult'

const useStyles = makeStyles((theme) => ({
  
   
    /* total height is 4.5rem */
    selectedTargetsContainer: {
      boxSizing: "border-box",
      marginTop: ".5rem",
      marginBottom: ".5rem",
  
      overflowX: "scroll", 
      overflowY: "hidden", 
  
      display: "flex",
      alignItems: "center",
      justifyContent: 'flex-start',
      gap: "1rem",
  
      //We have to use much larger height here to make place for the scrollbar in Firefox and Chromium on Linux
      height: "3.5rem", //Use fixed height to avoid 'jumping' of the target list when the first element being selected.
    
      //for debug:
      //backgroundColor: "rgba(11,23,233,.5)"
    },
  
    autocompleteProgressContainer: {
        visibility: "hidden",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "rgba(127, 63, 7, .3)" // Just for debug
    },

    visible: {
        visibility: "visible",
    },

    wrapper: {
      flex: 1,
      alignItems: "stretch",
      width: `100%`,

      //just for debugging
      //backgroundColor: "green",
    },

    /* This wrapper is necessary to fit & scroll perfectly */
    scrollerWrapper: {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  
    scroller: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "scroll",

      //for debug:
      //backgroundColor: "yellow",
    },
  
  }));

const TargetList = (props) => {
  
    const { items, selectedItems, next, hasMore, onTargetClick, autocompleteInProgress } = props
    const classes = useStyles()
    const scrollParentRef = useRef(null)
    const { t } = useTranslation()
    
    if (items.length === 0) return <NoResult />
    
    const acpcClasses = COM.clsx({
        [classes.autocompleteProgressContainer]: true,
        [classes.visible]: autocompleteInProgress,
    })
  
    return (
      <div className={classes.wrapper}>

        <div className={acpcClasses}>
            <CircularProgress />
        </div>
       
       {/*
          Maybe it seems overkiller but it's not!
          To scroll and fit preciselly this dual div solution looks the easiest way!  
        */}
        <div className={classes.scrollerWrapper}>
          <div className={classes.scroller} ref={scrollParentRef} >
          <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={next}
              hasMore={hasMore}
              useWindow={false}
              getScrollParent={() => scrollParentRef.current}
              
              //XXX: We use a random number for key to avoid a strange 'unique key missing' error
              //TODO: investigate futher this problem!
              loader={<InfiniteScrollMessage message={t("Loading targets...")} key={`${Math.random()}`} />}>
                
              <Targets items={items} onClick={onTargetClick} selectedItems={selectedItems} />

            </InfiniteScroll>
          </div>
        </div>

      </div>
    )
  }

  export default TargetList