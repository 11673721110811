import { spawn } from 'redux-saga/effects'

import { saga as auth } from './auth'
import { saga as refuels } from './refuels'
import { saga as map } from './map/map'
import { saga as mapEvents } from './map/events'
import { saga as route } from './routes'
import { saga as settings } from './settings'
import { saga as device } from './device'
import { saga as error } from './error'
import { saga as chat } from './chat'
import { saga as shareTarget } from './shareTarget'
import { saga as targets } from './targets'
import { saga as tracking } from './tracking/tracking'
import { saga as trackingAutofit } from './tracking/autofit'
import { saga as keyboard } from './keyboard'
import { saga as navigation } from './navigation'

const modules = [
    auth,
    refuels,
    map,
    mapEvents,
    route,
    settings,
    device,
    error,
    chat,
    shareTarget,
    targets,
    tracking,
    trackingAutofit,
    keyboard,
    navigation,
]

function* rootSaga() {

    // spawn all modules
    for(let i = 0; i < modules.length; i++) {
        yield spawn(modules[i])
    }

}

export default rootSaga