import * as COM from '../../../utilities/common'

import { take, takeEvery, put, putResolve, call, select, race, delay } from 'redux-saga/effects'
import * as trackingRepo from '../../repository/tracking'
import * as actionTypes from '../../actions/actionTypes'

import * as mapActions from '../../actions/map'
import * as mapEventsActions from '../../actions/mapEvents'
import * as trackingActions from '../../actions/tracking'

import { LatLng } from 'leaflet'
//import * as settingsActions from './../actions/settings'

function* updateTargets() {

    try {
        
        const state = yield select();
        const { tracking, settings } = state

        // filtering by status
        const filteredByStatus = []
        filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`] = []
        filteredByStatus[`${COM.TRACKING_STATUS_NO_DATA}`] = []
        filteredByStatus[`${COM.TRACKING_STATUS_LIVE}`] = []

        state.tracking.targets.forEach((i) => filteredByStatus[`${i.status}`].push(i))

        //we have to ignore items with NO_DATA status if we have any items with PENDING status
        const merged = []
        merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`])
        merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_LIVE}`])
        if (filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`].length === 0) merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_NO_DATA}`])

        // create datastructure for the API call
        const targetsArray = merged.map((t) => {             

            const ret = {
                targetId: t.target.id,
                
                fromTimeMicros: t.status === COM.TRACKING_STATUS_PENDING 
                ? COM.DEFAULT_FROM_TIME_MILIS : t.currentPosition
                ? t.currentPosition.timeMicros : COM.DEFAULT_FROM_TIME_MILIS,
                
                asynchronous: t.status !== COM.TRACKING_STATUS_PENDING
            }

            return ret
        })

        //We have to pass the popupTargetId as a query param to the API call
        const { popupTargetId } = tracking
        const languageCode = settings.languageCode
        const { data } = yield call(trackingRepo.getTargetsPosition, state.auth.credentials, JSON.stringify(targetsArray), popupTargetId, languageCode)
        
        yield putResolve(trackingActions.updateTargetsSuccess(data))  

    } catch (exp) {

        console.error("Error during updating targets!", exp)

        const delayTime = parseInt(process.env.REACT_APP_TARGETS_POLLING_DELAY_TIME_ON_ERROR_IN_MILLISECONDS) || 5000
        yield delay(delayTime)

        yield put(trackingActions.updateTargetsFail(exp))
        yield put(trackingActions.pollStop())
    }
}

function* updateTarget(action) {

    const { tracking } = yield select()
    const targetId = action.payload

    try {

        // We have to set the status to pending to show a progess indicator
        yield putResolve(trackingActions.setTargetStatus(targetId, COM.TRACKING_STATUS_PENDING))

        const { auth, settings } = yield select()

        const targetsArray = [
            {
                targetId: targetId,
                fromTimeMicros: + new Date(),
                asynchronous: false,
            },    
        ]
        
        //We have to pass the popupTargetId as a query param to the API call if the popup is open
        const { popupTargetId } = tracking
        const languageCode = settings.languageCode
        const { data } = yield call(trackingRepo.getTargetsPosition, auth.credentials, JSON.stringify(targetsArray), popupTargetId, languageCode)

        yield putResolve(trackingActions.updateTargetsSuccess(data))

    } catch (exp) {

        console.debug(`Error during update target ${targetId}`, exp)
        
        yield putResolve(trackingActions.pollStop())
        yield putResolve(trackingActions.updateTargetsFail(exp))

    }
}

function* pollWorker(action) {

    console.debug("Poll did START.")

    while (true) {
        try {
            
            yield call(updateTargets)

        } catch (err) {
            
            console.error(err)

            //On case of error, we have to wait for a while to avoid endless queries to the server.
            //TODO: better logic, incremented delays and stop after Nth failes or something similar
            const delayTime = parseInt(process.env.REACT_APP_TARGETS_POLLING_DELAY_TIME_ON_ERROR_IN_MILLISECONDS) || 5000
            yield delay(delayTime)
        }
    }
}

function* pollManager() {

        const { stop, restart } = yield race({
            poll: pollWorker(null),
            //We have to interrupt the current long polling at the very moment when any of the following cases happened.
            restart: take(actionTypes.TRACKING_POLL_RESTART),
            stop: take(actionTypes.TRACKING_POLL_STOP)
        })

        // handling restart
        if (restart) {
            console.debug("Poll did RESTART.")
            yield call(pollManager) //XXX: CHECK: is it ok to call itself from here?
        }

        // handling stop
        if (stop) {
            console.debug("Poll did STOP.")
            // do nothing
        }
}

function* handleUpdateTargetsSuccess(action) {

    const newTargets = []                                   // New empty array
    const receivedTargets = action.payload                  // These are the received items from the server
    const currentState = yield select()                     // The current state contains the actual array of targets
    const currentItems = currentState.tracking.targets      // Just a shorthand for targets

    const getNewTargetById = (id) => {
        let f = receivedTargets.filter((i) => i.target.id === id)
        return f && f.length > 0 ? f[0] : null
    }

    // Merge newly received data with the currentItems array
    currentItems.forEach((i) => {
        const item = getNewTargetById(i.target.id)
        let newItem = item ? {
            ...item,
            frontendTimeMicros: i.frontendTimeMicros
        } : {...i}
        newItem.status = newItem.currentPosition ? COM.TRACKING_STATUS_LIVE : COM.TRACKING_STATUS_NO_DATA
        newTargets.push(newItem)
    })

    //1. Push an action and set the new targets
    yield putResolve(trackingActions.setTargets(newTargets))  
}

//XXX: this function is little bit messy. Rethinking & redesigning would be great!
const enhanceItemsIfNecessary = (items) => {
    const enhancedItems = []
    const currentTime = + new Date() //shorthand for get time
    for (var ti of items) {
        let ei = ti;

        //Note: the rehydration process also uses this function, so the incoming structure could be enhanced already!
        //We have to check the object here, because we do not want to cascade the addition of the target object!
        if (!ti.target) {
            ei = {
                target: {...ti},
                frontendTimeMicros: currentTime,
                status: COM.TRACKING_STATUS_PENDING
            }
        }
        enhancedItems.push(ei)
    }

    return enhancedItems;
}

function* addTargets(action) {
    
    const newItems = action.payload

    const currentState = yield select()
    const currentItems = currentState.tracking.targets
   
    const newEnhancedItems = enhanceItemsIfNecessary(newItems)

    // The current items array is empty, we have to create the default
    if (currentItems.length === 0) {
        yield putResolve(trackingActions.setTargets(newEnhancedItems))
        yield putResolve(trackingActions.pollRestart())
    } else {

        /*
        
        SYMMETRIC DIFFERENCE OF THE ARRAYS

        We are going to create the symmetric difference of the current items and the new items.
        It means all of items which are represented in both the 'current items' and the 'new enhanced items'
        arrays will be removed. If a target is in the list and the user clicks on it will be removed and all
        of the items which aren't represented in the current list will be added to. This behaviour allows the
        user to use only the targets list to 'toggle select' items.
        
        */
        
        //TODO: move to COM
        const arraySubstract = (a, b) => {
            const res = []
            for (let x of a) {
                let contains = false;
                for (let y of b) {
                    if (x.target.id === y.target.id) {
                        contains = true;
                        continue;
                    }
                }
                if (!contains) res.push(x)
            }
            return res;
        }

        //Combining the two arrays
        const filtered = [
            ...arraySubstract(currentItems, newEnhancedItems),
            ...arraySubstract(newEnhancedItems, currentItems),
        ]

        yield putResolve(trackingActions.setTargets(filtered))
        yield putResolve(trackingActions.pollRestart())
    }
}

function* replaceAllTargets(action) {
    const newItems = action.payload

    const newEnhancedItems = enhanceItemsIfNecessary(newItems)

    yield putResolve(trackingActions.setTargets(newEnhancedItems))
    yield putResolve(trackingActions.pollRestart())
}

function* removeTargetById(action) {
    
    const tid = action.payload
    const currentState = yield select()
    const currentTargets = currentState.tracking.targets
    const newTargets = currentTargets.filter(e => e.target.id !== tid)

    yield putResolve(trackingActions.setTargets(newTargets))
    yield putResolve(trackingActions.pollRestart())
}

function* setTargetStatusById(action) {

    const tid = action.targetId
    const status = action.status
    const state = yield select()
    const targets = state.tracking.targets

    // Get by ID and set the status
    const filtered = targets.filter(e => e.target.id === tid)
    if (filtered[0]) filtered[0].status = status

    yield putResolve(trackingActions.setTargets(targets))
}

function* inProgressOn() {
    yield putResolve(trackingActions.setInProgress(true))
}

function* inProgressOff() {
    yield putResolve(trackingActions.setInProgress(false))
}

function* reloadStoredTargets(action) {

    const { settings, auth } = yield select()
    const { trackingTargets } = settings
    const storedUserId = settings.userId
    const currentUserId = auth.credentials.id

    if (storedUserId &&
        storedUserId === currentUserId &&
        trackingTargets &&
        trackingTargets.length > 0) {

        console.debug(`Reload last tracked targets. We have ${trackingTargets.length} targets stored.`, settings)
        yield putResolve(trackingActions.replaceAllTargets(trackingTargets))
    }
}

function* createBounds(action) {

    try {

        const { tracking } = yield select()
        const targets = tracking.targets

        const targetsWithPosition = targets.filter( t => t.currentPosition )
        const coordinates = targetsWithPosition.map( t => {
            return {
                lat: t.currentPosition.latitude,
                lng: t.currentPosition.longitude,
            }
        })
        
        const bounds = COM.createBounds(coordinates)
        if (bounds) yield putResolve(trackingActions.setBounds(bounds))

    } catch (error) {
        console.error(`Oops! Something went wrong during creating bounds for tracking.`, error)
    }
}

function* handleSetBounds(action) {
    const bounds = action.payload
    if (bounds && bounds.isValid() ) {
        console.debug("Tracking bounds have been set and they are valid.", bounds)
        yield put(trackingActions.boundsAreValid())
    }
}

function* handleMapMovedByUser() {
    const { settings } = yield select()
    
    if (settings.selectedBottomMenuItem !== 1) {
        console.debug("YYY Tracking page isn't active. Skipping handleFiToBoundsUserRequest.")
        return
    }

    yield putResolve(trackingActions.setAutoFitToBounds(false))
}

function* handleFiToBoundsUserRequest() {
    const { tracking, settings } = yield select()
    
    if (settings.selectedBottomMenuItem !== 1) {
        console.debug("YYY Tracking page isn't active. Skipping handleFiToBoundsUserRequest.")
        return
    }

    const { autoFitToBounds } = tracking
    yield putResolve(trackingActions.setAutoFitToBounds(!autoFitToBounds))
    yield putResolve(trackingActions.fitToBounds())
}

function* handleAutoFitToBoundsWhenZoom(action) {
    
    const { tracking, map, settings } = yield select()

    if (settings.selectedBottomMenuItem !== 1) {
        console.debug("YYY Tracking page isn't active. Skipping handleAutoFitToBoundsWhenZoom.")
        return
    }

    const { ref } = map
    const items =  tracking.targets

    const isPositionOutsideOfBounds = (bounds, positions) => {
        for (let i = 0; i < positions.length; i++) {
            if (!bounds.contains(positions[i])) return true 
        }
        return false
    }
   
    if (items && items.length > 1 && ref) {

        console.debug(`Handling tracking zoom event. We have ${items.length} items.`)

        const arr = items.filter(t => t.currentPosition != null )
        const positions = arr.map( t => new LatLng(t.currentPosition.latitude, t.currentPosition.longitude) )
        
        //XXX: This condition is just a quick and dirty hack to avoid read-on-null error
        //Investigate and understand the whole problem and review this condition!
        if (positions && positions.length > 0) {
            console.debug("Items' positions: ", positions)

            const mapViewBounds = ref.getBounds()
            console.debug(`Map view bounds:`,mapViewBounds)

            const hasItemOutsideOfViewBounds = isPositionOutsideOfBounds(mapViewBounds, positions)
            console.debug(`Has item outside of the visible map: `, hasItemOutsideOfViewBounds)

            //XXX: Is this condition necessary?
            if (hasItemOutsideOfViewBounds) yield putResolve(trackingActions.setAutoFitToBounds(false))
        } else {
            console.debug("There are no positions for tracking 'autoFitToBound'.")
        }
    }
}

function* fitToBounds(action) {
    const { tracking } = yield select()
    const bounds = tracking.bounds
    yield putResolve(mapActions.fitToBounds(bounds))
}

function* fitToBoundsIfAutoFitActive(action) {
    const { tracking, settings } = yield select()
    const { autoFitToBounds } = tracking

    console.debug(`YYY Tracking 'autoFitToBounds' is ${autoFitToBounds}`)

    if (settings.selectedBottomMenuItem !== 1) {
        console.debug("YYY We are not on tracking page. Interrupting.")
        return
    }

    if (!tracking.autoFitToBounds) {
        console.debug("YYY tacking autofittobopunds is FALSE. Interrupting.")
        return
    }

    if (!tracking.bounds.isValid) {
        console.debug("YYY tacking bounds aren't valid! Interrupting.")
        return
    }

    yield putResolve(mapEventsActions.stopMapEventsChannel())
    yield putResolve(trackingActions.fitToBounds())
    yield putResolve(mapEventsActions.startMapEventsChannel())
}

function* checkPendingTargetsCount(action) {
    const { tracking } = yield select()
    const { targets } = tracking
    if ( targets.filter(t => t.status === COM.TRACKING_STATUS_PENDING).length === 0 ) {
        //There is no pending target
        yield putResolve(trackingActions.noPendingTarget())
    }
}

function* setPopupTargetId(action) {
    const popupTargetId = action.payload
    
    if (popupTargetId == null) return

    console.debug(`BBB Popup target is has been changed to: ${popupTargetId}. Updating the target.`)
    yield putResolve(trackingActions.updateTarget(popupTargetId))
}

export function* saga() {

    yield takeEvery(actionTypes.SETTINGS_USER_ID_STORED, reloadStoredTargets)

    yield takeEvery([
        actionTypes.TRACKING_SET_TARGETS,
    ], checkPendingTargetsCount)

    //events, when we have to create the bounds
    yield takeEvery([
        actionTypes.TRACKING_SET_TARGETS,
        actionTypes.TRACKING_CREATE_BOUNDS,
    ], createBounds)

    //events, when we have unconditionally to fit the map to the bounds
    yield takeEvery([
        actionTypes.TRACKING_FIT_TO_BOUNDS,
    ], fitToBounds)
   
    //
    yield takeEvery([
        actionTypes.TRACKING_SET_BOUNDS,
    ], fitToBoundsIfAutoFitActive)
    

    yield takeEvery(actionTypes.TRACKING_FIT_TO_BOUNDS_USER_REQUEST, handleFiToBoundsUserRequest)

    //zoom and auto zoom handling
    yield takeEvery(actionTypes.MAP_EVENTS_MAP_DID_ZOOM, handleAutoFitToBoundsWhenZoom)
    yield takeEvery(actionTypes.MAP_FIT_TO_BOUNDS_USER_REQUEST, handleFiToBoundsUserRequest)    
    yield takeEvery(actionTypes.MAP_EVENTS_MAP_MOVED_BY_USER, handleMapMovedByUser)

    yield takeEvery(actionTypes.TRACKING_SET_BOUNDS, handleSetBounds)

    yield takeEvery(actionTypes.TRACKING_POLL_START, pollManager)
    yield takeEvery(actionTypes.TRACKING_UPDATE_TARGETS_START, updateTargets)
    yield takeEvery(actionTypes.TRACKING_UPDATE_TARGETS_SUCCESS, handleUpdateTargetsSuccess)

    yield takeEvery(actionTypes.TRACKING_ADD_TARGETS, addTargets)

    yield takeEvery(actionTypes.TRACKING_REPLACE_ALL_TARGETS, replaceAllTargets)
    yield takeEvery(actionTypes.TRACKING_REMOVE_TARGET_BY_ID, removeTargetById)

    yield takeEvery(actionTypes.TRACKING_UPDATE_TARGET, updateTarget)
    yield takeEvery(actionTypes.TRACKING_SET_TARGET_STATUS, setTargetStatusById)

    yield takeEvery([
        actionTypes.TRACKING_UPDATE_TARGETS_START,
    ], inProgressOn )

    yield takeEvery([
        actionTypes.TRACKING_UPDATE_TARGETS_SUCCESS,
        actionTypes.TRACKING_UPDATE_TARGETS_FAIL,
        actionTypes.TRACKING_SET_TARGETS,
    ], inProgressOff )

    yield takeEvery(actionTypes.TRACKING_SET_POPUP_TARGET_ID, setPopupTargetId)
}
