import React from 'react'
import FlagMarker from './IconsAndMarkers/FlagMarker'
import PolylineWithArrows from './PolylineWithArrows'

const Route = (props) => {

    const { startPosition, stopPosition, sections, positions, latLngPositions } = props     

    if (!positions || !sections || !latLngPositions) return null

    return ( 
        <>
            <FlagMarker type="start" position={[startPosition.latitude, startPosition.longitude]} />
            <PolylineWithArrows sections={sections} positions={latLngPositions} />
            <FlagMarker type="finish" position={[stopPosition.latitude, stopPosition.longitude]} />
        </>
    )
}

export default Route
