// Style
import './index.css'

// Store
import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { storeCreated } from './store/actions/root'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import rootSaga from './store/sagas/root'
import initStoreAndSaga from './store/init'

// Service worker
import * as serviceWorker from './serviceWorker'

// Components
import App from './components/App/App'

// This is just for debugging purposes
// to delay the start of the app
// normally this does not have any effect (the value is 0)
const delayBeforeStart = process.env.REACT_APP_DELAY_IN_MILIS_BEFORE_START_APP || 0

// Sentry
// Remote error logging
const serenityInit = () => {
    Sentry.init({
        dsn: "https://543ac7259c5a452dbc3893abf1f9ba90@o4504259711991808.ingest.sentry.io/4504265284255744",
        integrations: [new BrowserTracing()],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const wrapper = () => {

    serenityInit();
   
    const { store, saga, persistor } = initStoreAndSaga()
    saga.run(rootSaga)
    store.dispatch(storeCreated())
    
    const app = (

        <Provider store={store} >
            <PersistGate loading={"Loading..."} persistor={persistor}>
                <App />
            </PersistGate>        
        </Provider>
    )

    const container = document.getElementById('app')
    const root = createRoot(container)
    root.render(app)
}

setTimeout(wrapper, delayBeforeStart)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
