// React imports
import React from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core'

//i18n
import { Trans } from 'react-i18next' //useTranslation, 

const useStyles = makeStyles( (theme) => ({
    root: {
        fontSize: theme.typography.fontSize * 0.95,
    },
    [theme.breakpoints.up('sm')]: {
        root: {
            fontSize: theme.typography.fontSize
        }
    },
    tableContainer: {
        overflowY: 'scroll',
        height: '8rem', //TODO: Proper calculated height would be better
    },
    table: {
        width: '100%',
    },
    tableHeader: {
        textAlign: 'left',
    },
    odd: {
        backgroundColor: theme.palette.grey[100],
    }
    
}))

const Registration = ( props => {

    const classes = useStyles()

    return (
        <Box mt={4} mb={2}> 
            <Box>
                <Typography
                variant="body1"
                color="textSecondary"
                className={classes.root}>
                    <Trans i18nKey="registrationTitle">Dear Customer!</Trans>
                </Typography>
            </Box>
            <Box mt={1.5}>
                <Typography
                variant="body1"
                color="textSecondary"
                className={classes.root}>
                    <Trans i18nKey="registrationBody">To registred or change your password, please contact our customer support.</Trans>
                </Typography>
            </Box>
            <Box mt={3} mb={2} align="right">
                <Link
                variant="body1"
                href={`tel:${process.env.REACT_APP_CALL_CENTER_PHONE_NUMBER}`}
                className={classes.root}>
                    {process.env.REACT_APP_CALL_CENTER_PHONE_NUMBER.match(/[^/].{1,3}/g).join('-')}
                </Link>
            </Box>
            <div className={classes.tableContainer}>
                <Typography
                variant="body2"
                color="textSecondary"
                className={classes.root}>
                    <table className={classes.table}>
                        <tr>
                            <th className={classes.tableHeader}><Trans i18nKey="registrationTableHeader">Customer Service opening hours</Trans></th>
                        </tr>
                        <tr>
                            <td className={classes.odd}><Trans i18nKey="registrationTable_1th_line">Toll: 0-24h</Trans></td>
                        </tr>
                        <tr>
                            <td><Trans i18nKey="registrationTable_2th_line">Sales: Mon-Thu: 8:00am-4:30pm Fri: 8:00am-3:00pm</Trans></td>
                        </tr>
                        <tr>
                            <td className={classes.odd}><Trans i18nKey="registrationTable_3th_line">Accounts receivable management:<br />Mon-Fri: 8:00am-4:00pm</Trans></td>
                        </tr>
                        <tr>
                            <td><Trans i18nKey="registrationTable_4th_line">Bug report and Customer service:<br />Mon-Fri: 7:00am-7:00pm (Other times on call)</Trans></td>
                        </tr>
                        <tr>
                            <td className={classes.odd}><Trans i18nKey="registrationTable_5th_line">Arrangement of an installation appointment:<br />Mon-Fri: 8:00am-4:00pm</Trans></td>
                        </tr>
                    </table>
                </Typography>
            </div>
        </Box>
    )
})

export default Registration