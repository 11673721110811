import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

/**
 * Generate a sharable link for the target
 * @param {object} credentials - Complex object, mandatory to contain the following properties:
 * @param {string} credentials.username - The username
 * @param {string} credentials.password - The password
 * @param {string} acceptLanguage  - Mandatory parameter for the API, use the current language
 * @param {object} payloadAsJSON - The payload as JSON
 */
export const generateSharableLink = (credentials, payloadAsJSON, acceptLanguage) => {
    return axios.post(
        `${BASE_URL}/urls/generate`,
        payloadAsJSON,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': acceptLanguage,
            },
            auth: credentials
        }
    )
}