// Action Types import
import * as actionTypes from '../actions/actionTypes'

import * as COM from '../../utilities/common.js'

// Initial state
const initialState = {
    inProgress: false,
    target: null,
    refuels: [],
    dateRangeFrom: COM.TODAY,
    dateRangeTo: COM.TODAY,
    offset: 0,
    limit: 25, //TODO: from settings
    selectedTab: 0,
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {

        case actionTypes.REFUELS_FETCH_START: return COM.update(state, {inProgress: true})
        case actionTypes.REFUELS_FETCH_SUCCESS: return COM.update(state, {
            inProgress: false,
            refuels: action.payload.refuels,
            offset:  action.payload.offset,
            limit: action.payload.limit
        })
        case actionTypes.REFUELS_FETCH_FAIL: return COM.update(state, {inProgress: false})

        case actionTypes.REFUELS_SET_REFUELS: return COM.update(state, {refuels: action.payload})

        case actionTypes.REFUELS_ADD_TARGET: return COM.update(state, {target: action.target})
        case actionTypes.REFUELS_DELETE_TARGET: return COM.update(state, {target: null})

        case actionTypes.REFUELS_SET_DATE_RANGE: return COM.update(state, {dateRangeTo: action.to, dateRangeFrom: action.from})

        case actionTypes.REFUELS_SET_SELECTED_TAB: return COM.update(state, {selectedTab: action.payload})

        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return COM.update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer