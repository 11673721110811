import { takeEvery, put, call, select, putResolve, delay, take } from 'redux-saga/effects'

import * as routesActions from '../actions/routes'
import * as mapActions from '../actions/map'
//import * as settingsActions from '../actions/settings'

import * as actionTypes from '../actions/actionTypes'
import * as routesRepo from '../../store/repository/routes'

import * as COM from '../../utilities/common'

import L from 'leaflet'

function* fetchRoutes(action) {

    try {
        const state = yield select()

        //We are going to create the proper 'from' and 'to' time micros for the server
        const processedFromTimeMicros = COM.createStartOfDayServerTimeMicrosFromDate(action.fromTime)
        const processedToTimeMicros = COM.createEndOfDayServerTimeMicrosFromDate(action.toTime)
        
        const params = {
            acceptLanguage: state.settings.acceptLanguage,
            credentials: state.auth.credentials,
            targetId: action.targetId,
            fromTime: processedFromTimeMicros,
            toTime: processedToTimeMicros,
        }

        const { data } = yield call(routesRepo.getRoutes.bind(this, params))
        
        for (var i = 0; i < data.length; i++) {
            const item = data[i]
            item.targetId = action.targetId
            item.positions = []
            
            //[HACK]
            //XXX: PLUTO-1230
            //XXX: The server returns invalid start positions for some routes.
            //XXX: https://jira.idata.hu/browse/PLUTO-1230
            //XXX:
            //XXX: After this function the route fetch success action will be dispatched.
            //XXX: We have to flag this route as invalid if the start position is not valid.
            //XXX: The bound creator will not contain this route in the calculation to avoid bouncing of the map.
            //XXX: After we have fetched the positions of this route, we could use a valid start position and remove the flag.
            //XXX: This bug definitely should be fixed on the server side!
            if (item.startPosition.longitude === 0 && item.startPosition.latitude === 0) {
                item.isInvalidStartPosition = true
                console.debug(`Invalid start position for route ${item}. It will be marked as invalid until the position is fetched from the server.`);
            }
            //[/HACK]
        }

        yield put(routesActions.routesFetchSucces(data))
    
    } catch (exp) {
        console.error("Error while fetching rutes!", exp)
        yield put(routesActions.routesFetchFail(exp))
    }
}

function* fetchPositions(action) {

    const state = yield select()
        
    const targetId = action.targetId
    const fromTime = action.fromTime

    try {

        const params = {
            acceptLanguage: state.settings.acceptLanguage,
            credentials: state.auth.credentials,
            targetId: targetId,
            fromTime: fromTime,
            toTime: action.toTime,
        }

        const { data } = yield call(routesRepo.getPositions.bind(this, params))

        yield put(routesActions.positionsFetchSucces(data, fromTime))
    
    } catch (exp) {

        console.debug("Exception while fetching positions!",exp, "From time:", fromTime)
        yield put(routesActions.positionsFetchFail(exp))
    }
}

function updateItems(items, checked) {
    items.forEach(itm => {
        itm.checked = checked
        itm.markers = null
        itm.positions = []
    })
    return items
} 

const deepItemsCopy = (items) => {

    const copiedItems = [...items]
    for (var i = 0; i < items.length; i++) {
        copiedItems[i].events = [...items[i].events]
    }

    return copiedItems
}

function* handleToggleChecked(action) {
    
    const timeMicros = action.payload

    const currentState = yield select()
    const multiselect = currentState.routes.multiselect

    //XXX: Does this deep copy necessary?
    const items = deepItemsCopy(currentState.routes.items)

    const itm = COM.getRouteItemByStartPositionTimeMicros(items, timeMicros)

    if (!multiselect) updateItems(items, false)

    itm.checked = !itm.checked
    if (!itm.checked) {
        itm.positions = []
        itm.markers = null
    } else {
        itm.inProgress = true
        yield putResolve(routesActions.positionsFetchStart(
            itm.targetId,
            itm.startPosition.timeMicros,
            itm.stopPosition.timeMicros))
    }

    yield put(routesActions.setItems(items))
}

function* handleSetMultiselect(action) {
    
    const currentState = yield select()
    const multiselect = currentState.routes.multiselect
    const items = [...currentState.routes.items]

    // we have to clear all selected if multiselect is false
    if (!multiselect) {
        updateItems(items, false)
        console.debug("setMultiselect: Multiselect is NOT allowed! Sections have been cleared.")
    } else {
        console.debug("setMultiselect: Multiselect is allowed!")
    }

    yield put(routesActions.setItems(items))
}

function* handleSelectAllItems() {

    const currentState = yield select()
    const items = [...currentState.routes.items] //XXX: does deep-copy necessary?
    
    items.forEach( itm => {
        itm.inProgress = true
        itm.checked = true
    })

    yield put(routesActions.setItems(items))

    for (let i = 0; i < items.length; i++) {
        const itm = items[i]
        yield putResolve(routesActions.positionsFetchStart(
            itm.targetId,
            itm.startPosition.timeMicros,
            itm.stopPosition.timeMicros))
        yield delay(200) //XXX: CHECK THIS!!!! WITHOUT DELAY THE SERVER DROPS THE QUERIES!
    }
}

function* handleClearSelectedSections() {
    const currentState = yield select()
    const items = [...currentState.routes.items] //XXX: does this deep-copy necessary?
    
    updateItems(items, false)

    yield put(routesActions.setItems(items))
}

function* handleClearDateRange() {
    const d = new Date()
    yield putResolve(routesActions.setDateRangeFrom(d))
    yield putResolve(routesActions.setDateRangeTo(d))
}

function* fetchIfNecessary() {

    const currentState = yield select()
    const routes = currentState.routes
    const targetId = routes.target ? routes.target.id : null //To avoid to read propery on null!
    const fromTime = routes.dateRangeFrom
    const toTime = routes.dateRangeTo

    if (targetId) yield putResolve(routesActions.routesFetchStart(targetId, fromTime, toTime))
}

function* setPositionsFetchInProgress(action, value) {

    const { routes } = yield select()
    const newItems = JSON.parse(JSON.stringify(routes.items))
    const fromTime = action.fromTime
    
    const items = COM.getRouteItemByStartPositionTimeMicros(newItems, fromTime)
    items.inProgress = value

    yield putResolve(routesActions.setItems(newItems))
}

function* handlePositionsFetchStart(action) {
    yield setPositionsFetchInProgress(action, true)
}

function* handlePositionsFetchFail(action) {
    yield setPositionsFetchInProgress(action, false)
}

function* handlePositionsFetchSuccess(action) {

    const { routes, map } = yield select()
    const { items } = routes
    const { data, fromTime } = action 
    
    const newItems = [...items] 
    const itm = COM.getRouteItemByStartPositionTimeMicros(newItems, fromTime)
    itm.inProgress = false
    itm.markers = data.markers
    itm.positions = data.positions
  
    itm.latLngPositions = data.positions.map( p => [p.latitude, p.longitude])

    //[HACK]
    //XXX: PLUTO-1230
    //XXX: If the tracked wehicle has no GPS data the positions array can contain two zero values!
    //XXX: The 0,0 coordinates are located somewhere in the Atlantic Ocean in the Gulf of Guinea, so we have to remove these positions!
    //itm.positions = itm.positions.filter( p => p.latitude !== 0 && p.longitude !== 0)
    if (itm.isInvalidStartPosition) {
        console.debug("Invalid start position! Resetting start position to the first position in the positions array!")
        
        //We have to reset the start position to the first position in the positions array!
        //But we have to keep the timeMicros value, otherwise the start time of the route will be changed!
        itm.startPosition.longitude = itm.positions[0].longitude
        itm.startPosition.latitude = itm.positions[0].latitude
        itm.isInvalidStartPosition = false
        console.debug("New start position: ", itm.startPosition)
    }
    //[/HACK]

    const positionArray = data.positions
    
    const sections = []
    let totalRouteLength = 0;

    //create route sections
    for(let i = 1; i < positionArray.length; i++) {
        
        const p1 = COM.toLatLngArray(positionArray[i - 1])
        const p2 = COM.toLatLngArray(positionArray[i])

        const distance = map.ref.distance(p1, p2)
        const middle = COM.midPoint(p1, p2)
        const section = {
            index: i - 1, //To start from 0
            angle: COM.calcAngle(p1, p2, -1),
            length: distance,
            distanceFromStart: totalRouteLength,
            midPointDistanceFromStart: totalRouteLength + (distance / 2),
            endPointDistanceFromStart: totalRouteLength + distance,
            midPoint: middle,
        }

        totalRouteLength += distance
        sections.push(section);
    }
    console.debug(`Total count of route sections: ${sections.length}`)
    console.debug(`Total route length: ${totalRouteLength}m`)

    itm.sections = sections
    itm.totalRouteLength = totalRouteLength

    yield putResolve(routesActions.setItems(newItems))
}

function* itemCheckedByUser(action) {
    const { payload } = action
    yield putResolve(routesActions.toggleChecked(payload))
}

function* handleSetItems(action) {
    yield putResolve(routesActions.createBounds())
}

function* handleSetBounds(action) {
    console.debug("Bounds for routes have been changed.", action.payload)
    yield putResolve(routesActions.fitToBounds())
}

function* handleCreateBounds(action) {

    try {
        
        // IMPORTANT: In case of routes, we have a preprocessed state to work with,
        // so we have to get the section from the state instead of use the payload of the action parameter
        const { routes } = yield select()
        const { items } = routes

        const getLatLng = (obj) => L.latLng([obj.latitude, obj.longitude])
        const coordinates = []
        items.forEach( itm => {
            if (itm.checked) {
                if (!itm.isInvalidStartPosition) {
                    const positions = itm.positions.map( p => getLatLng(p) )
                    coordinates.push(...positions)
                    coordinates.push(getLatLng(itm.startPosition))
                    coordinates.push(getLatLng(itm.stopPosition))
                } else {
                    console.debug(`Creating bounds: route has invalid start position! It has been skipped!`, itm)
                }
            }
        })

        const bounds = coordinates.length === 0 ? null : COM.createBounds(coordinates)
        yield putResolve(routesActions.setBounds(bounds))

    } catch (error) {
        console.error(`Oops! Something went wrong during creating bounds for routes!`, error)
    }
}

function* handlePageIsReady(action) {
    yield take(actionTypes.MAP_SET_REF)
    yield putResolve(routesActions.createBounds())
}

function* handleFitToBounds(action) {
    const { routes } = yield select()
    const bounds = routes.bounds
    yield putResolve(mapActions.fitToBounds(bounds))
}

export function* saga() {
    yield takeEvery(actionTypes.NAVIGATION_ROUTES_PAGE_IS_READY, handlePageIsReady)

    yield takeEvery(actionTypes.ROUTES_SET_ITEMS, handleSetItems)
    yield takeEvery(actionTypes.ROUTES_CREATE_BOUNDS, handleCreateBounds)
    yield takeEvery(actionTypes.ROUTES_SET_BOUNDS, handleSetBounds)
    yield takeEvery([
        actionTypes.ROUTES_FIT_TO_BOUNDS_USER_REQUEST,
        actionTypes.ROUTES_FIT_TO_BOUNDS
    ], handleFitToBounds)

    yield takeEvery(actionTypes.ROUTES_FETCH_START, fetchRoutes)

    yield takeEvery(actionTypes.ROUTES_POSITIONS_FETCH_START, fetchPositions)
    yield takeEvery(actionTypes.ROUTES_POSITIONS_FETCH_START, handlePositionsFetchStart)
    yield takeEvery(actionTypes.ROUTES_POSITIONS_FETCH_FAIL, handlePositionsFetchFail)
    yield takeEvery(actionTypes.ROUTES_POSITIONS_FETCH_SUCCESS, handlePositionsFetchSuccess)

    yield takeEvery(actionTypes.ROUTES_ITEM_CHECKED_BY_USER, itemCheckedByUser)
    yield takeEvery(actionTypes.ROUTES_TOGGLE_CHECKED, handleToggleChecked)
    yield takeEvery(actionTypes.ROUTES_SET_MULTISELECT, handleSetMultiselect)

    yield takeEvery(actionTypes.ROUTES_SELECT_ALL_ITEMS, handleSelectAllItems)
    yield takeEvery(actionTypes.ROUTES_CLEAR_SELECTED_SECTIONS, handleClearSelectedSections)

    yield takeEvery(actionTypes.ROUTES_CLEAR_DATE_RANGE, handleClearDateRange)
   

    yield takeEvery([
        actionTypes.ROUTES_SET_DATE_RANGE,
        actionTypes.ROUTES_ADD_TARGET,
    ], fetchIfNecessary)
}