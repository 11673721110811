import * as actionTypes from './actionTypes'

export const authStart = (credentials) => {
    return {
        type: actionTypes.AUTH_START,
        credentials: credentials
    }
}

export const authSuccess = (credentials) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        credentials: credentials
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const authSignOutStart = () => {
    return {
        type: actionTypes.AUTH_SIGN_OUT_START,
    }
}

export const authSignOutSuccess = () => {
    return {
        type: actionTypes.AUTH_SIGN_OUT_SUCCESS,
    }
}