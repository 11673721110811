import { takeEvery, put, putResolve, call, select, fork, take, all } from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import * as authActions from '../actions/auth'
import * as settingsActions from '../actions/settings'
import * as authRepo from '../../store/repository/auth'
import * as COM from '../../utilities/common'

import AuthError from '../../other/AuthError'

function* authStart(action) {

    try {
        const { settings } = yield select()
        const language = settings.languageCode
        const { data } = yield call(authRepo.getCurrentUser, action.credentials, language)

        let extendedCredentials = {...action.credentials, ...data}
 
        yield putResolve(authActions.authSuccess(extendedCredentials))

    } catch (exp) {
        console.warn("Error while sending password reset request: ", exp);

        const error = new AuthError(exp)
        yield put(authActions.authFail(error))
    }
}

function* authSignOutStart(action) {
    yield put(authActions.authSignOutSuccess())
}

function* checkAutoLogin(action) {
    
    const { device, settings } = yield select()
    const { autoLoginData, forcedAutoLogin } = settings  
    const { info } = device
    const isExoskeleton = info.presenter === (COM.IOS_EXOSKELETON || COM.ANDROID_EXOSKELETON)

    console.debug("🤖 Checking auto login... autoLoginData: ", autoLoginData, " isExoskeleton: ", isExoskeleton, " forcedAutoLogin: ", forcedAutoLogin)

    if ((isExoskeleton || forcedAutoLogin) && autoLoginData && autoLoginData.username && autoLoginData.password) {
        
        console.debug("Do auto login: ", autoLoginData, info.presenter)

        const credentials = {
            username: autoLoginData.username,
            password: autoLoginData.password,
        }
        
        yield putResolve(authActions.authStart(credentials))

    } else {
        console.debug("Auto login doesn't necessary. Do normal login.")
    }
}

function* authSuccess(action) {

    const { auth, device, settings } = yield select()

    const { autoLoginData, forcedAutoLogin } = settings
    const { credentials } = auth
    const { info } = device

    const isExoskeleton = info.presenter === (COM.IOS_EXOSKELETON || COM.ANDROID_EXOSKELETON)

    if (autoLoginData && autoLoginData.id !== credentials.id) {
        yield putResolve(settingsActions.initState())
        console.debug("The stored user id and the id used for the login don't mach! Using the initial state for 'settings'.")
    }
    
    //XXX: ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
    //XXX:
    //XXX: ❗❗❗ VERY BAD CODE & DESIGN                ❗❗❗
    //XXX: ❗❗❗ CHANGE THIS CODE AS SOON AS POSSIBLE! ❗❗❗ 
    //XXX:  
    //XXX:
    //XXX:  It's a VERY BAD part of the current application!
    //XXX:  Is saves the username and password into the local storage.
    //XXX:  This 'feature' have to be replaced with a token-based solution as soon as possible!
    //XXX:  The customer had been informed multiple times before this code has been implemented.
    //XXX:  They understand, acknowledge and accept the riskk due to their higher priority of business needs.
    //XXX:
    //XXX: ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗

    const persistObject = {...credentials} //We have to make a copy here, to not to modify the credentials of the state!
    if (!forcedAutoLogin && !isExoskeleton) {
        console.debug("Remove password from auto login data. isExoskeleton: ", isExoskeleton, " forcedAutoLogin: ", forcedAutoLogin, " autoLoginData: ", autoLoginData)
        persistObject.password = null 
    }
    
    yield putResolve(settingsActions.autoLoginDataChange(persistObject))   
}

function* waitForAutoLogin() {
    
    yield all([
        take(actionTypes.DEVICE_INFO_READY),
        take("persist/REHYDRATE"),
    ])

    yield checkAutoLogin()
}


export function* saga() {

    yield takeEvery(actionTypes.AUTH_START, authStart)
    yield takeEvery(actionTypes.AUTH_SUCCESS, authSuccess)
    yield takeEvery(actionTypes.AUTH_SIGN_OUT_START, authSignOutStart)

    yield fork(waitForAutoLogin)
}
