// React imports
import React from 'react'

// i18n
import { useTranslation } from 'react-i18next'

// Copy helper import
import copy from 'copy-to-clipboard'

// Material-UI
import {
    makeStyles,
    Switch,
    Button,
    FormControlLabel,
} from '@material-ui/core'

// Icons
import CopyIcon from '@material-ui/icons/FileCopy'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
    },
}));

const SubContent = (props) => {

    const { t } = useTranslation()
    const classes = useStyles()
    
    if (!props.show) return null

    const handleCopyClick = () => {
        copy(props.link)
        console.debug(`'${props.link}' has been copied!`)
    }

    return (
        <div className={classes.root}>
            <FormControlLabel control={<Switch checked={props.isEmbedded} onChange={props.switchChanged} />} label={t("Embedded")} />
            <Button
                onClick={handleCopyClick}
                variant="contained"
                color="default"
                size="small"
                className={classes.button}
                endIcon={<CopyIcon />}>
                {t("Copy")}
            </Button>
        </div>
    )
}

export default SubContent