// Action Types import
// import * as actionTypes from '../actions/actionTypes'

// import { update } from '../../utilities/common.js'

// Initial state
const initialState = {

}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {
        
        default: return state
    }
}

export default reducer