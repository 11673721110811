// React
import React, { useEffect, useState } from 'react'
import * as COM from '../../utilities/common'

// Redux
import { connect } from 'react-redux'
import * as mapActions from '../../store/actions/map'

// i18n
import { useTranslation } from 'react-i18next'

import AutorenewIcon from '@material-ui/icons/Autorenew'
import ExpandMore from '@material-ui/icons/ExpandMore'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import MapIcon from '@material-ui/icons/Map'
import CenterIcon from '@material-ui/icons/CenterFocusWeak'
import ShareIcon from '@material-ui/icons/Share'

import {
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  makeStyles
} from '@material-ui/core'


import ShareTargetDialog from '../ShareTarget/ShareTargetDialog'
import NoSelectedTarget from '../Filter/NoSelectedTarget'

const useStyles = makeStyles((theme) => ({
    odd: {
      backgroundColor: "lightgray" //TODO: from theme!
    },
    even: {
      backgroundColor: "white" //TOOD: from theme!
    },
    chipContainer: {
      display: "flex",
    },
    chipWrapper: {
      marginRight: "0.25rem",
    },
    emptyList: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    '@keyframes rotate360': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      }
    },
    animateRotate360: {
      animation: '$rotate360 1s linear infinite'
    }
}))

const TrackingSelectedTargets = (props) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(false)
    const [openGoogleMapsLink, setOpenGooleMapsLink] = useState(null)
    const [isShareDialogOpen, setShareDialogOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    const { items } = props;

    /* 
      *** BUGFIUX PLUTO-1224 ****

      This logic is here to solve the PLUTO-1224 ticket. The issue was like this: when a user did click on
      the 'open with Google Maps' menu item in the target sharing screen, the popup menu did not close in Firefox.
      The problem caused by asynchronous behavior of the 'setState' call.
      The new windows had been opened before the 'setState' did finish.
      The opener window's process was terminated and the newly opened window's process had been activated.
      To overcome this issue, we are going to set the link first and after that we are going to close the popup window.
      We use the 'useEffect' hook to check the necessary conditions and open the new window from the hook.
    
    */
    useEffect(() => {
      if (!anchorEl && openGoogleMapsLink) {
        window.open(openGoogleMapsLink)
        console.debug(`New window (tab) has been opened with Google Maps.`, selectedItem)
      } 
    },[openGoogleMapsLink, anchorEl, selectedItem])

    if (items.length === 0) {
      return (
        <div className={classes.emptyList}>
          <NoSelectedTarget />
        </div>
      )
    }

    const comparator = (a,b) => b.frontendTimeMicros - a.frontendTimeMicros
    
    const statusIcons= {}
    statusIcons[`${COM.TRACKING_STATUS_PENDING}`] = <AutorenewIcon className={classes.animateRotate360} />
    statusIcons[`${COM.TRACKING_STATUS_NO_DATA}`] = <NotInterestedIcon />
    statusIcons[`${COM.TRACKING_STATUS_LIVE}`] = <ExpandMore />

    const statusColors= {}
    statusColors[`${COM.TRACKING_STATUS_PENDING}`] = "default"
    statusColors[`${COM.TRACKING_STATUS_NO_DATA}`] = "default"
    statusColors[`${COM.TRACKING_STATUS_LIVE}`] = "primary"

    const handleMenuClose = (event) => {
      setAnchorEl(null)
    }

    const handleMenuOpen = (event, item) => {
      setOpenGooleMapsLink(null)
      setSelectedItem(item)
      setAnchorEl(event.currentTarget)
    }

    const handleOpenInGoogleMaps = (event) => { 
      const latlng = selectedItem.currentPosition ? `${selectedItem.currentPosition.latitude},${selectedItem.currentPosition.longitude}` : null
      if (latlng) {
          setOpenGooleMapsLink(COM.googleMapsURLFromLatLng(latlng));
          console.debug(`New window (tab) will be open Google Maps.`, selectedItem)
      }
      else {
          console.debug("No currentPosition for target! ", selectedItem)
      }
      handleMenuClose(event)
    }

    const handleGetSharableLink = (event) => {
      handleMenuClose(event) //close the popup
      setShareDialogOpen(true) //open the dialog
    }

    const handleCenterTarget = (event) => {
      
      handleMenuClose(event)
      
      selectedItem && selectedItem.currentPosition && props.mapFitTo([
          selectedItem.currentPosition.latitude,
          selectedItem.currentPosition.longitude
      ])
    }

    const handleShareDialogClose = () => {
      setShareDialogOpen(false)
    }
     
    return (
      <>
        
        <ShareTargetDialog
          item={selectedItem}
          onClose={handleShareDialogClose}
          open={isShareDialogOpen} />

        <Menu
          id={`share-menu`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose} >
          
          <MenuItem onClick={handleCenterTarget}>
          <ListItemIcon>
              <CenterIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t("Fit map")}</Typography>
          </MenuItem>
          
          <MenuItem onClick={handleOpenInGoogleMaps}>
          <ListItemIcon>
              <MapIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t("Open with Google Maps")}</Typography>
          </MenuItem>

          <MenuItem onClick={handleGetSharableLink}>
          <ListItemIcon>
              <ShareIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">{t("Sharable link")}</Typography>
          </MenuItem>
        </Menu>

        <div className={classes.chipContainer}>
          {props.items.sort(comparator).map((item, index) => (
            <div className={classes.chipWrapper} key={`cwdiv-${index}`}>
              <Chip
                icon={statusIcons[item.status]}
                color={statusColors[item.status]}
                label={item.target.name}
                key={index}
                onClick={ (event) => handleMenuOpen(event, item) }
                onDelete={props.onDelete.bind(this, item.target)} />
            </div>
          ))}
        </div>
      </>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    mapFitTo: (latlng) => dispatch(mapActions.fitTo(latlng)),
  }
}

const mapStateToProps = (state) => {
  return {
    mapState: state.map,
    settingsState: state.settings
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingSelectedTargets)