// React imports
import React from 'react'
import * as COM from '../../utilities/common'

import makeStyles from '@material-ui/core/styles/makeStyles'

const INVISIBLE_PLACEHOLDER_CHAR = String.fromCharCode(8205)

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "0.5rem",
        width: "75%",
        maxWidth: "480px",
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        borderRadius: "1rem",
        overflowWrap: "break-word",
    },
    dateTime: {
        color: theme.palette.secondary.main,
        fontSize: "85%",
    },
    received: {
        backgroundColor: "white",
        border: `solid 2px ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    },
    sent: {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}))

const TextRow = (props) => {

    const classes = useStyles()
    
    const { message } = props
    const { content, type, sentStatus } = message

    const messageSentStatusDate = new Date(COM.createFrontendTimeMilis(sentStatus.timeMicros))

    //XXX: in case of empty string, place an invisible char (UTF 8205) instead to get a same row height
    const text = (content.body === "" || content.body === null) ? INVISIBLE_PLACEHOLDER_CHAR : content.body
    const conditionalClass = (type === COM.CHAT_TYPE_SENT ? classes.sent : classes.received)

    return (
        <div className={`${classes.root} ${conditionalClass}`}>
            {text}<br />
            <span className={classes.dateTime}>{COM.formattedDateTime(messageSentStatusDate)}</span>
        </div>
    )
}

export default TextRow