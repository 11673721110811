// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { update } from '../../utilities/common.js'

// Initial state
const initialState = {
    inProgress: false,
    loginToken: null
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {

        case actionTypes.SHARE_TARGET_GENERATE_START: return update(state, {inProgress: true})
        case actionTypes.SHARE_TARGET_GENERATE_SUCCESS: return update(state, {inProgress: false, loginToken: action.loginToken})
        case actionTypes.SHARE_TARGET_GENERATE_FAIL: return update(state, {inProgress: false})

        default: return state
    }
}

export default reducer