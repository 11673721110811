import React from 'react'
import { connect } from 'react-redux'

import {
    Badge,
} from '@material-ui/core'

const EnhancedIcon = (props) => {
    const { state, icon, isSelected  } = props;
    const { settings } = state;
    const { filterPaneVisible } = settings;

    if (!filterPaneVisible || (isSelected)) return icon
    return (
        <Badge
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
            variant="dot"
            color="secondary" >
            
            {icon}
        
        </Badge>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        //
    }
}

const mapStateToProps = (state) => {
    return {
        state: state,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedIcon)