// Action Types import
import * as actionTypes from '../actions/actionTypes'

// Other imports
import { update } from '../../utilities/common.js'

// Initial state
const initialState = {
    targets: [],
    inProgress: false,
    bounds: null,
    autoFitToBounds: false,
    popupTargetId: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.TRACKING_SET_IN_PROGRESS: return update(state, {inProgress: action.payload})
        case actionTypes.TRACKING_SET_TARGETS: return update(state, {targets: action.payload})
        case actionTypes.TRACKING_SET_BOUNDS: return update(state, {bounds: action.payload})
        case actionTypes.TRACKING_SET_AUTO_FIT_TO_BOUNDS: return update(state, {autoFitToBounds: action.payload})
        case actionTypes.TRACKING_SET_POPUP_TARGET_ID: return update(state, {popupTargetId: action.payload})

        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer