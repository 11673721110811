// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { update } from '../../utilities/common.js'

// Initial state
const initialState = {
    ref: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.MAP_SET_REF: return update(state, {ref: action.ref})

        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState) //Return to initial state
        
        default: return state
    }
}

export default reducer