import * as actionTypes from './actionTypes'

export const startMapEventsChannel = () => {
    return {
        type: actionTypes.MAP_EVENTS_START_CHANNEL,
    }
}

export const stopMapEventsChannel = () => {
    return {
        type: actionTypes.MAP_EVENTS_STOP_CHANNEL,
    }
}

export const mapDidZoom = (fromLevel, toLevel) => {
    return {
        type: actionTypes.MAP_EVENTS_MAP_DID_ZOOM,
    }
}

export const mapMovedByUser = (fromLatLng, toLatLng) => {
    return {
        type: actionTypes.MAP_EVENTS_MAP_MOVED_BY_USER,
        payload: {fromLatLng: fromLatLng, toLatLng: toLatLng}
    }   
}