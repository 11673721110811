import { takeEvery, put, select } from 'redux-saga/effects'
import * as errorActions from './../actions/error'
import * as actionTypes from '../actions/actionTypes'

import DisplayableError from '../../other/DisplayableError'

function* addError(action) {

    const currentState = yield select()
    const error = currentState.error
    const newItems = [...error.items]

    if (newItems.length >= process.env.REACT_APP_MAX_ERROR_ARRAY_LENGTH) {
        newItems.pop() //drop the last item
    }

    newItems.push(action.error)
    newItems.sort((a,b) => b.date.getTime() - a.date.getTime() )

    yield put(errorActions.setItems(newItems))
}

function* setAcknowledged(action) {
    const currentState = yield select()
    const error = currentState.error
    const newItems = [...error.items]

    const item = newItems.find(i => i.uuid === action.payload)
    if (item) {
        item.acknowledged = true
        yield put(errorActions.setItems(newItems))
    } else {
        console.error(`ERROR! There is no matching error item for uuid ${action.payload}`)
    }
}

export function* saga() {
    
    //NOTE: BE CAREFUL, we are watching for any action passed!
    yield takeEvery(action => action.error instanceof DisplayableError, addError)

    yield takeEvery(actionTypes.ERROR_SET_ACKNOWLEDGED, setAcknowledged) 
}