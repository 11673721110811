// React imports
import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as routesActions from '../../store/actions/routes'
import * as COM from '../../utilities/common'

// i18n
import { useTranslation } from 'react-i18next'

import {
    Checkbox,
    CircularProgress,
    Grid,
    Box,
    makeStyles,
    Typography,
    useTheme,
    useMediaQuery,
    //alpha,
} from '@material-ui/core'

//import AirIcon from '@mui/icons-material/Air';
import { ArrowDropDown, Error, HourglassFull, LocalShipping, Place } from '@material-ui/icons'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import TailwindIcon from './IconsAndMarkers/TailwindSvgIcon'

const useStyles = makeStyles(theme => ({
    root: {
       borderTop: `solid 1px ${theme.palette.grey[400]}`
    },
    checkedRow: {
        backgroundColor: `#ffffdd`
    },
    row: {
        padding: `.25rem 0 .25rem 0`,
        fontSize: `.8rem`,
        display: `flex`,
        alignItems: "center",
    },
    startRow: {
        paddingTop: ".5rem",
        width: "100%",
        display: `flex`,
        justifyContent: "flex-start",
        borderTop: `dotted 1px ${theme.palette.grey[300]}`,

    },
    stopRow: {
        paddingBottom: ".5rem",
        width: "100%",
        display: `flex`,
        justifyContent: "flex-end",
        borderBottom: `dotted 1px ${theme.palette.grey[300]}`,

    },
    durationAndDistanceContainer: {
        boxSizing: `border-box`,
        padding: `0 .5rem 0 .5rem`,
        justifyContent: "space-around"
    },
    durationAndDistanceValues: {
        fontWeight: "bold",
    },
    driverName: {
        paddingLeft: "0.5rem",
    },
    eventsContainer: {
        borderBottom: `dotted 1px ${theme.palette.grey[300]}`,
        padding: `0 0 .5rem 0`,
    },
    progressIndicatorContainer: {
        padding: `.5rem`,
    },
    footer: {
        padding: `.5rem 0 .5rem 0`,
    },
    icon: {
        color: theme.palette.grey[500],
        marginRight: `.25rem`
    },
    even: {
        backgroundColor: `rgba(0, 0, 0, .05)`
    },
    summaryNormalBg: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    summaryMultipleSelectedBg: {
        //backgroundColor: alpha(theme.palette.primary.main., 0.85)
        backgroundColor: theme.palette.secondary.main,
        color: "black",
        "& *": {
            color: "black",
        }
    },
    summary: {

    },
    hide: {
        display: "none",
    },
    center: {
        textAlign: "center",  
    },
    right: {
        textAlign: "right",  
    },
    summaryTitle: {
        textAlign: "center",
    },
    eventIconContainer: {
        color: theme.palette.grey[700],
        paddingRight: ".5rem",
    },
    // The MUI icons do not contain proper 'fast moving van' icon
    // so we have to create one by using CSS
    running: {
        transform: "skew(-15deg, 0deg)",
    }
}))

const EventSwitch = ({events, eventsVisible}) => {

    const { t } = useTranslation()
    const classes = useStyles()

    if (!events || events.length < 1) return null

    const icon = eventsVisible ? <ExpandMoreIcon className={classes.icon} /> : <ExpandLessIcon className={classes.icon}/>
    const text = events.length > 1 ? t("events") : t("event")

    return <>{events.length}&nbsp;{text}{icon}</>
}

const EventRow = ({event, index}) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    
    //Example data structure:
    //code: 10007
    //description: "Idle"
    //eventTimeMicros: 1607665069000000
    //marker: "standing"
    const { description, eventTimeMicros, marker } = event

    const runningClasses = COM.clsx({
        [classes.eventIconContainer]: true,
        [classes.running]: true
    })

    //XXX: icon key change
    //Consider to use the 'code' as key instead of the 'marker'
    //Please be careful the markers start with small letters while the
    //descriptions start with capital letters.
    const icons = {
        "running": <div className={runningClasses}><TailwindIcon fill={theme.palette.grey[700]} /><LocalShipping /></div>,
        "standing": <div className={classes.eventIcon}><HourglassFull /><LocalShipping /></div>,
    }

    const markerIcon = icons[marker] || <Error />

    return (
        <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            key={`event-row-${index}`}>
            
            <Grid
                item
                xs={3}
                container
                justifyContent="flex-start"
                alignItems="center">{markerIcon}{COM.formattedTime(eventTimeMicros, true)}</Grid>     
            <Grid item xs={9}>{t(description)}, {t(marker)}</Grid>   
        </Grid>
    )
}

const EventList = ({events, eventsVisible}) => {
    
    const classes = useStyles()

    if (!eventsVisible) return null   
    
    return (
        <Grid item xs={12} className={classes.eventsContainer}>
            {events.map( (e, i) => <EventRow event={e} index={i} />)}
        </Grid>
    )
}

const CheckBoxOrProgressIndicator = ({checked, inProgress, onChange, isSummary}) => {
    const classes = useStyles()

    if (isSummary) return null

    if (inProgress) return (
        <Box className={classes.progressIndicatorContainer}>
            <CircularProgress size="1.25rem" color="secondary" />
        </Box>
    )

    return <Checkbox checked={checked || false} onChange={onChange} />
}

const StopDateText = (props) => {
    const { isSummary, dateText } = props
    if (!isSummary) return null
    return dateText
}

const getSignsI18n = (t) => {
    return {
        yearSign: t("y"),
        monthSign: t("mt"),
        daySign: t("d"),
        hourSign: t("h"),
        minuteSign: t("m"),
        secondSign: t("s"),
    }
}

const StandTime = (props) => {

    const { timeMicros } = props
    const classes = useStyles()
    const { t } = useTranslation()
    
    const signs = getSignsI18n(t)
    const standText = COM.formattedDuration(timeMicros, true, signs)
    
    if (!standText) return null

    return (
        <>
            <PauseCircleOutlineIcon fontSize="small" className={classes.icon}/>
            <span>{standText}</span>
        </>
    )
}

const SummaryHeader = (props) => {

    const { section, isSummary } = props

    const { t } = useTranslation()
    const classes = useStyles()

    if (!isSummary) return null
    const { isMultipleSelected, itemCount } = section

    const multipleChunk = isMultipleSelected ? `(${itemCount} ${t("row")})` : ''
    const title = `${t("Summary")} ${multipleChunk}`

    return (
        <Grid xs={12} >
            <Typography variant='h5' className={classes.summaryTitle}>
                {title}
            </Typography>
        </Grid>
    )
}

const ItemRow = (props) => {

    const classes = useStyles()
    const { t } = useTranslation()
    const theme = useTheme()
    const isNormalMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobile))

    const { section, itemCheckedByUser, index, isSummary } = props
    const isIndexEven = index % 2 === 0
    const { checked, events, inProgress } = section
    const { startPosition, stopPosition, standTimeMicros, durationTimeMicros, distanceKm, driver, } = section
   
    const durationText = COM.formattedDuration(durationTimeMicros, true, getSignsI18n(t))
    const distanceUnit = process.env.REACT_APP_DISTANCE_UNIT || 'km'
    const driverName = driver && driver.name ? driver.name : ""

    //We used to use the start and stop positions conditionally. This is the reason why we use
    //a complex object for positionInfo here. I let here this structure in case of future request
    //to place conditional rendering back.
    const positionInfo = {
        start: {
            timeText: COM.formattedTime(startPosition.timeMicros, true),
            dateText: COM.formattedDate(startPosition.timeMicros, true),
            geocodedAddress: startPosition.geocodedAddress,
            icon: <Place fontSize="small" className={classes.icon} />
        },
        stop: {
            timeText: COM.formattedTime(stopPosition.timeMicros, true),
            dateText: COM.formattedDate(stopPosition.timeMicros, true),
            geocodedAddress: stopPosition.geocodedAddress,
            icon: <Place fontSize="small" className={classes.icon} />
        }
    }

    const handleCheckboxChange = () => {
        itemCheckedByUser(startPosition.timeMicros)    
    }

    const [eventsVisible, setEventsVisible] = useState(false)

    const toggleEventsVisibility = (ev) => {
        setEventsVisible(!eventsVisible)
    }

    const wrapperClasses = COM.clsx({
        [classes.checkedRow]: checked,
    })

    const containerClasses = COM.clsx({
        [classes.root]: true,
        [classes.even]: isIndexEven && !isSummary,
        [classes.summary]: isSummary,
        [classes.summaryNormalBg]: isSummary && !section.isMultipleSelected,
        [classes.summaryMultipleSelectedBg]: isSummary && section.isMultipleSelected,
    })

    const downIconGridClasses = COM.clsx({
        [classes.icon]: true,
        [classes.hide]: !isNormalMobile,
        [classes.center]: isNormalMobile,
    })

    const horizontalIconClasses = COM.clsx({
        [classes.icon]: true,
        [classes.hide]: isNormalMobile,
    })

    const routeStartContainerClasses = COM.clsx({
        [classes.center]: isNormalMobile,
    })

    const routeStopContainerClasses = COM.clsx({
        [classes.center]: isNormalMobile,
        [classes.right]: !isNormalMobile,
    })

    const durationAndDistanceContainerClasses = COM.clsx({
        [classes.durationAndDistanceContainer]: true,
    })

    console.debug("YYY ", isNormalMobile)
    
    return (
        <div className={wrapperClasses}>
            
            <Grid
                container
                className={containerClasses}>

                <Grid
                    item
                    xs={12}
                    container
                    className={classes.header}>
                    
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center">
                        
                        <SummaryHeader isSummary={isSummary} section={section}/>
                        
                        <Grid item xs={isSummary ? 6 : 10} container justifyContent="flex-start" alignItems="center">
                            {positionInfo.start.dateText}
                            <span class={classes.driverName}>{driverName}</span>
                        </Grid>
                        <Grid item xs={isSummary ? 6 : 2} container justifyContent="flex-end" alignItems="center">
                            <StopDateText dateText={positionInfo.stop.dateText} isSummary={isSummary} />
                            <CheckBoxOrProgressIndicator
                                isSummary={isSummary}
                                checked={checked}
                                inProgress={inProgress}
                                onChange={handleCheckboxChange} />
                        </Grid>
                    
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center">
                        
                        <Grid
                            item
                            xs={isNormalMobile ? 12 : 3}
                            className={routeStartContainerClasses}>
                            
                            {positionInfo.start.timeText}
                        
                        </Grid>

                        <Grid item xs={12} className={downIconGridClasses}><ArrowDropDown /></Grid>

                        <Grid
                            item
                            xs={isNormalMobile ? 12 : 6}
                            container
                            className={durationAndDistanceContainerClasses}>

                            <ArrowForwardIosIcon fontSize="small" className={horizontalIconClasses}/>
                            <span className={classes.durationAndDistanceValues}>{`${durationText} | ${parseFloat(distanceKm).toFixed(1)} ${distanceUnit}`}</span>
                            <ArrowForwardIosIcon fontSize="small" className={horizontalIconClasses}/>

                        </Grid>

                        <Grid item xs={12} className={downIconGridClasses}><ArrowDropDown /></Grid>

                        <Grid
                            item
                            xs={isNormalMobile ? 12 : 3}
                            className={routeStopContainerClasses} >

                            {positionInfo.stop.timeText}

                        </Grid>
                    </Grid>

                </Grid>
                
                {/*

                Just for debug purposes!
                
                <Grid
                    item
                    xs={4}>
                        {startPosition.timeMicros}
                </Grid>
                <Grid
                    item
                    xs={4}>
                        {durationTimeMicros}, {`${driveTime}`}
                 </Grid>
                <Grid
                    item
                    xs={4}>
                        {stopPosition.timeMicros}
                 </Grid>
                 */}

                <Grid
                    item
                    container
                    xs={12}
                    className={classes.row}>

                    <div className={classes.startRow}>
                        {positionInfo.start.icon}
                        {positionInfo.start.geocodedAddress}
                    </div>

                </Grid>

                <Grid
                    item
                    xs={12}
                    className={classes.row}>

                    <div className={classes.stopRow}>
                        {positionInfo.stop.geocodedAddress}
                        {positionInfo.stop.icon}
                    </div>

                </Grid>

                <EventList events={events} eventsVisible={eventsVisible} />

                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.footer} >

                    <Grid item xs={isSummary? 11 : 7} container justifyContent="flex-start" alignItems="center">
                        <StandTime timeMicros={standTimeMicros} />
                    </Grid>
                    <Grid item xs={isSummary? 1 : 5} container justifyContent="flex-end" alignItems="center" onClick={toggleEventsVisibility}>
                        <EventSwitch events={events} eventsVisible={eventsVisible} />
                    </Grid>

                </Grid>

            </Grid>

        </div>    
    )
}

const mapStateToProps = (state) => {
    return {
        state: state,
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
        itemCheckedByUser: (startPositionTimeMicros) => dispatch(routesActions.itemCheckedByUser(startPositionTimeMicros)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemRow)