import { select, takeEvery, put } from 'redux-saga/effects'

import * as navigationRoutes from '../../other/NavigationRoutes'
import * as navigationActions from './../actions/navigation'
import * as settingsActions from './../actions/settings'
import * as actionTypes from '../actions/actionTypes'

function* handlePushToHistory(action) {

    const to = action.payload
    const { navigation } = yield select()
    const { history } = navigation

    console.debug(`YYYY Navigation to: ${to}`)

    yield history.push(to)
}

function* handleKeyDown(action) {

    const event = action.payload

    console.debug("Handling keyboard event: ", event)
    
    //We bind our functions only for ctrl + keys
    
    //TODO: document these shortcuts
    if (event.ctrlKey) {

        let to

        switch (event.code) {
            case "KeyR": to = navigationRoutes.NAVIGATION_ROUTE_ROUTES; break;
            case "KeyF": to = navigationRoutes.NAVIGATION_ROUTE_REFUELS; break;
            case "KeyT": to = navigationRoutes.NAVIGATION_ROUTE_TRACKING; break;
            case "KeyM": to = navigationRoutes.NAVIGATION_ROUTE_CHAT; break;
            default: //do nothing
        }

        if (to) yield put(navigationActions.pushToHistory(to))
    }
}   

function* handleBottomMenuItemClick(action) {

    const { payload } = action
    const { settings } = yield select()
    const { filterPaneVisible } = settings

    yield put(navigationActions.pushToHistory(payload.to))
    yield put(settingsActions.setFilterPaneVisible(!filterPaneVisible))
}

export function* saga() {
    yield takeEvery(actionTypes.KEYBOARD_KEY_DOWN, handleKeyDown)
    yield takeEvery(actionTypes.NAVIGATION_PUSH_TO_HISTORY, handlePushToHistory)
    yield takeEvery(actionTypes.NAVIGATION_BOTTOM_MENU_ITEM_CLICK, handleBottomMenuItemClick)
}
