// React imports
import React from 'react'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import * as COM from '../../utilities/common.js'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: ".3rem",
      paddingBottom: ".3rem",
      fontSize: "1.05rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "0.5rem",
    },
    odd: {
      backgroundColor: theme.palette.grey[200],
    },
    even: {
      backgroundColor: "white" //TOOD: from theme!
    },
    selected: {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      // I haven't deleted this yet, because it will be good to show
      // the target's state colored circle
      /*'&:before': {
        content: '"•"',
        paddingLeft: "0.15rem",
        paddingRight: "0.15rem",
        fontWeight: "bold",
        display: 'inline',
        color: theme.palette.primary.main,
      }*/
    }
}))

const Target = (props) => {

    const classes = useStyles()
    const { selected, item } = props 

    let text = null
    if (item.name === item.description) {
      text = item.name
    } else if (item.description !== null && item.description !== "" && item.description !== " ") {
      text = `${item.name} (${item.description})`
    } else {
      text = item.name
    }
    
    const boxStyle = COM.clsx({
      [classes.root]: true,
      [classes.odd]: props.index % 2 !== 0,
      [classes.even]: props.index % 2 === 0,
      [classes.selected]: selected
    })

    return (
      <Box className={boxStyle}>
        {selected ? <CheckBox /> : <CheckBoxOutlineBlank />} {text}
      </Box>
    );
}

export default Target