//React
import ReactDOMServer from 'react-dom/server'

// Redux
import * as trackingActions from '../../store/actions/tracking'
import { connect } from 'react-redux'

// Basic imports
import * as COM from '../../utilities/common'

// Leaflet
import { Marker, Popup, Tooltip } from "react-leaflet"

import {
  makeStyles,
  Box,
  Typography,
}
from '@material-ui/core'

import RefreshIcon from '@material-ui/icons/Refresh'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

//import * as leafletIcons from '../Map/LeafletIcons'
import KeyValueTable from './KeyValueTable'
import IngitionStatusIcon from './IgnitionStatusIcon'
import { divIconMaker } from '../Map/LeafletIcons'

//TODO: tooltip class would be better
//color values would be from theme palette
const useStyles = makeStyles(theme => ({
  divIcon: {
    border: "none",
  },
  popup: {
    "& .leaflet-popup-content-wrapper": {
      //backgroundColor: "red" //to set the popups background
    }
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  big: {
    marginBottom: ".5rem",
    padding: 0,
    fontSize: "125%",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  normal: {
    marginBottom: ".5rem",
    fontSize: "90%",
    color: theme.palette.primary.main,
  },
  measuresContainer: {
    //XXX: It would be better to set the popup class directly insted of
    //these hacks here, but it is much easier now!
    marginLeft: "-5%",
    width: "110%", //5% overflow at the left, 100% width, 5% overflow at the right

    padding: ".5rem",
    borderRadius: ".5rem",
    color: "white",
    backgroundColor: theme.palette.primary.main,

  },
  tooltipTextWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  //XXX: it's part of a hack! Read bellow!
  showRefreshIcon: {
    "& > :first-child": {
      display: "none",

    },
    "& > :nth-child(2)": {
      display: "block",
    }
  },
  showPendingIcon: {
    "& > :first-child": {
      display: "block",

    },
    "& > :nth-child(2)": {
      display: "none",
    }
  }
}))

const TitleText = (props) => {
  const { target } = props
  if (!target.description) return target.name
  return `${target.name}, ${target.description}`
}

const TrackingMarker = (props) => {

  const classes = useStyles()

  const { item } = props
  const { currentPosition } = item

  console.debug(`TrackingMarker: item:`, item)

  // if currentPosition isn't presented we do not render anything
  if (!currentPosition) { return null }

  const iconHTML = ReactDOMServer.renderToString(<IngitionStatusIcon fillColor={currentPosition.color} />)
  const icon = divIconMaker(iconHTML, classes.divIcon)
  const position = [currentPosition.latitude, currentPosition.longitude]
  const measures = currentPosition.measures || []
  const truncatedLat = currentPosition.latitude.toFixed(4)
  const truncatedLng = currentPosition.longitude.toFixed(4)

  let timeMicros = item.lastPositions[0].timeMicros
  if (!timeMicros) {
    console.debug("The last position has no timeMicros, using serverTimeMicros instead!", item);
    timeMicros = item.serverTimeMicros
  }

  const frontendTime = COM.createFrontendTimeMilis(timeMicros)

  //const frontendTime = COM.createFrontendTimeMilis(item.serverTimeMicros)
  const dateTimeString = COM.formattedDateTime(frontendTime)

  const speedUnit = process.env.REACT_APP_SPEED_UNIT || 'km/h'
  
  const handleRefresh = (ev)  => {
    const targetId = item.target.id;
    console.debug(`Refresh clicked with id ${targetId}`)
    props.updateTarget(targetId)
  }

  //XXX: we have to use this CSS modification hack here, to avoid a very strange exception deeply in the leaflet library (Cannot read properties of null (reading '_leaflet_disable_click')).
  //This bug hasn't been investigated properly, but it seems the click event handling of the library needs the event source HTML element being presented after the click
  //so cannot use a simple element replace approach as usual!
  //Check this issue with newer leaflet and react-leaflet versions too.y
  const isPending = item.status === COM.TRACKING_STATUS_PENDING
  const iconClasses = COM.clsx({
    [classes.showRefreshIcon] : !isPending,
    [classes.showPendingIcon] : isPending,
  })

  const markerEventHandlers = {
    click: (ev) => {
      //XXX: The following lines have been recommended by GitHub co-pilot. It works, but check if it is the best solution!
      const targetId = item.target.id;
      const marker = ev.target;
      const popup = marker.getPopup();
      const isPopupOpen = popup.isOpen();
      
      console.debug(`Marker clicked on target ${targetId}! Popup is open: `, isPopupOpen)
      
      const poputTargetId = isPopupOpen ? targetId : null
      console.debug(`Setting popup target id to: ${poputTargetId}`)
      
      props.setPopupTargetId(poputTargetId)
    }
  }

  console.debug(`Icon color for marker is: ${currentPosition.color}`)

  return (
    <Marker
      icon={icon}
      position={position}
      eventHandlers = {markerEventHandlers}>
      
      <Popup className={classes.popup}>
        
        <Box className={classes.title}>
            <Typography className={classes.big}>
              <TitleText target={item.target} />
            </Typography>
            
            <div className={iconClasses}>
              <HourglassEmptyIcon fontSize="small" />
              <RefreshIcon fontSize="small" onClick={handleRefresh} />
            </div>
        </Box>
  
        <Box>

          <div className={classes.big}>
            {currentPosition.speed}&nbsp;{speedUnit}
          </div>
          <div className={classes.big}>
            {currentPosition.geocodedAddress}
          </div>

          <div className={classes.normal}>
            {truncatedLat}, {truncatedLng}
          </div>

          <div className={classes.normal}>
            {dateTimeString}
          </div>

        </Box>
        
        <div className={classes.measuresContainer}>
          <KeyValueTable array={measures} />
        </div>
      
      </Popup>
  
      <Tooltip direction="bottom" offset={[0, 0]} opacity={0.9} permanent className={classes.tooltip}>
        {COM.getTargetLabelText(item.target)}
      </Tooltip>
  
  </Marker>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    //update
    updateTarget: (targetId) => dispatch(trackingActions.updateTarget(targetId)),
    setPopupTargetId: (targetId) => dispatch(trackingActions.setPopupTargetId(targetId)),
  }
}

const mapStateToProps = (state) => {
  return {
    mapState: state.map,
    settingsState: state.settings
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingMarker)