const IngitionStatusIcon = (props) => {

    const fillColor = props.fillColor || "#FFFFFF"
    const strokeColor = props.strokeColor || "#000000"

    return (
        <svg width="24" height="24" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <g id="ignitionStatusIcon" fill={fillColor} stroke={strokeColor} strokeWidth="16">
                <circle cx="128" cy="128" r="120"></circle>
            </g>
        </svg>  
    )
}

export default IngitionStatusIcon;