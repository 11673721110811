// Basic imports
import React from 'react'
import * as COM from '../../utilities/common'
import { connect } from 'react-redux'
import * as refuelActions from '../../store/actions/refuels'

// Material UI
import { 
  makeStyles,
  Grid,
  Tooltip,
} from '@material-ui/core'

// Icons
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

// i18n imports
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  row: {
    padding: "0.5rem",
    borderBottom: `solid 1px ${theme.palette.grey[300]}`
  },
  even: {
    backgroundColor: theme.palette.grey[100],
  },
  icon: {
    color: theme.palette.grey[400],
    marginRight: "0.5rem",
  },
  amount: {
    fontWeight: "bold"
  },
  info: {
    color: theme.palette.grey[400],
    padding: "0.2rem",
  }
}))

const RefuelsList = (props) => {

  const classes = useStyles()
  const { t } = useTranslation()

  //XXX: !!! MISLEADING NAMES !!!
  //XXX: Nameing is misleading, be carefull!
  //XXX: The state contains a 'refuels' object.
  //XXX: The refuels object contains a 'refuels' list.
  //XXX: We use the refuels list INSIDE the refuels object to iterate
  const refuelsState = props.state.refuels
  const { refuels } = refuelsState


  if (!refuels || refuels.length < 1) return null

  const Row = (props) => {

    //XXX: check the name of the 'fuelFillingTimeMicrose' variable! It seems like a type int the name TimeMicrosE
    //Is the E at the end coming from the backend?
    const fillingTimeString = props.row.fuelFillingTimeMicrose
    ? COM.formattedDateTime(props.row.fuelFillingTimeMicrose / 1000)
    : t("undefined")

    return (
      <Grid container className={COM.cls(classes.row, props.rowClass)}>
        <Grid item xs={12} sm={4} md={2} container alignItems="center">
          <AccessTimeIcon fontSize="small" className={classes.icon} />
          {fillingTimeString}
        </Grid>
        <Grid item xs={12} sm={8} md={6} container alignItems="center">
          <LocationOnIcon fontSize="small"  className={classes.icon} />
          {props.row.fuelFillingCountry}, {props.row.fuelFillingPosition}
        </Grid>
        <Grid item xs={12} sm={12} md={4} container alignItems="center">
          <Tooltip title={t("start + filled = result, all in liters")}>
            <Grid container alignItems="center">
              <LocalGasStationIcon fontSize="small" className={classes.icon} />
              <span>{props.row.fillStartLevel.toFixed(1)}L</span>&nbsp;+&nbsp;<span className={classes.amount}>{props.row.fillAmount.toFixed(1)}L</span>&nbsp;=&nbsp;<span>{props.row.fillEndLevel.toFixed(1)}L</span>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  return refuels.map((row, idx) => <Row row={row} key={`row-${idx}`} rowClass={idx % 2 === 0 ? classes.even : null} />)
}

const mapDispatchToProps = dispatch => {
  return {
    fetchStart: (offset, limit) => dispatch(refuelActions.fetchStart(offset, limit)),
  }
}

function mapStateToProps(state) {
  return {
      state: state,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RefuelsList)