// React imports
import React from 'react'

// i18n
import { useTranslation } from 'react-i18next'

import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import NoResult from './NoResult'

const useStyles = makeStyles((theme) => ({
    odd: {
      backgroundColor: "lightgray" //TODO: from theme!
    },
    even: {
      backgroundColor: "white" //TOOD: from theme!
    },
    chipContainer: {
      display: "flex",
    },
    chipWrapper: {
      marginRight: "0.25rem",
    },
    emptyList: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontStyle: "italic",
    }
}))

const SingleSelectedTarget = (props) => {

    const classes = useStyles()

    const { t } = useTranslation()

    if (!props.item) return <NoResult message={t(`No target selected`)} />

    return (
      <div className={classes.chipContainer}>
          <div className={classes.chipWrapper}>
            <Chip
              color="primary"
              label={props.item.name}
              onDelete={props.onDelete} />
          </div>
      </div>
    )
}

export default SingleSelectedTarget