// React imports
import React from 'react'

// React Leaflet
import { MapContainer, TileLayer } from "react-leaflet"

// material-ui
import {
  makeStyles
} from "@material-ui/core"

// Own components
import MapControlButtons from './ControllButtons/MapControlButtons'
import MapReferenceCollector from './MapReferenceCollector'

import * as positions from '../../config/language-positions.json'

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: 'rgba(77,88,99, .25)',    
    //To hide the leaflet attribution badge under the bottom menu
    //There is no 'official' way to turn it off, so we have to hack
    //and hide the class
    "& div.leaflet-control-attribution" : {
      display: "none",
    }
  },
}))



const MapView = (props) => {

    const classes = useStyles()
    const { children } = props
    const tileLayerURL = process.env.REACT_APP_OSM_TILE_LAYER_URL

    const handleWhenReady = () => {
      console.debug("The map is ready.")
    }

    // !!! VERY IMPORTANT !!!
    // The MapContainer whitout 'center' and 'zoom' parameter doesn't show up! Doesn't show any error message
    // to the console or anywhere else, so I had to debug this problem for hours! Keep in mind, do not waste your
    // time as I did (Edi).
    return (
      
      <MapContainer
        
        doubleClickZoom={false}
        center={positions.enUS.center}
        zoom={positions.enUS.zoom} 
        scrollWheelZoom={true}
        zoomControl={false}
        whenReady={handleWhenReady}
        className={classes.root}>

        <MapReferenceCollector />

        <TileLayer
          url={tileLayerURL}
          attribution=""/>   

        <MapControlButtons />
        
        {children}
      
      </MapContainer>
    )
}

export default MapView