import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& span": {
            fontWeight: "bold",
        }
    },
}))

const InfiniteScrollMessage = (props) => {

    const classes = useStyles()
    const { message } = props
    const rnd = Math.random()

    return (
        <div className={classes.container} key={rnd}>
            <span>{message}</span>
        </div>
    )
}

export default InfiniteScrollMessage