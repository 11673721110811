// React imports
import React from "react"
import * as refuelActions from '../../store/actions/refuels'
import * as COM from "../../utilities/common"

// Redux imports
import { connect } from 'react-redux'

// i18n imports
import { useTranslation } from 'react-i18next'

// Material UI components
import {
    Tabs,
    Tab,
 } from "@material-ui/core"

 // Icons
import LocalShipping from "@material-ui/icons/LocalShipping"
import DateRange from "@material-ui/icons/DateRange"

// Own components
import TargetsFilter from "../Filter/TargetsFilter"
import DateRangeFilter from "../Filter/DateRangeFilter"
import SingleSelectedTarget from '../Filter/SingleSelectedTarget'
import TopDrawer from "../Drawer/TopDrawer"
import RefuelsList from './RefuelsList'
import RefuelsHeader from "./RefuelsHeader"

const Refuels = (props) => {

    const { t } = useTranslation()
    const { state, setDateRange } = props
    const { refuels, targets } = state
    const { dateRangeFrom, dateRangeTo } = refuels

    const handleTargetClick = (item) => {
        props.addTarget(item)
    }
       
    const handleClickDelete = () => {
        props.deleteTarget()
    }

    const handleClearClick = () => {
        console.debug("handleClearClick")
    }

    const handleTabChange = (event, value) => {
        props.setSelectedTab(value)
    }

    const { selectedTab } = refuels

    //NOTE: To be human-friendy we add plus one to the real day difference, so in case of same days the difference is 1 intead of 0
    const dateDifferenceString = `${COM.dateDifference(refuels.dateRangeFrom, refuels.dateRangeTo).days + 1 }${t('d')}`
    const selectedCount = refuels.target ? 1 : 0

    //We have to create a common target format
    const selectedTargets = [{
          id: refuels.target?.id,
          ...refuels.target,
    }]

    const tabContent = [

        <TargetsFilter
            items={refuels.target}
            selectedTargets={selectedTargets}
            selectedCount={selectedCount}
            onTargetClick={handleTargetClick}
            onCleasSelectedClick={handleClickDelete}>
            <SingleSelectedTarget item={refuels.target} onDelete={handleClickDelete} />
        </TargetsFilter>, 
        
        <DateRangeFilter
            onClearClick={handleClearClick}
            dateRangeFrom={dateRangeFrom}
            dateRangeTo={dateRangeTo}
            handleDateRangeChange={setDateRange} />,
    ]

    return (
        <>
            <TopDrawer inProgress={refuels.inProgress || targets.inProgress} >
                <div>
                    <Tabs value={selectedTab} onChange={handleTabChange} >
                    <Tab
                        icon={<LocalShipping />}
                        label={`${selectedCount}`} />
                    <Tab
                        icon={<DateRange />}
                        label={dateDifferenceString} />
                    </Tabs>
                </div>
                {tabContent[selectedTab]}
            </TopDrawer>

            <RefuelsHeader />
            <RefuelsList />
        </>
    )
} 

const mapDispatchToProps = dispatch => {
    return {
        fetchStart: (offset, limit) => dispatch(refuelActions.fetchStart(offset, limit)),
        addTarget: target => dispatch(refuelActions.addTarget(target)),
        deleteTarget: () => dispatch(refuelActions.deleteTarget()),
        setDateRange: (from, to) => dispatch(refuelActions.setDateRange(from, to)),
        setSelectedTab: value => dispatch(refuelActions.setSelectedTab(value)),
    }
}

function mapStateToProps(state) {
    return {
        state: state,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Refuels)