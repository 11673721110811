// React imports
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as settingsActions from './../../store/actions/settings'

// i18n imports
import { useTranslation } from 'react-i18next'
import '../../i18n/i18n' // initialization

// Material UI imports
import { 
  makeStyles,
  ThemeProvider,
  CssBaseline
} from '@material-ui/core'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import * as DateFnsLocales from "date-fns/locale"

import SignInLayout from '../SignIn/SignInLayout'
import AuthorizedLayout from '../Aurhorized/AuthorizedLayout'
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog'

// UI themes
import { getTheme } from '../../ui/themes'
import HistoryReferenceCollector from '../Navigation/HistoryReferenceCollector'

const Layout = ({loggedIn}) => {
  if (!loggedIn) return <SignInLayout />
  return <AuthorizedLayout />
}

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE

const App = (props) => {

    // We create the root CSS classes here
    makeStyles((theme) => ({
      '@global': {
        body: {
          padding: 0,
          margin: 0,
          height: "100%",
          overscrollBehavior: "contain", //!!! IMPORTANT !!! To avoid page refresh when the user 'pulls down' the site 
        },
      },
    }))() //NOTE: it's a function call here!

  const { i18n } = useTranslation()
  const { authState, settingsState, languageChange } = props
  const { uiThemeName } = settingsState
  const languageCode = settingsState.languageCode
  const { credentials } = authState
  const loggedIn = credentials !== null

  // These lines of code act like a main language change logic of the application. It's part of the business
  // logic, but due to the fact that i18n object is available just here we have to do this operation here. We do
  // not provide the i18n to the saga layer, because the whole implementation would be too complex, so we 'break'
  // the logical layers here to not to increase complexity. Due to this structure on change of the language the
  // whole site will be re-rendered, since the languageCode object will change. Just keep this behaviour in mind!
  // Do not nowhere call i18n.languageChange() instead set the settings languageCode via the dedicated
  // settingsActions.languageChange(code) function.
  //
  // TODO: create an 'i18n to saga' provider and move to logic to the saga layer
  // 
  useEffect( () => {
    console.debug("Main language change handler. Language code: ", languageCode)
    if (!languageCode) {
      console.debug("Language code not presented, going to use the default: ", DEFAULT_LANGUAGE_CODE)
      languageChange(DEFAULT_LANGUAGE_CODE)
    } else {
      i18n.changeLanguage(languageCode)
      console.debug("Language has been changed to: ", languageCode)
    }
  }, [languageCode, i18n, languageChange])


  const theme = getTheme(uiThemeName) 
  console.debug(`Theme being used: `, theme)
  
  const locale = DateFnsLocales[languageCode]
  console.debug(`Locale used by MUI-pickers:`, locale)
 
  return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <BrowserRouter>
                <HistoryReferenceCollector />
                <CssBaseline />
                <ErrorDialog />
                <Layout loggedIn={loggedIn} />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  return {
      authState: state.auth,
      settingsState: state.settings,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    languageChange: (code) => dispatch( settingsActions.languageChange(code) ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)