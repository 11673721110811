import React, { Fragment } from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

// i18n imports
import { useTranslation } from 'react-i18next';

const Environment = () => {

    const { t } = useTranslation();    
    
    return (
        <Fragment>
            <TableRow>
            <TableCell component="th" scope="row" colSpan={2}>
                <Typography variant="h6" component="h6">
                    {t("Environment")}
                </Typography>
            </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("Node Environment")}</TableCell>
                <TableCell>{process.env.NODE_ENV}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("API URL")}</TableCell>
                <TableCell>{process.env.REACT_APP_API_URL}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("Google Analitics ID")}</TableCell>
                <TableCell>{process.env.REACT_APP_GOOGLE_ANALYTICS_ID}</TableCell>
            </TableRow>
        </Fragment>
    )
}

export default Environment