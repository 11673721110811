import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL

// https://m.itrack.hu/ws-rest/api/v1.1/targets/49841/routes?fromTime=1593640800000000&toTime=1603666799000000&_=1601913343119
export const getRoutes = (params) => {

    console.debug(`Params for getRoutes API call:`, params)

    return axios.get(
        `${BASE_URL}/targets/${params.targetId}/routes`, {
            auth: params.credentials,
            params: {
                fromTime: params.fromTime,
                toTime: params.toTime,
            }
        }
    )
}

// https://m.itrack.hu/ws-rest/api/v1.1/targets/37665/positions?fromTime=1598422337000000&toTime=1598423472000000&_=1601957927249
export const getPositions = (params) => {
    console.debug(`Params for getPositions API call:`, params)

    return axios.get(
        `${BASE_URL}/targets/${params.targetId}/positions`, {
            auth: params.credentials,
            params: {
                fromTime: params.fromTime,
                toTime: params.toTime,
            }
        }
    )
}
