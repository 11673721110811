import { SvgIcon } from "@material-ui/core"

const TailwindSvgIcon = (props) => {

    const fill = props.fill || "black"
    
    return (
        <SvgIcon viewBox='0 0 24 24'>
            <g fill={fill}>
                <rect x='14' y='4' width= '10' height='2'></rect>
                <rect x='8' y='11' width= '10' height='2'></rect>
                <rect x='10' y='18' width= '10' height='2'></rect>
            </g>
        </SvgIcon>
    )
}

export default TailwindSvgIcon