import * as actionTypes from './actionTypes'

export const set = (center, zoom, zoomControl) => {
    return {
        type: actionTypes.MAP_SET,
        center: center,
        zoom: zoom,
        zoomControl: zoomControl
    }
}

export const setRef = (ref) => {
    return {
        type: actionTypes.MAP_SET_REF,
        ref: ref,
    }
}

export const panTo = (position) => {
    return {
        type: actionTypes.MAP_PAN_TO,
        payload: position,
    }
}


// BOUNDS & FIT TO BOUNDS
export const fitToBounds = (bounds) => {
    return {
        type: actionTypes.MAP_FIT_TO_BOUNDS,
        payload: bounds,
    }
}

export const fitTo = (latlng) => {
    return {
        type: actionTypes.MAP_FIT_TO,
        payload: latlng,
    }
}

// ZOOM
export const zoomUserRequest = (step) => {
    return {
        type: actionTypes.MAP_ZOOM_USER_REQUEST,
        payload: step
    }
}