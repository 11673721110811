// Redux imports
import { connect } from 'react-redux'
import * as navigationActions from '../../store/actions/navigation'

// React Leaflet
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const HistoryReferenceCollector = (props) => {
    const history = useHistory()
    
    //Set the map reference, but we run only once!
    useEffect(() => {
        props.setHistory(history)
    // eslint-disable-next-line
    },[])
    
    return null
}

const mapDispatchToProps = dispatch => {
    return {
        setHistory: (ref) => dispatch(navigationActions.setHistory(ref)),
    }
}

const mapStateToProps = (state) => {
    return {
        state: state
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryReferenceCollector)