// Redux imports
import { connect } from 'react-redux'
import * as mapActions from '../../store/actions/map'

// React Leaflet
import { useMap } from "react-leaflet"
import { useEffect } from 'react'

const MapReferenceCollector = (props) => {
    const map = useMap()
    
    //Set the map reference, but we run only once!
    useEffect(() => {
        props.mapSetRef(map)
    // eslint-disable-next-line
    },[])
    
    return null
}

const mapDispatchToProps = dispatch => {
    return {
        mapSetRef: (ref, setToDefaultView) => dispatch(mapActions.setRef(ref)),
    }
}

const mapStateToProps = (state) => {
    return {
        state: state
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapReferenceCollector)