// React imports
import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as authActions from '../../store/actions/auth'
import * as mapActions from '../../store/actions/map'
import * as settingsActions from '../../store/actions/settings'
import * as COM from '../../utilities/common'
//import * as positions from '../../config/language-positions.json'

// import i18next
import { useTranslation } from 'react-i18next'

// Default theme
import { getTheme } from '../../ui/themes'

// Component imports
import {
  makeStyles,
  Tab,
  Tabs,
  Container,
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core'

import {
  ComputerRounded,
  Apple,
  Android,
} from '@material-ui/icons'

import logo from '../../assets/itrack-logo.svg'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Registration from './Registration'
import Form from './Form'
import Copyright from './Copyright'
import FullScreenModalProgressIndicator from '../App/FullScreenModalProgressIndicator'

const positions = require('../../config/language-positions.json');

const useSyles = makeStyles( theme => {

  return ({
    container: {
      width: "100vw",
      minWidth: "320px",
      boxShadow: theme.shadows[3],
      backgroundColor: theme.palette.background.paper,
      borderRadius: '1rem 1rem'
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },
    logoContainer: {
      marginTop: "1.5rem",
      marginLeft: "1.5rem",
      marginRight: "1.5rem",
      marginBottom: ".5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor: "rgba(34,56,72,.5)", //just for debugging!
      "& img": {
        width: "65%",
      }
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    signUp: {
        textAlign: "right",
    },
    storePasswordBox: {
        display: "flex",
        "& *": {
          color: theme.palette.grey['700'],
          fontSize: "0.85rem",
        }
    },
    footer: {
      borderWidth: "1px",
      borderTopStyle: "solid",
      borderTopColor: theme.palette.grey['400']
    },
    paramsBox: {
      position: 'fixed',
      paddingTop: '0.25rem',
      left: '0',
      bottom: '0',
      width: '100%',
      height: '1.75rem',
      fontSize: '0.75rem',
      textAlign: 'center',
      zIndex: 5,
      backgroundColor: theme.palette.grey['600'],
      color: theme.palette.grey['800']
    },
    languageWrapper: {
      //
    },
    buildId: {
      color: theme.palette.grey['400'],
      fontSize: ".75rem"
    }
  })
})

const PresenterIcon = ({presenter}) => {
  if (presenter === COM.IOS_EXOSKELETON) return <Apple color="disabled" fontSize="small"/>
  if (presenter === COM.ANDROID_EXOSKELETON) return <Android color="disabled" fontSize="small"/>
  return <ComputerRounded color="disabled" fontSize="small"/>
}

const SignIn = (props) => {

  const { t } = useTranslation()

  const { state, setMap, onAuth } = props
  const { auth, settings, device } =  state

  const currentTheme = getTheme(settings.uiThemeName)
  const classes = useSyles(currentTheme)
 
  const [tabValue, setTabValue] = useState(0)

  const { inProgress } = auth
  const { languageCode, forcedAutoLogin } = settings
  const mapPosition = positions[languageCode]

  // This is a business logic step, but instead of placing this code into the saga
  // we are going to handle this functionality here hence this feature is highly realted to the view.
  console.debug(`Language code: '${languageCode}'`)
  console.debug(`Map positing for language code: `, mapPosition, positions)
  mapPosition && setMap(mapPosition.center, mapPosition.zoom)

  const handleLanguageChange = () => {
    console.debug("Language has changed!")
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const handleSubmit = (credentials) => {
    onAuth(credentials)
  }

  const handleForgotPassword = () => { 
    const to = process.env.REACT_APP_PASSWORD_RESET_URL
    console.debug(`'Forgot password' has clicked, redirecting to: ${to}`)
    window.location.href = to
  }

  const tabContent = [
    <Form
      handleSubmit={handleSubmit}
      handleForgotPassword={handleForgotPassword}
      setForcedAutoLogin={props.setForcedAutoLogin}
      forcedAutoLogin={forcedAutoLogin}
      classes={classes}/>,
    <Registration />,
  ]
 
  return (
    <>
      <Container component="main" maxWidth="xs" className={classes.container}>

          <div className={classes.logoContainer}>
             <img src={logo} alt='iTrack logo' />
          </div>          

          <div className={classes.paper}>

            <Box mt={2}>
              <Tabs
              value={tabValue}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="SignIn tabs">
                <Tab label={t("Sign In")} />
                <Tab label={t("Registration")} />
              </Tabs>
            </Box>

            {tabContent[tabValue]}

            <Box mb={2} mt={2} className={classes.footer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch">

                <Grid item xs={12}>
                  <Box mb={1}>
                    <div className={classes.languageWrapper}>
                      <LanguageSelector onLanguageChange={handleLanguageChange}/> 
                    </div>
                  </Box>
                </Grid>

                <Grid item xs={4} container justifyContent="flex-start" alignContent="center">
                  <PresenterIcon presenter={device.presenter} />
                </Grid>

                <Grid item xs={4} container justifyContent="center" alignContent="center">
                  <Copyright />
                </Grid>
                
                <Grid item xs={4} container justifyContent="flex-end" alignContent="center" className={classes.buildId}>
                  <Tooltip title="build ID">
                    <span>
                      {COM.buildIdString()}&nbsp;
                      ({process.env.REACT_APP_ENV_ID || "???"})
                    </span>
                  </Tooltip>
                </Grid>
            
              </Grid>
            </Box>
          </div>

      </Container>

      <FullScreenModalProgressIndicator
        id="login-progress"
        message={t("Login is in progress")}
        flag={inProgress}
        showPaper />
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (credentials) => dispatch(authActions.authStart(credentials)),
    setMap: (center, zoom) => dispatch(mapActions.set(center, zoom)),
    setForcedAutoLogin: (value) => dispatch(settingsActions.setForcedAutoLogin(value)),
  }
}

function mapStateToProps(state) {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)