import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    makeStyles
} from '@material-ui/core/'

import { themes } from '../../ui/themes'

//i18n
import { useTranslation } from 'react-i18next'

import * as settingsActions from '../../store/actions/settings'

const useStyles = makeStyles( theme => ({
}))

const ThemeSelector = (props) => {

    const currentThemeName = props.settingsState.uiThemeName
    const { t } = useTranslation()
    const classes = useStyles()

    const handleThemeChange = (event) => {
        props.themeChange(event.target.value)
    }

    return (
        <>
            <h3>{t("Theme")}</h3>
            <FormControl component="fieldset">
                <RadioGroup
                aria-label="themes"
                row
                name="themes"
                value={`${currentThemeName}`}
                onChange={handleThemeChange}>
                {Object.keys(themes).map( (t, i) => (
                    <FormControlLabel key={t} value={`${t}`} control={<Radio />} label={<span className={classes.label}>{t}</span>} />
                ))}
                </RadioGroup>
            </FormControl>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
       themeChange: (selectedIndex) => dispatch(settingsActions.themeChange(selectedIndex)),
    }
}

const mapStateToProps = (state) =>  {
    return {
        settingsState: state.settings,
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ThemeSelector))