import DisplayableError from './DisplayableError'

export default class AuthError extends DisplayableError {
    
    constructor(exception, message) {
        
        super(message)
    
        this.title = "Nem engedem be!"
        this.message = "A felhasználónév vagy jelszó nem megfelelő!"
        this.exception = exception
        this.date = new Date()

        console.debug(`ERROR has been created with date: Date: ${this.date} Message: ${this.message} Exception: ${this.exception}`)
    }
}
