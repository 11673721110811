import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

//actions
import * as authActions from '../../store/actions/auth'
import * as settingsActions from '../../store/actions/settings'
import * as navigationActions from '../../store/actions/navigation'

//routes
import * as navigationRoutes from '../../other/NavigationRoutes'

// i18n imports
import { useTranslation } from 'react-i18next'

//Material UI
import {
  makeStyles,
  Divider,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import {
  ExitToApp,
  Gesture,
  GpsFixed,
  LocalGasStation,
  Message,
  MoreVert,
  PermDeviceInformation,
  Person
} from '@material-ui/icons'


import EnhancedIcon from './EnhancedIcon'
import PopupMenu from './PopupMenu'
import { clsx } from '../../utilities/common'
import { useLocation } from 'react-router-dom'

// *** IMPORTAN ***
// We use makeStyle two times!
// We have useStyle and useActionStyle too. The first is for BottomNavigation 
// and the second is for BottomNavigationAction
const useStyle = makeStyles( (theme) => ({
  wrapper: {

  },
  root: {
    backgroundColor: theme.palette.primary.main,
    "&$selected": {
      color: "red"
    },
  },
  //Helper class to reduce the size of the bottom menu
  //to fit well on small screen too (320px width)
  smaller: {
    transform: "scale(.8)",
  },
  dot: {
    position: "relative",
    top: 0,
    left: 0,
    backgroundColor: "red",
    width: ".5rem",
    height: ".5rem",
  },
  divider: {
    marginTop: ".5rem",
    marginBottom: ".5rem",
  }
}))

const useActionStyle = makeStyles((theme) => ({
  root: {
    color: "white",
    "&$selected": {
      color: theme.palette.secondary.main
    },
  },
  selected: {
    color: theme.palette.secondary.main,
  }
}))

const BottomMenu = (props) => {

  const { state } = props
  const { settings } = state
  const { selectedBottomMenuItem } = settings

  const classes = useStyle()
  const actionClasses = useActionStyle()
  
  const itemsRef = useRef([]);
  const [anchorRef, setAnchorRef] = useState(null);

  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme();
  //For custom brakepoints we have to use the following syntax instead of a simple string
  const matches = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.mobile))

  const generatedBottomNavigationClasses = clsx({
    [classes.root]: true,
    [classes.smaller]: matches,
  })

  //click event handlers
  //these declarations have to be 'upper' before
  //the bottomMenuItems to the 'to' field get value
  const handleChange = (evt, value) => props.setSelectedBottomMenuItem(value)
  const handleProfileClick = () => props.setProfilePaneVisible(true)
  const handleLogout = () => props.signOutStart()
  const handleClose = () => setAnchorRef(null)

  const bottomMenuItems = [
    {id: 0, icon: <MoreVert />, label: "More", popupMenuItems: [
        {icon: <Person />, label: "Profile", to: handleProfileClick, },
        {icon: <PermDeviceInformation />, label: "System information", to: navigationRoutes.NAVIGATION_ROUTE_SYS_INFO },
        <Divider className={classes.divider}/>,
        {icon: <ExitToApp />, label: "Logout", to: handleLogout },
    ]},
    {id: 1, icon: <GpsFixed />, label: "Tracking", to: navigationRoutes.NAVIGATION_ROUTE_TRACKING },
    {id: 2, icon: <Gesture />, label: "Routes", to: navigationRoutes.NAVIGATION_ROUTE_ROUTES },
    {id: 3, icon: <Message />, label: "Messages", to: navigationRoutes.NAVIGATION_ROUTE_CHAT },
    {id: 4, icon: <LocalGasStation />, label: "Refuels", to: navigationRoutes.NAVIGATION_ROUTE_REFUELS },
  ]

  const findIdByPathname = (pn) => {
    for(var i = 0; i < bottomMenuItems.length; i++) {
      const bi = bottomMenuItems[i]
      if (bi.to === pn) {
        return bi.id
      }
      if (bi.popupMenuItems) {
        for(var j = 0; j < bi.popupMenuItems.length; j++) {
          const pmi =  bi.popupMenuItems[j]
          if (pmi === pn) {
            return bi.id
          }
        }
      }
    }
  }
  
  useEffect(() => {
    const selectedIdByPathname = findIdByPathname(location.pathname)
    console.debug("Bottom menu:", selectedIdByPathname, selectedBottomMenuItem )
    if (selectedIdByPathname !== selectedBottomMenuItem) {
      console.debug(`Bottom menu: We have a different menu item id '${selectedIdByPathname}' by the location '${location.pathname}' than the selected menu item id '${selectedBottomMenuItem}'.`)
      console.debug(`Bottom menu: We are going to set the selected menu item to id '${selectedIdByPathname}`)
      props.setSelectedBottomMenuItem(selectedIdByPathname)
    }
   // eslint-disable-next-line
  },[props, selectedBottomMenuItem, location.pathname])

  //main click event handler
  const handleClick = (event, item, ref) => {

    console.debug("Handling menu item click. item: ", item, "item.to:", item.to, " event: ", event, " ref: ", ref)
    
    //Do we have popup menu items? If yes, open the menu
    if (item.popupMenuItems) {
      console.debug("The clicked item is a group menu item, I am going to open the menu.")
      setAnchorRef(ref)
      return
    }

    //Is the item.to a function? If yes, we are going to call it.
    if (item.to instanceof Function) {
      console.debug("The 'to' parameter is a function.");
      item.to()
      return
    }

    props.bottomMenuItemClick(item)
   
    handleClose()
  }

  return (

      <div className={classes.wrapper}>
        <BottomNavigation
          showLabels
          className={generatedBottomNavigationClasses}
          value={selectedBottomMenuItem}
          onChange={handleChange}>
            {bottomMenuItems.map((item, index) =>
                <BottomNavigationAction
                  value={item.id}
                  key={index}
                  ref={el => itemsRef.current[index] = el}
                  label={t(item.label)}
                  icon={<EnhancedIcon icon={item.icon} isSelected={index !== selectedBottomMenuItem} />}
                  tooltiptitle={t(item.name)}
                  onClick={(event) => handleClick(event, item, itemsRef.current[index])}
                  classes={actionClasses} />
            )}
        </BottomNavigation>
        {bottomMenuItems.map((item, index) => 
          <PopupMenu
            onClose={handleClose}
            anchorEl={anchorRef}
            onClick={handleClick}
            key={index}
            items={item.popupMenuItems} />
        )}

      </div>
)
}


const mapDispatchToProps = dispatch => {
  return {
      signOutStart: () => dispatch(authActions.authSignOutStart()),

      setFilterPaneVisible: (v) => dispatch( settingsActions.setFilterPaneVisible(v) ),
      setFloatingMenuOpen: (v) =>  dispatch( settingsActions.setFloatingMenuOpen(v) ),
      setProfilePaneVisible: (v) =>  dispatch( settingsActions.setProfilePaneVisible(v) ),
      setSelectedBottomMenuItem: (item,) =>  dispatch( settingsActions.setSelectedBottomMenuItem(item) ),

      bottomMenuItemClick: (item) => dispatch( navigationActions.bottomMenuItemClick(item) ),
    }
}

function mapStateToProps(state) {
  return {
      state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BottomMenu)