// Rreact imports
import React from 'react'
import { Route, Switch } from 'react-router-dom'

// Navigation routes
import * as navigationRoutes from '../../other/NavigationRoutes'

// Own components
import Refuels from '../Refuels/Refuels'
import Chat from '../Chat/Chat'
import Routes from '../Routes/Routes'
import Tracking from '../Tracking/Tracking'
import SystemInfo from '../SystemInfo/SystemInfo'
import ProfilePane from './ProfilePane'
import BottomMenu from '../BottomMenu/BottomMenu'

// Material components
import {
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    height: `calc(100% - ${process.env.REACT_APP_BOTTOM_MENU_HEIGHT})`,
    maxHeight: `calc(100% - ${process.env.REACT_APP_BOTTOM_MENU_HEIGHT})`,
    overflowY: "scroll",
    width: "100%",
  },
  menu: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: process.env.REACT_APP_BOTTOM_MENU_HEIGHT,
    minWidth: "320px",
    backgroundColor: theme.palette.primary.main,
  }
}))

const AuthorizedLayout = (props) => {

  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <ProfilePane />
        <Switch>
          <Route exact path={navigationRoutes.NAVIGATION_ROUTE_HOME} component={Tracking}/>
          <Route path={navigationRoutes.NAVIGATION_ROUTE_REFUELS} component={Refuels} />
          <Route path={navigationRoutes.NAVIGATION_ROUTE_CHAT} component={Chat} />
          <Route path={navigationRoutes.NAVIGATION_ROUTE_SYS_INFO} component={SystemInfo} />
          <Route path={navigationRoutes.NAVIGATION_ROUTE_ROUTES} component={Routes} />
          <Route path={navigationRoutes.NAVIGATION_ROUTE_TRACKING} component={Tracking} />
        </Switch>
      </div>
       <div className={classes.menu}>
        <BottomMenu />
      </div>
    </>
  )
}

export default AuthorizedLayout